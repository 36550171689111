import React, { useEffect, useState, useRef } from "react";
import userImg from "../../../../assets/channels/intro_visual_art.png";
import { motion, useScroll } from "framer-motion";
import SubscriptionsTab from "../../ChannelsTabs/SubscriptionsTab";
import CoursesTab from "./CoursesTab";
import ImagesTab from "./ImagesTab";
import AboutUserTab from "./AboutUserTab";
import VideosTab from "./VideosTab";
import SearchTab from "./SearchTab";

const AboutTabs = () => {
  // DISPLAY ON STICKY SCROLL FUNCTION
  const ref = useRef(null);
  const [stickyScroll, setStickyScroll] = useState(0);

  // TO GET THE POSITION OF THE TABS HEADER (0-1)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      setStickyScroll(latest);
    });

    // eslint-disable-next-line
  }, []);

  return (
    <section className="mb-8 flex flex-col items-start justify-center flex-wrap w-full">
      {/* TABS NAVIGATION */}
      <ul
        className="nav nav-tabs sticky top-0 bg-white z-10 md:w-full ioigroup flex items-start lg:items-center flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4 p-4 lg:p-0"
        id="tabs-tab"
        role="tablist"
        ref={ref}
      >
        <motion.li className="">
          <img
            src={userImg}
            alt="user icon"
            className={
              "transition-all duration-300 " +
              (stickyScroll > 0.7
                ? "!h-10 md:w-6 lg:w-8 transition-all"
                : "h-0 w-8 md:w-0 md:h-10 ")
            }
          />
        </motion.li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-about"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent md:px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent active"
            id="tabs-about-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-about"
            role="tab"
            aria-controls="tabs-about"
            aria-selected="true"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span className="ml-2 hidden ioigroup-hover:flex md:flex">
              About
            </span>
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-images"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent md:px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-images-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-images"
            role="tab"
            aria-controls="tabs-images"
            aria-selected="false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.21 7.89924V16.0502C21.21 19.0702 19.32 21.2002 16.3 21.2002H7.65C4.63 21.2002 2.75 19.0702 2.75 16.0502V7.89924C2.75 4.87924 4.64 2.75024 7.65 2.75024H16.3C19.32 2.75024 21.21 4.87924 21.21 7.89924Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.28125 16.4311L6.80925 14.8181C7.34025 14.2551 8.22525 14.2281 8.78925 14.7581C8.80625 14.7751 9.72625 15.7101 9.72625 15.7101C10.2813 16.2751 11.1883 16.2841 11.7533 15.7301C11.7903 15.6941 14.0872 12.9081 14.0872 12.9081C14.6792 12.1891 15.7422 12.0861 16.4622 12.6791C16.5102 12.7191 18.6803 14.9461 18.6803 14.9461"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.3127 9.13315C10.3127 10.1022 9.52769 10.8872 8.55869 10.8872C7.58969 10.8872 6.80469 10.1022 6.80469 9.13315C6.80469 8.16415 7.58969 7.37915 8.55869 7.37915C9.52769 7.38015 10.3127 8.16415 10.3127 9.13315Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span className="ml-2 hidden ioigroup-hover:flex md:flex">
              Images
            </span>
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-videos"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent md:px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-videos-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-videos"
            role="tab"
            aria-controls="tabs-videos"
            aria-selected="false"
          >
            <svg
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2976 11.5383C15.3785 13.3704 13.8999 14.9196 11.9953 14.9975C11.8551 15.0034 5.01604 14.9896 5.01604 14.9896C3.12069 15.1335 1.46188 13.7715 1.31238 11.9463C1.30112 11.8103 1.30419 4.47219 1.30419 4.47219C1.22023 2.63815 2.69678 1.08499 4.60236 1.00418C4.74469 0.997278 11.5745 1.01009 11.5745 1.01009C13.4791 0.868176 15.143 2.24001 15.2904 4.07405C15.3007 4.2061 15.2976 11.5383 15.2976 11.5383Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.3008 5.97984L18.5938 3.28484C19.4098 2.61684 20.6338 3.19884 20.6328 4.25184L20.6208 11.6008C20.6198 12.6538 19.3948 13.2308 18.5808 12.5628L15.3008 9.86784"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span className="ml-2 hidden ioigroup-hover:flex md:flex">
              Videos
            </span>
          </a>
        </li>
        <li className="nav-items" role="presentation">
          <a
            href="#tabs-courses"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent md:px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-courses-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-courses"
            role="tab"
            aria-controls="tabs-courses"
            aria-selected="false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7161 16.2236H8.49609"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.7161 12.0371H8.49609"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.2521 7.86035H8.49707"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.909 2.75C15.909 2.75 8.23198 2.754 8.21998 2.754C5.45998 2.771 3.75098 4.587 3.75098 7.357V16.553C3.75098 19.337 5.47298 21.16 8.25698 21.16C8.25698 21.16 15.933 21.157 15.946 21.157C18.706 21.14 20.416 19.323 20.416 16.553V7.357C20.416 4.573 18.693 2.75 15.909 2.75Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="ml-2 hidden ioigroup-hover:flex md:flex">
              Courses
            </span>
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-subscriptions"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent md:px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-subscriptions-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-subscriptions"
            role="tab"
            aria-controls="tabs-subscriptions"
            aria-selected="false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2.5C17.2459 2.5 21.5 6.75315 21.5 12C21.5 17.2469 17.2459 21.5 12 21.5C6.75315 21.5 2.5 17.2469 2.5 12C2.5 6.75315 6.75315 2.5 12 2.5Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 11.9951C15 11.184 10.8425 8.58912 10.3709 9.0557C9.8993 9.52228 9.85395 14.424 10.3709 14.9346C10.8879 15.4469 15 12.8063 15 11.9951Z"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span className="ml-2 hidden ioigroup-hover:flex md:flex">
              Subscriptions
            </span>
          </a>
        </li>
        {/* COUNTS */}
        <li
          className={
            "hidden lg:flex items-center justify-around py-3 my-2 w-0 overflow-hidden transition-all duration-500 " +
            (stickyScroll > 0.7 ? "w-auto transition-all px-6" : "")
          }
        >
          <span className="flex items-center mx-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.592 15.2068C13.281 15.2068 16.434 15.7658 16.434 17.9988C16.434 20.2318 13.302 20.8068 9.592 20.8068C5.902 20.8068 2.75 20.2528 2.75 18.0188C2.75 15.7848 5.881 15.2068 9.592 15.2068Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.59108 12.0198C7.16908 12.0198 5.20508 10.0568 5.20508 7.6348C5.20508 5.2128 7.16908 3.2498 9.59108 3.2498C12.0121 3.2498 13.9761 5.2128 13.9761 7.6348C13.9851 10.0478 12.0351 12.0108 9.62208 12.0198H9.59108Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.4844 10.8816C18.0854 10.6566 19.3184 9.2826 19.3214 7.6196C19.3214 5.9806 18.1264 4.6206 16.5594 4.3636"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.5957 14.7322C20.1467 14.9632 21.2297 15.5072 21.2297 16.6272C21.2297 17.3982 20.7197 17.8982 19.8957 18.2112"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="ml-2">5.4k</p>
          </span>
          <span className="flex items-center mx-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 21.75C18.1569 21.75 19.5 20.4069 19.5 18.75C19.5 17.0931 18.1569 15.75 16.5 15.75C14.8431 15.75 13.5 17.0931 13.5 18.75C13.5 20.4069 14.8431 21.75 16.5 21.75Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 8.25C18.1569 8.25 19.5 6.90685 19.5 5.25C19.5 3.59315 18.1569 2.25 16.5 2.25C14.8431 2.25 13.5 3.59315 13.5 5.25C13.5 6.90685 14.8431 8.25 16.5 8.25Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.9777 6.87186C11.8469 8.24113 8.52148 10.3781 8.52148 10.3781"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.52148 13.6219L13.9777 17.1281"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="ml-2">5.4k</p>
          </span>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-search"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent md:px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-search-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-search"
            role="tab"
            aria-controls="tabs-search"
            aria-selected="false"
          >
            <span className="sr-only">search</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.7659"
                cy="11.7666"
                r="8.98856"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.0176 18.4851L21.5416 22"
                stroke="#A4A4A4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </li>
      </ul>
      {/* TABS CONTENT */}
      <div
        className="tab-content relative -top-96 pt-10 md:top-0 md:pt-0 w-[calc(100%_-_20px)] sm:w-[91%] md:w-full ml-auto"
        id="tabs-tabContent"
      >
        <div
          className="tab-pane fade min-h-[30vh] show active"
          id="tabs-about"
          role="tabpanel"
          aria-labelledby="tabs-about-tab"
        >
          <AboutUserTab />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-images"
          role="tabpanel"
          aria-labelledby="tabs-images-tab"
        >
          <ImagesTab />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-videos"
          role="tabpanel"
          aria-labelledby="tabs-videos-tab"
        >
          <VideosTab />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-courses"
          role="tabpanel"
          aria-labelledby="tabs-courses-tab"
        >
          <CoursesTab />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-subscriptions"
          role="tabpanel"
          aria-labelledby="tabs-subscriptions-tab"
        >
          <SubscriptionsTab />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-search"
          role="tabpanel"
          aria-labelledby="tabs-search-tab"
        >
          <SearchTab />
        </div>
      </div>
    </section>
  );
};

export default AboutTabs;
