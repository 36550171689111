import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeAgo from "react-timeago";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import {
  changeSelectedVideo,
  getAllVideos
} from "../../redux/reducers/mediaSlice";
import videoLogo from "../../assets/media/video.png"
import imageLogo from "../../assets/media/image.png"
import masterClassLogo from "../../assets/media/teacher.png"
import crowdFUndingLogo from "../../assets/media/teacher.png"
import Alerts from "../FlashMessage/Alerts";
import CustomCensor from "./CustomCensor";
import Backdrop from '@mui/material/Backdrop';
import { AnimatePresence, motion } from "framer-motion";


const Posts = () => {
  const {
    loading,
    error,
    videos,
    currentPage,
    errMsg,
    success,
  } = useSelector((state) => state.media);
  const { user } = useSelector(state => state.protection)
  const fullNameRef = useRef(user.fullname)
  const phoneNoRef = useRef(user.phone ?? "")
  const physicalAddressRef = useRef(user.physicalAddress ?? "")
  const saveAddressRef = useRef(true)
  const sizeRef = useRef("")

  console.log(videos)

  const videosToUse = videos.length > 20 ? videos.splice(20 * currentPage, (videos.length - 20 * currentPage) > 20 ? 20 : (videos.length - 20 * currentPage)) : videos

  const dispatch = useDispatch();
  function capitalizeFirstLetter(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
  let params = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [imageDetails, setImageDetails] = useState(null)
  const [showImage, setShowImage] = useState(false)
  const [showDropDown, setDropDown] = useState(false)
  const [restrictionMsg, setRestrictionMsg] = useState({ msg: "", id: "" });
  const [purchaseIndex, setPurchaseIndex] = useState(0)

  useEffect(() => {
    if (!loading && !error) {
      dispatch(getAllVideos());
    }
  }, [success]);


  const truncate = (title) => {
    if (title.length > 30) {
      return `${title.slice(0, 30)}...`;
    } else {
      return title;
    }
  };


  const mediaPath = (pageDetail) => {
    if (location.pathname.split('/').at(1) === "maisha-arts") {
      // MAISHA ARTS
      return (`/${location.pathname.split('/').at(1)}/${location.pathname.split('/').at(2)}${pageDetail}`)

    } else {
      // MAISHA DIGITAL
      return (`/${location.pathname.split('/').at(1)}${pageDetail}`)
    }

  }

  return <section className="mt-10 px-5 lg:px-8 xl:px-4 mb-8">
    {/* Posts */}
    {!error ?
      <>
        {loading ?
          <>
            <div className="items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch">
              {Array(12)
                .fill()
                .map((item, index) => {
                  return <div
                    key={index}
                    role="status"
                    className="post xl:max-w-[18rem] md:mx-3 lg:mx-4 mb-8 relative group flex flex-col justify-between animate-pulse"
                  >
                    <div className="rounded-lg mb-4 bg-gray min-h-[198px] lg:min-h-[216px] w-full"></div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="flex justify-between items-start text-sm w-full h-max-content mb-4">
                        <div className="h-7 w-9/12 bg-gray rounded-full"></div>
                        <div className="h-7 flex-1 bg-gray w-max px-2 py-1 rounded-full ml-3"></div>
                      </div>
                      <div className="h-5 w-full bg-gray rounded-full"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                  </div>
                })}
            </div>
          </>
          :
          <>
            <Alerts open={showAlert} close={() => {
              setShowAlert(false)
            }}>
              <CustomCensor message={restrictionMsg.msg.toUpperCase()} id={restrictionMsg.id} close={() => { setShowAlert(false) }} />
            </Alerts>
            {showImage && <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showImage}

            >
              <div className="w-3/6 h-5/6 bg-white rounded p-4 text-black relative">
                <div className="flex justify-between items-center">
                  <section className="flex basis-5/12 items-center">
                    <img src={imageLogo} alt="Channel Logo" srcset="" width="50px" height="50px" className="rounded-full  mr-5" />
                    <section className="flex flex-col">
                      <h1 className="font-bold" >{imageDetails.titleOfContent}</h1>
                      <h6>by Maisha Africa</h6>

                    </section>
                  </section>
                  <section className="flex basis-4/12 items-center">
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 2.42705L13.9809 8.52356L14.1493 9.0418H14.6942H21.1044L15.9184 12.8096L15.4776 13.1299L15.646 13.6482L17.6268 19.7447L12.4408 15.9768L12 15.6565L11.5592 15.9768L6.37316 19.7447L8.35404 13.6482L8.52242 13.1299L8.08158 12.8096L2.89559 9.0418H9.30583H9.85074L10.0191 8.52356L12 2.42705Z" fill="#9B1C28" stroke="#9B1C28" stroke-width="1.5" />
                    </svg>

                    <span className="mr-5">0</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M16.5 21.75C18.1569 21.75 19.5 20.4069 19.5 18.75C19.5 17.0931 18.1569 15.75 16.5 15.75C14.8431 15.75 13.5 17.0931 13.5 18.75C13.5 20.4069 14.8431 21.75 16.5 21.75Z" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M16.5 8.25C18.1569 8.25 19.5 6.90685 19.5 5.25C19.5 3.59315 18.1569 2.25 16.5 2.25C14.8431 2.25 13.5 3.59315 13.5 5.25C13.5 6.90685 14.8431 8.25 16.5 8.25Z" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M13.9777 6.87207L8.52148 10.3783" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.52148 13.6221L13.9777 17.1283" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    <span className="mr-5">150</span>
                  </section>
                  <section className="flex items-center">

                    <section className="flex basis-3/12 flex-col mr-5" onMouseEnter={(e) => {
                      setDropDown(true);
                    }} >
                      <button className="bg-strong-red p-2 text-white rounded">Download</button>

                      {showDropDown && <div className="bg-white rounded shadow p-5 absolute mt-5 w-60 md:w-96" >
                        <div className="flex justify-end">
                          <svg onClick={(e) => {
                            setDropDown(false)
                          }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 3.5L3.5 12.5" stroke="#414142" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.5 12.5L3.5 3.5" stroke="#414142" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </div>
                        <ul className="flex flex-row justify-evenly font-bold mb-3">
                          <motion.li className={purchaseIndex === 0 ? "border-b-4 w-full border-strong-red cursor-pointer text-center" : "w-full cursor-pointer text-center"} onClick={(e) => {
                            setPurchaseIndex(0)
                          }}>Digital Download</motion.li>
                          <motion.li className={purchaseIndex === 1 ? "border-b-4 w-full border-strong-red cursor-pointer text-center" : "w-full cursor-pointer text-center"} onClick={(e) => {
                            setPurchaseIndex(1)
                          }}>Physical Print</motion.li>
                        </ul>
                        {purchaseIndex === 0 ? <AnimatePresence>
                          <motion.ul initial={{
                            opacity: 0,
                            x: -30
                          }} animate={{
                            opacity: 1,
                            x: 0
                          }} exit={{
                            opacity: 0,
                            x: -30
                          }}>
                            {imageDetails.resolutions.map(e =>
                              <li className="text-lg" value={e._id}>
                                <a href={e.fileUrl} download>                                {capitalizeFirstLetter(e.category)}

                                </a>
                              </li>)}
                          </motion.ul>
                        </AnimatePresence> : <AnimatePresence>
                          <motion.ul initial={{
                            opacity: 0,
                            x: 30
                          }} animate={{
                            opacity: 1,
                            x: 0
                          }} exit={{
                            opacity: 0,
                            x: 30
                          }}>
                            <h1 className="font-bold">Please provide your delivery details</h1>
                            <input className="border-2 outline-none border-gray px-3 py-1 rounded-md w-full my-2" ref={fullNameRef} placeholder="Full Name" />
                            <input className="border-2 outline-none border-gray px-3 py-1 rounded-md w-full my-2" ref={physicalAddressRef} placeholder="Your Location" />
                            <input className="border-2 outline-none border-gray px-3 py-1 rounded-md w-full my-2" ref={phoneNoRef} placeholder="Phone Number" />
                            <div>
                              <input type="checkbox" ref={saveAddressRef} className="ring-strong-red" id="confirm-address" />
                              <label className="mx-2" for='confirm-address'>Save your delivery information?</label>
                            </div>

                            <h1 className="font-bold"> Please enter your preferred dimensions</h1>
                            <input ref={sizeRef} className="border-2 outline-none border-gray px-3 py-1 rounded-md w-full my-2" placeholder="E.g. 50 x 60" />
                            <button className="my-2 py-2 w-full bg-strong-red text-white rounded-md">Place order</button>
                          </motion.ul>
                        </AnimatePresence>}
                      </div>}
                    </section>
                    <span className="cursor-pointer text-strong-red" onClick={(e) => {
                      setShowImage(value => !value);
                    }}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.75 5.25L5.25 18.75" stroke="#414142" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.75 18.75L5.25 5.25" stroke="#414142" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                  </section>
                </div>
                {/* Photo Section */}
                <div className="flex m-3">
                  <section className="basis-2/4 mr-4 h-[350px] overflow-clip">
                    <img src={imageDetails.resolutions[0].fileUrl} className="bg-contain rounded shadow" />
                  </section>
                  <section className="basis-1/4 ">
                    <div className="my-2">
                      <p className="font-bold">Views</p>
                      <p>1300</p>
                    </div>
                    <div className="my-2">
                      <p className="font-bold">Downloads</p>
                      <p>1300</p>
                    </div>
                    <div className="my-2">
                      <p className="font-bold">Published at</p>
                      <p>{new Date(imageDetails.postDate).toDateString()}</p>
                    </div>
                    <div className="my-2">
                      <p className="font-bold">Description</p>
                      <p>{imageDetails.contentSummary.length > 50 ? imageDetails.contentSummary.slice(0, 50) + " ..." : imageDetails.contentSummary}</p>
                    </div>
                    {imageDetails.resolutions.length > 1 && <div className="my-2 flex" >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9846 21.606C11.9846 21.606 19.6566 19.283 19.6566 12.879C19.6566 6.474 19.9346 5.974 19.3196 5.358C18.7036 4.742 12.9906 2.75 11.9846 2.75C10.9786 2.75 5.26557 4.742 4.65057 5.358C4.03457 5.974 4.31257 6.474 4.31257 12.879C4.31257 19.283 11.9846 21.606 11.9846 21.606Z" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.38574 11.8746L11.2777 13.7696L15.1757 9.86963" stroke="#9B1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      <p className="font-bold text-strong-red">Paid Content</p>
                    </div>}
                  </section>
                </div>
              </div>
            </Backdrop>}
            <div className="items-start justify-center flex flex-wrap mb-8 max-w-xl lg:max-w-7xl mx-auto">
              {
                videosToUse.map((media, index) => {
                  const { _id, videoThumbnailUrl, titleOfContent, postDate, contentPrice, contentType, ageRestriction, waterMarkedUrl, resolutions, channelId } = media;
                  return <article
                    key={_id}
                    className="post xl:max-w-[18rem] md:mx-3 lg:mx-4 mb-8 relative group flex flex-col justify-between h-64 cursor-pointer "
                    onClick={(e) => {
                      e.preventDefault()
                      if (contentType == "image") {
                        setImageDetails(media)
                        setShowImage(value => { return !value })
                        return
                      }

                      if (contentType == "video") {
                        if (ageRestriction == "pg" || ageRestriction == "fourteen-plus" || ageRestriction == "eighteen-plus") {
                          switch (ageRestriction) {
                            case "pg":
                              setRestrictionMsg({ msg: "pg", id: _id })
                              break;
                            case "fourteen-plus":
                              setRestrictionMsg({ msg: "14+", id: _id })
                              break;
                            case "eighteen-plus":
                              setRestrictionMsg({ msg: "18+", id: _id })
                              break;

                            default:
                              setRestrictionMsg({ msg: "all", id: _id })
                              break;
                          }
                        }

                        if (ageRestriction != "all-age") {
                          setShowAlert(true);
                        } else {
                          dispatch(changeSelectedVideo(media))
                          navigate(`${_id}`)
                        }
                      }
                    }}>
                    <figure className="flex flex-col overflow-hidden mb-3">
                      <img
                        // src={videoThumbnailUrl ?? resolutions[0].fileUrl}
                        src={contentType === "video" ? videoThumbnailUrl : resolutions ? resolutions[0].fileUrl : ""}
                        width="300px"
                        height="250px"
                        alt="media thumbnail"
                        className="object-cover rounded-lg mb-4 bg-gray min-h-198 lg:min-h-216 transform transition duration-300 hover:scale-105"
                      />
                    </figure>
                    <img
                      src={contentType == "video" ? videoLogo : contentType == "image" ? imageLogo : contentType == "master-class" ? masterClassLogo : crowdFUndingLogo}
                      width="30px"
                      height='30px'
                      alt="corner image"
                      className="absolute top-1 right-1 bg-cover" />
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="flex justify-between items-start text-sm w-full h-max-content">
                        <h3 className="text-lg font-bold group-hover:text-gray">
                          {truncate(
                            titleOfContent
                              .replace(/&quot;/g, '"')
                              .replace(/&#39;/g, "'")
                              .replace(/&amp;/g, "&")
                          )}
                        </h3>
                        {/* {_id && (
                          <Link
                            to={`/media/${_id}`}
                            aria-hidden="true"
                            className="absolute inset-0"
                            aria-label="video link"
                          ></Link>
                        )} */}
                        {contentPrice == 0 ? (
                          <div className="text-dark-gray bg-very-light-gray font-bold text-sm w-max px-2 py-1 rounded-md ml-3">
                            Free
                          </div>
                        ) : (
                          <div className="text-white bg-red-600 font-bold text-sm w-max px-2 py-1 rounded-md ml-3">
                            ${contentType == "video" ? contentPrice.toFixed(2) : resolutions[0].price.toFixed(2)}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <p className="">
                          by{" "}
                          <span className="text-strong-red font-bold"> {channelId.channelName} </span>
                          <span className="rounded-full text-gray mx-1 relative bottom-1 text-4xl">
                            .
                          </span>
                          <span className="">
                            <TimeAgo date={postDate} />
                          </span>
                        </p>
                      </div>
                    </div>
                  </article>

                })
              }
            </div>
            <div className="pagination flex items-center justify-center my-6">
              <span className="font-bold">Page</span>
              <nav
                className="relative flex items-center font-bold"
                aria-label="pagination"
              >
                {/* <Link
                    to={curPage === 1 ? "" : mediaPath(`/page/${curPage - 1}`)}
                    className="relative inline-flex items-center p-4 text-sm font-medium text-gray hover:text-dark-gray"
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="h-5 w-5"
                      x-description="Heroicon name: solid/chevron-left"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link> */}
                <div className="flex">
                  {/* {displayNumbers.map((number, index) => (
                      <Link
                        to={curPage === number ? "" : mediaPath(`/page/${number}`)}
                        className={
                          "p-4 inline-flex items-center text-sm " +
                          (number === curPage
                            ? "text-strong-red"
                            : "text-gray hover:text-dark-gray")
                        }
                      >
                        {number}
                      </Link>
                    ))} */}
                </div>
                {/* <Link
                    to={
                      curPage === pageNumbers.length
                        ? ""
                        : mediaPath(`/page/${curPage + 1}`)
                    }
                    className="relative inline-flex items-center p-4 text-sm font-medium text-gray hover:text-dark-gray"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-5 w-5"
                      x-description="Heroicon name: solid/chevron-right"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link> */}
              </nav>

              {/* <a className=" " tabindex="-1" role="button" aria-disabled="true" aria-label="Previous page" rel="prev">&lt;</a>
            <a rel="canonical" role="button" tabindex="-1" aria-label="Page 1 is your current page" aria-current="page">1</a>
            <a role="button" tabindex="0" aria-label="Page 3">3</a> */}
            </div>
          </>
        }
      </>
      :
      <>
        <p className=" mt-20 text-center text-very-dark-blue min-h-[30vh]">
          {errMsg}. Reload page
        </p>
      </>
    }
  </section>

};

export default Posts;
