import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/Media/Filter";
import Posts from "../../components/Media/Posts";
import OnInstagram from "../../components/Home/OnInstagram";
import { getAllVideos } from "../../redux/reducers/mediaSlice";

const Media = () => {

  return (
    <>
      <Filter />
      <Posts />
      <OnInstagram />
    </>
  );
};

export default Media;
