import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const validateAge = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age >= 18;
};

const CustomCensor = (props) => {
    const navigate = useNavigate();
    const [birthDate, setBirthDate] = useState("");
    const [error, setError] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateAge(birthDate)) {
            setError("You must be 18 years old to proceed");
        } else {
            setError(null);
            // Proceed with form submission
            navigate(`${props.id}`)
        }
    };
    return (
        <div className="flex flex-col items-center bg-black p-4 rounded w-5/6 h-5/6 justify-center">
            <div className='w-full flex ml-32' onClick={() => { props.close() }}>
                <i className="fas fa-arrow-left" ></i>
            </div>
            <h1 className="text-6xl">{props.message}</h1>
            <h3>To view this content</h3>
            <h3> Please verify you are over {props.message}</h3>

            <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md text-black flex flex-col items-center mt-10">
                <div className="mb-4">
                    <label
                        className="block font-medium text-sm text-gray-700"
                        htmlFor="birthDate"
                    >
                        Birth Date
                    </label>
                    <input
                        type="date"
                        id="birthDate"
                        value={birthDate}
                        onChange={(event) => setBirthDate(event.target.value)}
                        className="w-full border border-gray-400 p-2"
                    />
                </div>
                {error && (
                    <div className="mb-4 text-red-500 text-xs">{error}</div>
                )}
                <button
                    type="submit"
                    className=" text-black font-medium py-2 px-4 rounded-full hover:bg-indigo-600 bg-gray "
                >
                    Proceed
                </button>
            </form>
        </div>
    )
}

export default CustomCensor