import React from "react";
import YouTube from "react-youtube";

const AboutMaishaTab = () => {
  // YOUTUBE VIDEO FUNCTION
  const opts = {
    width: "100%",
    borderRadius: "2rem",
    playerVars: {
      autoplay: 1,
    },
  };

  const videoReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <>
      <h2 className="font-bold text-2xl">About Maisha Africa</h2>
      <p className="mt-4">
        Maisha Digital make full use of new media, from targeted digital
        strategy and social media communication, painstaking data analysis and
        disruptive communication campaigns that allows African stories to create
        top of mind awareness for an intended African audience.
      </p>
      <p className="mt-4">
        You get good at what you focus on, which is why our service niche is
        precise; defined by our passions and social concerns. We care deeply
        about amplifying African women, educating the African child and
        inspiring the next generation, sustainability and our harmony with our
        environment. Above all, we care about constructive narratives about
        Africa. So much in fact, that we make sure we embody these very values
        ourselves: Social and cultural relevance, creative professionalism and
        originality.
      </p>

      <h3 className="font-bold mt-8">Animation Suites</h3>
      <h4 className="mt-6">
        1.
        <span className="ml-2 border-b w-fit">
          Standard animation suite (Graphic and Times)
        </span>
      </h4>
      <p className="mt-4">
        If you want to cut through online noise, and grab and hold your audience
        attention, don’t just use pictures or videos. Use animated explainer
        videos that quickly effectively captivate your target audience with
        Facts about your brand or social cause. Don’t just do it with any old
        animation. Do it with Graphic and Times from Maisha Online Africa. For
        example
      </p>

      <h4 className="mt-6">
        2.
        <span className="ml-2 border-b w-fit">
          Advanced animation suite (Plantain Chips)
        </span>
      </h4>
      <p className="mt-4">
        If you want to cut through online noise, and grab and hold your audience
        attention, don’t just use pictures or videos. Use animated explainer
        videos that quickly effectively captivate your target audience with
        Facts about your brand or social cause. Don’t just do it with any old
        animation. Do it with Graphic and Times from Maisha Online Africa. For
        example
      </p>

      <h4 className="mt-6">
        3.
        <span className="ml-2 border-b w-fit">
          Premium animation suite (Kelewele)
        </span>
      </h4>
      <p className="mt-4">
        If you want to cut through online noise, and grab and hold your audience
        attention, don’t just use pictures or videos. Use animated explainer
        videos that quickly effectively captivate your target audience with
        Facts about your brand or social cause. Don’t just do it with any old
        animation. Do it with Graphic and Times from Maisha Online Africa. For
        example
      </p>
      <div className="">
        <div className="max-w-3xl mt-8 mx-auto w-full bg-very-light-gray mb-8 lg:mb-0">
          <YouTube
            className="rounded-md overflow-hidden"
            videoId="2g811Eo7K8U"
            opts={opts}
            onReady={videoReady}
          />
        </div>
        <div className="flex items-start flex-col lg:flex-row justify-between mt-7 max-w-3xl mx-auto w-full">
          <div className="w-full">
            <h2 className="font-bold text-lg lg:text-2xl mb-4">
              BLU 'The Entrepreneur Heavy User' "Enter the BLU Zone"(2017)
            </h2>
            <p className="published-at pr-4 sm:pr-0 mb-4">
              Published: Tue-Jul-19-2022 by{" "}
              <span className="font-bold text-strong-red">Maisha Digital</span>
            </p>
          </div>
          <div className="mt-4 flex items-center justify-between flex-1 w-full">
            <svg
            className="mx-2 md:mx-3"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.0714 18.0699C15.0152 21.1263 10.4898 21.7867 6.78642 20.074C6.23971 19.8539 5.79148 19.676 5.36537 19.676C4.17849 19.683 2.70117 20.8339 1.93336 20.067C1.16555 19.2991 2.31726 17.8206 2.31726 16.6266C2.31726 16.2004 2.14642 15.7602 1.92632 15.2124C0.212831 11.5096 0.874109 6.98269 3.93026 3.92721C7.8316 0.0244319 14.17 0.0244322 18.0714 3.9262C21.9797 7.83501 21.9727 14.1681 18.0714 18.0699Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.9389 11.413H14.9479"
                stroke="#414142"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.931 11.413H10.94"
                stroke="#414142"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.92128 11.413H6.93028"
                stroke="#414142"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
            className="mx-2 md:mx-3"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.227 1.79648L14.2574 6.09148C14.4564 6.51312 14.8405 6.8055 15.2859 6.87316L19.8282 7.56543C20.9504 7.73699 21.397 9.17711 20.5848 10.0023L17.3002 13.344C16.9774 13.6727 16.8305 14.1451 16.9068 14.609L17.682 19.3268C17.8729 20.4939 16.6997 21.3843 15.6967 20.8322L11.6369 18.6032C11.2389 18.3845 10.7622 18.3845 10.3631 18.6032L6.30334 20.8322C5.30026 21.3843 4.1271 20.4939 4.31916 19.3268L5.09316 14.609C5.16952 14.1451 5.02259 13.6727 4.6998 13.344L1.4152 10.0023C0.603017 9.17711 1.0496 7.73699 2.17185 7.56543L6.71406 6.87316C7.15949 6.8055 7.54475 6.51312 7.74375 6.09148L9.77305 1.79648C10.2752 0.734507 11.7248 0.734507 12.227 1.79648Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
            className="mx-2 md:mx-3"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 21.75C18.1569 21.75 19.5 20.4069 19.5 18.75C19.5 17.0931 18.1569 15.75 16.5 15.75C14.8431 15.75 13.5 17.0931 13.5 18.75C13.5 20.4069 14.8431 21.75 16.5 21.75Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 8.25C18.1569 8.25 19.5 6.90685 19.5 5.25C19.5 3.59315 18.1569 2.25 16.5 2.25C14.8431 2.25 13.5 3.59315 13.5 5.25C13.5 6.90685 14.8431 8.25 16.5 8.25Z"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.9777 6.87183L8.52148 10.3781"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.52148 13.6218L13.9777 17.1281"
                stroke="#414142"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

          </div>
        </div>
      </div>
      <p className="mt-4">
        Our Standard animation suite uses eye-catching static and semi-static
        character and prop designs, animated font, narration and music in a
        presentation format t present Facts that gives audience a real reason to
        care about your brand and product. Contact us at info@maishaafrica.com
        to find out how to engage our services.
      </p>
    </>
  );
};

export default AboutMaishaTab;
