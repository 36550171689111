import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import axios from "axios";


export const getCountries = createAsyncThunk(
  'user/getCountries',
  async (thunkAPI) => {
    try {
      let { data } = await axios.get('https://restcountries.com/v2/all?fields=name,alpha2Code')
      let countries = data
      return countries
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

const userSlice = createSlice({
  name: "user",
  initialState: {
    countriesLoading: true,
    countries: [],
    error: false,
    errMsg: ''
  },
  reducers: {},
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.countriesLoading = true
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countriesLoading = false
      state.countries = payload
    },
    [getCountries.rejected]: (state, action) => {
      state.countriesLoading = false
      state.error = true
      state.errMsg = action.error.message
    },
  }
})

// export const {  } = userSlice.actions
export default userSlice.reducer;