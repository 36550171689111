import React, { useEffect } from 'react'
import ClassComments from '../../components/Channels/MasterClass/ClassComments'
import ClassDetails from '../../components/Channels/MasterClass/ClassDetails'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSingleMasterClass } from "../../redux/reducers/masterClassSlice"
import { useSearchParams } from 'react-router-dom'


const MasterClass = () => {
  const { loading, masterClass, error, errorMsg, isEnrolled, otherCourseSlice, otherCourseLoading } = useSelector(state => state.masterclass)
  const dispatch = useDispatch()
  const [query] = useSearchParams()

  useEffect(() => {
    dispatch(fetchSingleMasterClass({ id: query.get("id") }))
  }, [isEnrolled])
  if (loading || masterClass == null) {
    return <div className='mx-auto w-[80%] h-[40vh] animate-pulse'></div>
  }
  return (
    <div className="mt-16 px-5 lg:px-8 xl:px-4 mb-8 min-h-[50vh]">
      <ClassDetails masterClass={masterClass} otherCourses={otherCourseSlice} othersLoading={otherCourseLoading} />
      <ClassComments id={masterClass._id} />
    </div>
  )
}


export default MasterClass