import React from 'react'
import dummyProduct from "../../assets/shop/dummyItem.png"
import { useLocation, useParams } from 'react-router-dom'
import { Rating } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import { useState } from 'react'
import ReviewItem from './ReviewItem'
import TestimonialItem from './TestimonialItem'
import ShopItem from './ShopItem'

const ShopDetail = () => {
    const { id } = useParams()
    const [starCount, setStarCount] = useState(0)
    const [onReview, setOnReview] = useState(true)

    return (
        <div className='mx-10 my-5'>
            <div className='flex'>
                <div className='flex flex-col basis-1/12 h-[350px]'>
                    {Array(4).fill().map((_, index) => {
                        return (<div className='my-1 cursor-pointer'>
                            <img src={dummyProduct} className='w-[80%] h-full object-contain' />
                        </div>)
                    })}
                </div>
                <div className='basis-6/12 h-[350px]'>
                    <img src={dummyProduct} className='w-full h-full object-contain' />
                </div>
                <div className='basis-5/12'>
                    <span className='flex flex-row justify-between my-3'>
                        <p className='text-2xl'>African Carved Calabash</p>
                        <p className='text-lg font-bold'>275 GHS </p>
                    </span>
                    <p className='mt-5 font-bold'>Description</p>
                    <span>An African carved calabash with rhino designs and African patterns is a unique and
                        visually striking piece of artwork. The calabash, a large gourd-like fruit often used for
                        various purposes in African culture, serves as the canvas for this intricate carving.</span>
                    <p className='mt-5 font-bold'>Details</p>
                    <ol className='list-disc ml-5'>
                        <li>Hand Painted</li>
                        <li>Natural Material</li>
                    </ol>
                    <p className='mt-5'>Sold by: <span className='text-strong-red font-bold'>Bright Ackwerh </span></p>
                    <p className='mt-2'>In stock: 15 Items</p>
                    <div className='flex items-center'>
                        <Rating orientation='horizontal' className='my-2' style={{ maxWidth: 175 }} value={starCount} onChange={(value) => {
                            setStarCount(value);
                        }} />
                        <p className='mx-2'>(5)</p>
                    </div>
                    <div className='my-5'>
                        <p className='text-gray'>Quality</p>
                        <div className='flex mx-4 '>
                            <section className='flex justify-between border-2 rounded-md px-3 py-2 mr-3 basis-1/6'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9199 22.75C5.99992 22.75 1.16992 17.93 1.16992 12C1.16992 6.07 5.99992 1.25 11.9199 1.25C17.8399 1.25 22.6699 6.07 22.6699 12C22.6699 17.93 17.8499 22.75 11.9199 22.75ZM11.9199 2.75C6.81992 2.75 2.66992 6.9 2.66992 12C2.66992 17.1 6.81992 21.25 11.9199 21.25C17.0199 21.25 21.1699 17.1 21.1699 12C21.1699 6.9 17.0199 2.75 11.9199 2.75Z" fill="#A4A4A4" />
                                    <path d="M15.9199 12.75H7.91992C7.50992 12.75 7.16992 12.41 7.16992 12C7.16992 11.59 7.50992 11.25 7.91992 11.25H15.9199C16.3299 11.25 16.6699 11.59 16.6699 12C16.6699 12.41 16.3399 12.75 15.9199 12.75Z" fill="#A4A4A4" />
                                </svg>
                                <p className='mx-3'> 0</p>

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#414142" />
                                    <path d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#414142" />
                                    <path d="M12 16.75C11.59 16.75 11.25 16.41 11.25 16V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V16C12.75 16.41 12.41 16.75 12 16.75Z" fill="#414142" />
                                </svg>

                            </section>
                            <section className='basis-5/6 w-full text-center bg-strong-red text-white py-2 font-bold rounded-md'>
                                Buy this Item
                            </section>
                        </div>
                    </div>

                </div>
            </div>
            {/* Testimonial Section */}
            <div className='flex flex-row justify-between'>
                <div className='flex'>
                    <p className={`${onReview ? 'font-extrabold' : 'font-extrabold text-gray'} text-xl mx-3 cursor-pointer`} onClick={(e) => {
                        setOnReview(true)
                    }}>Review for this item (5)</p>
                    <p className={`${!onReview ? 'font-extrabold' : 'font-extrabold text-gray'} text-xl mx-3 cursor-pointer`} onClick={(e) => {
                        setOnReview(false)
                    }}>Testimonials (5)</p>
                </div>
                <p className='text-strong-red underline font-semibold cursor-pointer'>{onReview ? "Leave a Review" : "Leave a Testimonial"}</p>

            </div>
            {onReview ? <div>
                <ReviewItem />
            </div> : <div>
                <TestimonialItem />
            </div>}
            <div className='my-5'>
                <p className='text-xl'>Other Items from this Seller</p>
                <hr className='text-very-light-gray border rounded' />
            </div>
            <div className='flex py-5'>
                {Array(3).fill().map((e, index) => (<ShopItem index={index} />))}
            </div>
        </div>
    )
}

export default ShopDetail