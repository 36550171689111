import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";


export const fecthRecommendedForYou = createAsyncThunk("fecthRecommendedForYou", async (payload, thunkAPI) => {
    try {
        const response = await axios.get(baseUrl + "/media/suggested")

        thunkAPI.fulfillWithValue(response.data.media)
        return response.data.media
    } catch (err) {
        thunkAPI.rejectWithValue(err.message)
    }

})

const recommendedForYouSlice = createSlice({
    name: "recommendedForYou/Slice",
    initialState: {
        loading: false,
        error: true,
        medias: []
    },
    extraReducers: (builder) => {
        builder.addCase(fecthRecommendedForYou.fulfilled, (state, action) => {
            state.medias = action.payload
            state.loading = false
            state.error = false
        })
        builder.addCase(fecthRecommendedForYou.pending, (state, action) => {
            state.loading = true
            state.error = false
        })
        builder.addCase(fecthRecommendedForYou.rejected, (state, action) => {
            state.loading = false
            state.error = true
        })
    }
})
export default recommendedForYouSlice.reducer;