import React from "react";
import maishaLogo from "../../assets/user/maisha-logo.svg";
import maishaFace from "../../assets/user/maisha-face.svg";
import { Outlet } from "react-router-dom";

const UserForm = () => {
  return (
    <section className="relative flex flex-col items-start mt-8 min-h-screen pb-20">
      <div className="logo mt-10 w-full">
        <img src={maishaLogo} className="mx-auto" alt="maisha" />
      </div>
      <div className="absolute">
        <img src={maishaFace} alt="maisha face" className="" />
      </div>

      <div className="flex flex-col w-full items-center mt-12 lg:mt-20 relative">
        <Outlet />
      </div>
    </section>
  );
};

export default UserForm;
