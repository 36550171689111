import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/maisha-logo.svg";

const Footer = () => {
  return (
    <footer className="min-h-[30vh] bg-very-light-gray text-dark-gray">
      <div className="px-5 py-12 max-w-xl mx-auto lg:max-w-7xl flex flex-col md:flex-row md:flex-wrap justify-center lg:justify-between items-center lg:items-start">
        <div className="text-center lg:text-left w-full lg:w-auto mb-6 lg:mb-0 max-md lg:max-w-xs xl:max-w-sm">
          <a
            href="/"
            className="logo flex justify-center lg:justify-start flex-col lg:flex-row items-center lg:items-start"
          >
            <div className="bg-white w-20 h-20 rounded-md mb-1 lg:mb-0">
              <img src={logo} alt="maisha logo" className="mx-auto px-4 py-2.5" />
            </div>
            <span className="text-2xl font-medium ml-4">Maisha Digital</span>
          </a>
          <p className="mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
            diam pretium maecenas ultricies odio sagittis at purus enim. Morbi
            ac facilisis Blandit diam pretium maecenas
          </p>
        </div>
        <div className="text-center lg:text-left w-full md:w-1/3 lg:w-auto lg:mx-3 xl:mx-8 mb-6 lg:mb-0">
          <h4 className="font-bold text-2xl">Site</h4>
          <ul className="site mt-4 text-sm">
            <li className="mb-2 lg:mb-6">
              <Link className="hover:text-strong-red transition-all" to="media">
                Media
              </Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-strong-red transition-all" to="wiki">
                Wiki
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-center lg:text-left w-full md:w-1/3 lg:w-auto lg:mx-3 xl:mx-8 mb-6 lg:mb-0">
          <h4 className="font-bold text-2xl">Services</h4>
          <ul className="services mt-4 text-sm">
            <li className="mb-2 lg:mb-6">
              <Link className="hover:text-strong-red transition-all" to="crowdfunding">
                Crowdfunding
              </Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-strong-red transition-all" to="forum">
                Forum
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-center lg:text-left w-full md:w-1/3 relative md:top-3 lg:top-0 lg:w-auto lg:mx-3 xl:mx-8 mb-6 lg:mb-0">
          <h4 className="font-bold text-2xl">About Us</h4>
          <ul className="about-us mt-4 text-sm">
            <li className="mb-2 lg:mb-6">
              <Link className="hover:text-strong-red transition-all" to="#">
                About Us
              </Link>
            </li>
            <li className="mb-2 lg:mb-6">
              <Link className="hover:text-strong-red transition-all" to="#">
                Services
              </Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-strong-red transition-all" to="#">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-center lg:text-left w-full lg:flex-1 lg:max-w-md lg:w-auto lg:mx-3 xl:mx-8 mb-6 lg:mb-0">
          <h4 className="font-bold text-2xl">Subscribe</h4>
          <form className="flex mt-4 flex-col lg:flex-row">
            <label
              htmlFor="email"
              className="block text-sm font-medium sr-only"
            >
              email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="focus:outline-none w-full pl-2 xl:pl-6 h-14 rounded-md relative border border-dark-gray bg-transparent w-full lg:max-w-sm"
              placeholder="Email"
            />
            <button
              type="submit"
              className="bg-strong-red rounded-md h-14 px-6 text-white lg:ml-4 mt-3 lg:mt-0 hover:bg-white hover:text-strong-red transition-all"
            >
              Subscribe
            </button>
          </form>
          <div className="social flex justify-between items-center mt-8 mb-5 w-48 mx-auto lg:ml-0">
            <a href="#" aria-label="facebook">
              <i className="text-2xl hover:text-strong-red fab fa-facebook"></i>
            </a>
            <a href="#" aria-label="twitter">
              <i className="text-2xl hover:text-strong-red fab fa-twitter"></i>
            </a>
            <a href="#" aria-label="instagram">
              <i className="text-2xl hover:text-strong-red fab fa-instagram"></i>
            </a>
            <a href="#" aria-label="youtube">
              <i className="text-2xl hover:text-strong-red fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="copyright flex mx-auto text-center justify-center w-full pb-12">
        © 2022 All rights reserved. <strong> Maisha Africa</strong>
      </div>
    </footer>
  );
};

export default Footer;
