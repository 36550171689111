import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";

export const fetchMedias = createAsyncThunk("fetchMedias", async () => {
    const response = await fetch(baseUrl + "/media/all?mediaType=master-class")
    const data = await response.json();
    console.log(data["media"])
    return data["media"]
})
export const fetchChannels = createAsyncThunk("fetchChannels", async () => {
    const response = await fetch(baseUrl + "/channel/channels/all")
    const data = await response.json()
    console.log(data["channel"])
    return data["channel"];
})

const channelSlice = createSlice({
    name: "channel Slice",
    initialState: {
        masterclass: [],
        channels: [],
        loading: false
    },
    extraReducers: builder => {
        builder.addCase(fetchMedias.fulfilled, (state, action) => {
            state.masterclass = action.payload
            state.loading = false

        })
        builder.addCase(fetchMedias.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchMedias.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(fetchChannels.fulfilled, (state, action) => {
            state.channels = action.payload
            state.loading = false
        })
        builder.addCase(fetchChannels.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchChannels.rejected, (state) => {
            state.loading = false
        })
    }
})
export default channelSlice.reducer;