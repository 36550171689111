import { createSlice } from "@reduxjs/toolkit"

const AddCourseSlice = createSlice({
    name: "addCoure/slice",
    initialState: [],
    reducers: {
        addField(state, action) {
            console.log(state);
        }
    }
})

export default AddCourseSlice.reducer
export const { addField } = AddCourseSlice.actions