import React from "react";
import { Link } from "react-router-dom";

const ResultItem = (props) => {
  const data = props.e
  console.log("Data ---------", data)

  return (
    <article className="text-dark-gray mb-12">
      <Link to={{
        pathname: `/wiki/${data.title.replace(" ", "%20")}`,
        search: `?id=${data._id}`
      }} state={data} className="text-xl font-bold mb-2 block">
        {data.title}
      </Link>
      <time dateTime="13-09-2022">{new Date(data.editDate).toDateString()}</time>
      <p className="">
        {data.description}
      </p>
    </article>
  );
};

export default ResultItem;
