import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeAgo from "react-timeago";
import { replyCommentForm } from "../../../redux/reducers/forumSlice";
import { fetchCommentAsync } from "../../../redux/reducers/masterClassSlice"
const ClassComments = ({ id }) => {
  const { loading, error, errMsg, forumComments, showReplyForm, curComment, comments,
    commentsLoading } =

    useSelector((state) => state.masterclass);

  const dispatch = useDispatch();
  console.log(comments)
  useEffect(() => {
    dispatch(fetchCommentAsync({ id: id }));

    // eslint-disable-next-line
  }, [commentsLoading]);
  if (!commentsLoading) {

    return <div>
      No Comments for this Media
    </div>


  }
  return (
    <div className="comments mt-10">
      {comments.map((comment, index) => (
        <div
          key={comment._id}
          className="comment-container flex mb-10"
        >
          <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
            <img
              src={
                comment.userId.imageUrl
              }
              alt="author profile icon"
              className="w-full"
            />
          </div>
          <div className="flex-1 user-info">
            <div className="flex text-dark-gray">
              <h3 className="font-bold user-name">
                {
                  comment.userId.fullname
                }
              </h3>
              <span className="ml-2 text-gray">
                <TimeAgo
                  date={
                    comment.postDate
                  }
                />
              </span>
            </div>
            <div className="comment-text">
              {comment.content}{" "}
              {""}
              <button
                onClick={() =>
                  dispatch(
                    replyCommentForm({
                      id: comment._id,
                      display: true,
                    })
                  )
                }
                className="border-b font-bold ml-1 hover:text-strong-red"
              >
                Reply
              </button>
            </div>
            {false && (
              // REPLY COMMENT FORM

              <div className="mt-8 flex lg:items-center">
                <div className="rounded-full border border-dark-gray w-9 lg:w-12 h-9 lg:h-12 overflow-hidden">
                  {/* <img
                                src="user's image display icon"
                                alt="user's display icon"
                                className="w-full h-full rounded-full"
                              /> */}
                </div>
                <form className="w-full flex-1 pl-5 lg:pl-0 flex items-center justify-between flex-row flex-wrap lg:flex-nowrap">
                  <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
                    <input
                      id="reply"
                      name="reply"
                      type="text"
                      className="h-8 text-sm p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
                      placeholder="Reply here"
                    />
                    <label htmlFor="reply" className="sr-only">
                      Reply here
                    </label>
                  </div>
                  <button
                    // type="submit"
                    className="h-8 text-sm w-full flex-1 lg:flex-none lg:max-w-[7rem] ml-auto mr-2 lg:mr-0 flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
                  >
                    Reply
                  </button>
                  <button
                    // type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        replyCommentForm({
                          id: comment._id,
                          display: false,
                        })
                      );
                    }}
                    className="text-sm p-2 ml-3 flex items-center justify-center bg-very-light-gray text-gray rounded-md hover:bg-gray hover:text-very-light-gray transtion-all"
                  >
                    <ion-icon name="close" class="text-lg"></ion-icon>
                  </button>
                </form>
              </div>
            )}
            {comment.replies.length > 0 && (
              <div className="comment-replies">
                <div className="accordion" id="repliesAccordion">
                  <div className="accordion-item bg-white">
                    <div
                      className="accordion-header mb-0"
                      id={`heading1${comment._id.slice(0, 5)}`}
                    >
                      <button
                        className=" accordion-button !shadow-none !text-strong-red relative flex items-center w-full pt-4 transition focus:outline-none collapsed after:!bg-auto after:!ml-2 after:!w-3 after:!h-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse1${comment._id.slice(
                          0,
                          5
                        )}`}
                        aria-expanded="true"
                        aria-controls={`collapse1${comment._id.slice(
                          0,
                          5
                        )}`}
                      >
                        <h4 className="font-bold text-strong">
                          {comment.replies.length} Replies
                        </h4>
                      </button>
                    </div>
                    {comment.replies.map((reply, index) => (
                      <div
                        key={index}
                        id={`collapse1${reply._id.slice(0, 5)}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading1${reply._id.slice(
                          0,
                          5
                        )}`}
                        data-bs-parent="#repliesAccordion"
                      >
                        <div className="accordion-body py-4 lg:px-5">
                          <div className="comment-container flex">
                            <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
                              <img
                                src={
                                  reply.userId.imageUrl
                                }
                                alt="author profile icon"
                                className="w-full"
                              />{" "}
                            </div>
                            <div className="flex-1 user-info">
                              <div className="flex items-center text-dark-gray">
                                <h3 className="user-name font-bold flex-1">
                                  {reply.userId.fullname}
                                </h3>
                                <span className="ml-2 text-gray text-xs sm:text-sm lg:text-base">
                                  <TimeAgo
                                    date={reply.postDate}
                                  />
                                </span>
                              </div>
                              <div className="comment-text">
                                {reply.content}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )

  // (
  //   <div className="max-w-xl lg:max-w-7xl mx-auto ">
  //     <div className="mt-6 flex lg:items-center w-full lg:w-8/12">
  //       <div className="rounded-full border border-dark-gray w-12 h-12 overflow-hidden">
  //         <img
  //           src="https://yt3.ggpht.com/xGjOkcPW4OShTDzH9sFwNU0EfZbhL3fjgtEZ1AyUOKIiO4V1XOPXzGHd5Ewc1yWBdAuPG2YNCuI=s88-c-k-c0x00ffffff-no-rj"
  //           alt="maisha digital youtube logo"
  //           className="w-full h-full rounded-full"
  //         />
  //       </div>
  //       <form className="w-full pl-5 lg:pl-0 flex items-center justify-between flex-col lg:flex-row mt-6">
  //         <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
  //           <input
  //             id="comment"
  //             name="comment"
  //             type="text"
  //             className="h-11 p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
  //             placeholder="Comment here"
  //           />
  //           <label htmlFor="comment" className="sr-only">
  //             Comment here
  //           </label>
  //         </div>
  //         <button
  //           // type="submit"
  //           className="h-11 w-full max-w-[10rem] ml-auto flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
  //         >
  //           Comment
  //         </button>
  //       </form>
  //     </div>
  //     <>
  //       {!error ? (
  //         <>
  //           {loading ? (
  //             <p className="min-h-[30vh] text-center w-full font-semibold mt-10">
  //               Loading...
  //             </p>
  //           ) : (
  //             <div className="comments mt-10">
  //               {forumComments.items.map((comment, index) => (
  //                 <div
  //                   key={comment.id}
  //                   className="comment-container flex mb-10"
  //                 >
  //                   <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
  //                     <img
  //                       src={
  //                         comment.snippet.topLevelComment.snippet
  //                           .authorProfileImageUrl
  //                       }
  //                       alt="author profile icon"
  //                       className="w-full"
  //                     />
  //                   </div>
  //                   <div className="flex-1 user-info">
  //                     <div className="flex text-dark-gray">
  //                       <h3 className="font-bold user-name">
  //                         {
  //                           comment.snippet.topLevelComment.snippet
  //                             .authorDisplayName
  //                         }
  //                       </h3>
  //                       <span className="ml-2 text-gray">
  //                         <TimeAgo
  //                           date={
  //                             comment.snippet.topLevelComment.snippet
  //                               .publishedAt
  //                           }
  //                         />
  //                       </span>
  //                     </div>
  //                     <div className="comment-text">
  //                       {comment.snippet.topLevelComment.snippet.textOriginal}{" "}
  //                       {""}
  //                       <button
  //                         onClick={() =>
  //                           dispatch(
  //                             replyCommentForm({
  //                               id: comment.id,
  //                               display: true,
  //                             })
  //                           )
  //                         }
  //                         className="border-b font-bold ml-1 hover:text-strong-red"
  //                       >
  //                         Reply
  //                       </button>
  //                     </div>
  //                     {showReplyForm && curComment === comment.id && (
  //                       // REPLY COMMENT FORM

  //                       <div className="mt-8 flex lg:items-center">
  //                         <div className="rounded-full border border-dark-gray w-9 lg:w-12 h-9 lg:h-12 overflow-hidden">
  //                           {/* <img
  //                             src="user's image display icon"
  //                             alt="user's display icon"
  //                             className="w-full h-full rounded-full"
  //                           /> */}
  //                         </div>
  //                         <form className="w-full flex-1 pl-5 lg:pl-0 flex items-center justify-between flex-row flex-wrap lg:flex-nowrap">
  //                           <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
  //                             <input
  //                               id="reply"
  //                               name="reply"
  //                               type="text"
  //                               className="h-8 text-sm p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
  //                               placeholder="Reply here"
  //                             />
  //                             <label htmlFor="reply" className="sr-only">
  //                               Reply here
  //                             </label>
  //                           </div>
  //                           <button
  //                             // type="submit"
  //                             className="h-8 text-sm w-full flex-1 lg:flex-none lg:max-w-[7rem] ml-auto mr-2 lg:mr-0 flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
  //                           >
  //                             Reply
  //                           </button>
  //                           <button
  //                             // type="submit"
  //                             onClick={(e) => {
  //                               e.preventDefault();
  //                               dispatch(
  //                                 replyCommentForm({
  //                                   id: comment.id,
  //                                   display: false,
  //                                 })
  //                               );
  //                             }}
  //                             className="text-sm p-2 ml-3 flex items-center justify-center bg-very-light-gray text-gray rounded-md hover:bg-gray hover:text-very-light-gray transtion-all"
  //                           >
  //                             <ion-icon name="close" class="text-lg"></ion-icon>
  //                           </button>
  //                         </form>
  //                       </div>
  //                     )}
  //                     {comment.snippet.totalReplyCount > 0 && (
  //                       <div className="comment-replies">
  //                         <div className="accordion" id="repliesAccordion">
  //                           <div className="accordion-item bg-white">
  //                             <div
  //                               className="accordion-header mb-0"
  //                               id={`heading1${comment.id.slice(0, 5)}`}
  //                             >
  //                               <button
  //                                 className=" accordion-button !shadow-none !text-strong-red relative flex items-center w-full pt-4 transition focus:outline-none collapsed after:!bg-auto after:!ml-2 after:!w-3 after:!h-2"
  //                                 type="button"
  //                                 data-bs-toggle="collapse"
  //                                 data-bs-target={`#collapse1${comment.id.slice(
  //                                   0,
  //                                   5
  //                                 )}`}
  //                                 aria-expanded="true"
  //                                 aria-controls={`collapse1${comment.id.slice(
  //                                   0,
  //                                   5
  //                                 )}`}
  //                               >
  //                                 <h4 className="font-bold text-strong">
  //                                   {comment.snippet.totalReplyCount} Replies
  //                                 </h4>
  //                               </button>
  //                             </div>
  //                             {comment.replies.comments.map((reply, index) => (
  //                               <div
  //                                 key={index}
  //                                 id={`collapse1${reply.id.slice(0, 5)}`}
  //                                 className="accordion-collapse collapse"
  //                                 aria-labelledby={`heading1${reply.id.slice(
  //                                   0,
  //                                   5
  //                                 )}`}
  //                                 data-bs-parent="#repliesAccordion"
  //                               >
  //                                 <div className="accordion-body py-4 lg:px-5">
  //                                   <div className="comment-container flex">
  //                                     <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
  //                                       <img
  //                                         src={
  //                                           reply.snippet.authorProfileImageUrl
  //                                         }
  //                                         alt="author profile icon"
  //                                         className="w-full"
  //                                       />{" "}
  //                                     </div>
  //                                     <div className="flex-1 user-info">
  //                                       <div className="flex items-center text-dark-gray">
  //                                         <h3 className="user-name font-bold flex-1">
  //                                           {reply.snippet.authorDisplayName}
  //                                         </h3>
  //                                         <span className="ml-2 text-gray text-xs sm:text-sm lg:text-base">
  //                                           <TimeAgo
  //                                             date={reply.snippet.publishedAt}
  //                                           />
  //                                         </span>
  //                                       </div>
  //                                       <div className="comment-text">
  //                                         {reply.snippet.textOriginal}
  //                                       </div>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             ))}
  //                           </div>
  //                         </div>
  //                       </div>
  //                     )}
  //                   </div>
  //                 </div>
  //               ))}
  //             </div>
  //           )}
  //         </>
  //       ) : (
  //         <>
  //           <p className=" mt-20 text-center text-very-dark-blue">
  //             {errMsg}. Reload page
  //           </p>
  //         </>
  //       )}
  //     </>
  //   </div>
  // );
};

export default ClassComments;
