import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import axios from "axios";
export const searchAsyncThunk = createAsyncThunk("searchThunk", async (query) => {
  const response = await fetch(baseUrl + "/wiki/search?q=" + query)
  return await response.json();
})
export const fecthPageAsyncThunck = createAsyncThunk("fetchPageAsyncThunk", async (id, thunkAPI) => {
  const response = await axios.get(`${baseUrl}/wiki/${id}`)
  return response.data.wiki
})

const wikiSlice = createSlice({
  name: "wiki",
  initialState: {
    isFocus: false,
    searchText: '',
    searchSuggestionList: [],
    matchedSearchList: [],
    preventListClose: false,
    result: [],
    pageResult: null,
    loading: false

  },
  reducers: {
    searchWikiFocus: (state, { payload }) => {
      // WHEN THE SEARCH INPUT IS FOCUSED
      state.isFocus = payload;
    },
    searchInput: (state, { payload }) => {
      // RETURNS THE TEXT IN SEARCH INPUT
      state.searchText = payload
    },
    filterSearchList: (state, { payload }) => {
      // FILTERS ITEMS THAT MATCH THE SEARCH TEXT INTO A LIST
      state.searchText === "" ?
        state.matchedSearchList = state.searchSuggestionList
        :
        state.matchedSearchList = state.searchSuggestionList.filter((item) =>
          item.toLowerCase().indexOf(state.searchText.toLowerCase()) !== -1
        )
    },
    displayList: (state, { payload }) => {
      // TO PREVENT LIST CLOSE WHEN SEARCH FORM IS BLUR
      state.preventListClose = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(searchAsyncThunk.fulfilled, (state, action) => {
      state.result = action.payload["wiki"]
    })
    builder.addCase(fecthPageAsyncThunck.fulfilled, (state, action) => {
      state.pageResult = action.payload
      state.loading = false
    })
    builder.addCase(fecthPageAsyncThunck.pending, (state, action) => {
      state.loading = true
    })
  }
})

export const { searchWikiFocus, searchInput, filterSearchList, displayList } = wikiSlice.actions;
export default wikiSlice.reducer;