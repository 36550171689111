import React from "react";

const Aside = () => {
  return (
    <aside className="flex-1 mt-8 lg:mt-0">
      <div className="flex items-center justify-center lg:justify-start border-b border-gray">
        <div className="logo px-10 py-6 hidden sm:block">
          <svg
            width="120"
            height="150"
            viewBox="0 0 120 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M70.3525 119.164C70.3525 119.164 63.9333 119.899 62.5869 119.421C62.5869 119.421 64.1664 128.108 74.7278 129.933C74.7278 129.933 80.0045 131.183 81.6346 126.665C81.6346 126.665 78.4716 128.204 77.4147 123.301C77.4147 123.301 75.874 126.665 73.3853 126.471C73.3853 126.471 72.2351 126.086 72.4255 125.222C72.4255 125.222 71.3725 121.19 66.9564 122.149C66.9525 122.151 68.6253 119.837 70.3525 119.164Z"
              fill="#A4A4A4"
            />
            <path
              d="M104.432 90.8285L109.899 83.5818V79.27L112.664 74.9348L110.597 72.8596C110.597 72.8596 114.546 71.3543 114.919 70.7903L114.356 70.0395L115.485 67.4042L114.356 66.0856L120 63.071V58.7397L118.873 58.3604L118.308 55.729L114.166 54.033L116.425 52.1504L114.544 51.213L118.115 49.7076L118.871 46.5044L117.739 44.2425L114.544 44.8084L113.041 41.2337L114.166 40.2943L114.356 37.2758L114.733 35.2045L113.229 34.2632L111.722 35.5818L110.787 33.1371L112.475 32.3844V28.9945L113.229 28.2418L110.597 25.6065L109.843 26.5459L106.833 24.6632L104.385 26.1686L106.079 21.0865L103.455 16.7553L99.4971 16.3818L97.0589 12.2353L93.2917 11.6713L93.1032 10.1679L91.2264 4.89335L90.6591 9.03403L82.7595 6.02334L80.6923 6.39676V3.19935L75.9887 5.08201L70.1601 4.51604L69.2159 5.64603L66.0219 5.83468L69.4044 2.44473L68.0891 0L65.8295 0.939384L65.4546 1.69206L60.5683 1.13193L56.8069 3.57861L53.6051 1.31669L49.2784 1.5034L45.3383 7.72123L38.1847 6.96466L30.0967 12.9958L28.2179 13.3692L25.3969 15.0651L19.1914 15.8159L15.9896 19.5792L14.8647 26.7384L12.4167 27.4911L14.6763 28.9964L10.3496 33.3296L9.40921 34.8311L12.2283 35.9611L8.65539 35.7744L10.3476 38.5964L6.77277 39.7264L6.95928 42.739L0.374968 47.4457L5.45552 46.3176L4.13826 48.5757L5.07861 51.0243L1.88068 54.2236V58.1796L0.751881 59.8755L2.25564 63.8275L0.942277 66.2781L3.01336 69.2888L0 73.0522H5.45747L3.95369 75.6914L4.89403 77.1967L4.51907 82.2826L9.40921 79.834L7.14968 86.0441L8.84385 88.6794L9.59767 92.822L11.6707 95.6499L11.2879 100.17L17.4953 99.4191L17.8703 105.059L20.6972 104.688L22.3836 106.946L24.0758 107.319L24.6431 110.898L28.0256 109.768C28.0256 109.768 36.0126 111.575 35.0684 114.585L37.5145 123.283L36.4867 121.249L35.9038 114.585L30.8913 106.222L24.4527 102.797L17.6799 96.7818C17.6799 96.7818 8.65344 76.2554 9.21493 69.1001C9.77641 61.9429 21.4393 9.03403 44.9536 12.9958C68.4601 16.942 97.4241 9.03403 102.314 31.8204C107.208 54.599 109.283 43.4489 108.714 58.3215C108.72 58.3215 105.44 85.6415 104.432 90.8285Z"
              fill="#A4A4A4"
            />
            <path
              d="M13.1905 142.602C13.1905 142.602 23.3379 135.213 27.0313 135.213C27.0313 135.213 34.7833 124.685 35.5196 116.552L29.796 107.498L26.666 106.58L25.9258 103.994L25.1856 102.883L20.0196 102.331L18.5469 97.3382L13.377 96.9706L14.1114 93.46L11.1582 86.6237L9.86623 80.155L8.02247 81.2675L7.65916 76.6484L5.80568 71.2843L3.59861 72.7625L4.88672 68.8843L3.9658 61.8652L2.48535 58.3547L6.91116 51.709L6.17676 47.4555L3.2217 46.8973L8.02052 44.684L10.418 36.9258L10.6006 35.0801L15.9571 31.379L13.7403 30.0876L17.4336 26.9505L16.5088 21.9599L21.125 18.2626L27.9503 16.6037L37.919 9.39977H44.3848C44.3848 9.39977 49.3624 4.41111 50.837 4.22246L57.8526 5.14628L60.0694 4.22246L68.3712 3.4834L68.7384 6.80917L74.6505 7.36346L78.34 5.14628L79.0782 8.84353L81.8449 8.29118L87.9376 10.6912C87.9376 10.6912 88.4875 6.99393 89.2335 6.80917L91.0734 12.5408L97.1662 17.3427L101.413 18.4532L103.993 25.4704L108.794 26.3962L109.161 33.4173L111.374 36.3735L111.561 43.2079L114.327 44.3164L115.068 48.3813L113.406 53.1813L117.463 62.0558L111.374 63.7168L111.928 65.191L111.009 70.1835L109.35 72.5816L110.642 73.8788L108.056 76.6523L108.61 77.7608C108.61 77.7608 105.969 83.4419 105.969 84.5485C105.969 84.5485 101.549 114.722 94.0285 122.84C94.0285 122.84 102.513 104.919 103.661 83.7822C103.661 83.7822 111.724 52.4617 92.3693 38.2211C92.3693 38.2211 86.4611 33.7907 79.0821 35.8231L75.756 33.7907L74.837 35.6364L71.8839 34.8973L73.1759 37.1145C73.1759 37.1145 66.5294 31.3848 63.0226 36.743C63.0226 36.743 44.5655 36.5602 44.5655 59.0976C44.5655 59.0976 33.4952 81.2714 33.4952 82.3761C33.4952 82.3761 25.5567 74.2523 21.8672 75.5456C21.8672 75.5456 14.8535 82.0085 19.6524 89.5839C19.6524 89.5839 29.4346 108.615 36.9981 103.628C36.9981 103.628 39.347 116.25 43.7786 120.869C43.7786 120.869 54.2195 135.76 65.0859 139.509C65.0859 139.509 51.2644 136.816 40.7089 123.174C40.7089 123.174 32.9395 131.524 40.8741 150C40.8741 150 36.4463 145.2 31.6475 148.89C31.6456 148.884 29.4307 135.581 13.1905 142.602Z"
              fill="#414142"
            />
            <path
              d="M43.0254 74.542C43.0254 74.542 53.5751 67.1417 59.2229 68.7326C59.2229 68.7326 64.5133 70.1213 65.7334 71.4788C65.7334 71.4788 71.1384 74.1705 73.8059 72.6418C73.8059 72.6418 65.4245 63.1371 55.6986 65.4029C55.6986 65.401 49.7554 66.0117 43.0254 74.542Z"
              fill="#414142"
            />
            <path
              d="M90.1465 73.5073L90.333 76.4596L93.8437 74.4272C93.8437 74.4272 96.793 74.4272 99.748 73.1339C99.748 73.1339 103.995 72.21 106.391 74.4272C106.391 74.4272 102.147 69.6272 97.5351 69.8081L90.1465 73.5073Z"
              fill="#414142"
            />
            <path
              d="M85.7131 104.725C85.7131 104.725 86.3911 108.179 81.6836 108.373C81.6836 108.373 84.7533 109.624 88.3048 106.456L89.7484 106.259C89.7464 106.257 90.6071 105.489 85.7131 104.725Z"
              fill="#414142"
            />
            <path
              d="M69.3057 105.775C69.3057 105.775 74.5805 102.609 75.3499 107.127L73.5275 106.835C73.5275 106.835 71.9926 104.816 69.9779 106.162L69.3057 105.775Z"
              fill="#414142"
            />
            <path
              d="M62.584 119.421C62.584 119.421 69.3976 116.54 70.3573 115.002C70.3573 115.002 74.3887 110.2 79.1876 115.293C79.1876 115.293 82.6419 112.6 85.3289 115.772C85.3289 115.772 87.4388 118.654 85.1385 119.607C85.1385 119.607 80.7244 124.707 73.5242 120.385C73.5242 120.385 78.4201 120.864 78.902 120C78.902 120 78.0393 119.899 75.4456 118.557C75.4456 118.555 64.214 120.095 62.584 119.421Z"
              fill="#414142"
            />
            <path
              d="M21.9912 80.0303C21.9912 80.0303 27.3651 78.206 32.1601 84.7369C32.1601 84.7369 29.7704 90.9839 35.1443 92.3298C35.1443 92.3298 35.0394 98.6701 34.1768 99.9149C34.1768 99.9149 34.7518 92.7149 33.3102 92.9969C33.3102 92.9969 27.7926 91.4079 29.7607 85.6004C29.7607 85.6004 28.3249 82.7201 27.4584 83.1032C27.4584 83.1032 21.7056 86.6487 25.8342 91.8397C25.8342 91.8397 26.2402 92.9638 28.0354 93.769C28.0354 93.769 17.2876 90.8886 26.1178 82.0471"
              fill="#414142"
            />
            <path
              d="M46.7441 78.1087C46.7441 78.1087 52.7981 75.5162 53.0856 75.5162C53.0856 75.5162 63.7344 74.1684 67.7658 81.7573C67.7658 81.7573 66.5204 82.2397 64.4124 81.9538C64.4124 81.9538 67.2917 79.7385 60.6647 77.6264C60.6647 77.6264 60.857 78.875 60.379 78.9723C60.379 78.9723 60.1595 77.9862 58.8714 78.0757C58.8714 78.0757 57.3579 78.4821 57.9311 80.0614C57.9311 80.0614 58.6286 81.2633 59.8312 80.5768L59.3222 81.3742C59.3222 81.3742 56.1534 82.5236 54.331 80.3162C54.331 80.3162 52.9885 78.875 54.7118 77.533C54.7118 77.533 51.5469 78.206 50.8805 79.7385C50.8785 79.7405 46.9384 78.5891 46.7441 78.1087Z"
              fill="#414142"
            />
            <path
              d="M95.8003 83.3796C95.8003 83.3796 95.3068 83.8755 95.1203 83.9689L90.708 83.8755C90.708 83.8755 90.2282 82.8194 90.708 82.7222C90.708 82.7222 88.7866 83.4904 88.501 83.8755C88.501 83.8755 94.1605 76.0006 101.067 79.6473C101.067 79.6473 102.221 79.6473 102.991 78.9744C102.991 78.9744 102.128 80.0324 101.547 80.1258C101.547 80.1258 103.088 80.5108 104.335 79.6473C104.335 79.6473 102.991 81.0904 102.027 80.9893C102.027 80.9893 102.606 81.9559 102.317 82.7202C102.317 82.7202 100.972 79.836 96.7464 79.836C96.7464 79.836 96.9193 80.7792 96.4939 81.5494C96.4939 81.5494 95.7264 80.1899 94.4325 81.0749C93.2027 81.917 94.4325 81.0749 94.4325 81.0749C94.4325 81.0749 93.3698 81.9034 94.312 83.0839C95.2602 84.2625 94.312 83.0839 94.312 83.0839C94.312 83.0839 94.7958 83.5896 95.5341 83.4593"
              fill="#414142"
            />
          </svg>
        </div>
        <div className="">
          <h2 className="text-xl font-bold text-center lg:text-start">Maisha Digital</h2>
          <div className="social flex justify-between items-center mt-8 mb-5 w-48 mx-auto lg:ml-0">
            <a href="#" aria-label="facebook">
              <i className="text-2xl hover:text-strong-red fab fa-facebook"></i>
            </a>
            <a href="#" aria-label="twitter">
              <i className="text-2xl hover:text-strong-red fab fa-twitter"></i>
            </a>
            <a href="#" aria-label="instagram">
              <i className="text-2xl hover:text-strong-red fab fa-instagram"></i>
            </a>
            <a href="#" aria-label="youtube">
              <i className="text-2xl hover:text-strong-red fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="mx-10 mt-8">
        <p className="mb-5">Related content</p>
        <div className="">
          <div className="lg:w-80 h-44 bg-gray rounded-md"></div>
        </div>
      </div>
    </aside>
  );
};

export default Aside;
