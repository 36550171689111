import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";

export const fetchSingleMasterClass = createAsyncThunk("fecthSingle/masterClass", async ({ id }, thunkAPI) => {
    try {
        const response = await axios.get(baseUrl + "/media/master-class/" + id,
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }
        )
        return thunkAPI.fulfillWithValue(response.data.media)
    } catch (err) {
        return thunkAPI.rejectWithValue("An error occured")
    }

})
export const enrollForCourse = createAsyncThunk("enrollForCourse/Async", async (args, thunkAPI) => {

    try {
        const response = await axios.post(baseUrl + "/media/master-class/enroll", {
            courseID: args.courseID
        },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            }
        )
        console.log(response.data)
        return thunkAPI.fulfillWithValue("Sucess")
    } catch (err) {
        console.log(err)
    }

})
export const otherCourseByCreator = createAsyncThunk("otherCourseByCreator", async (args, thunkAPI) => {
    try {
        const response = await axios.get(baseUrl + "/media/master-class/other-courses-by-creator/" + args.id)
        return thunkAPI.fulfillWithValue(response.data.medias)
    } catch (err) {

    }
})
export const fetchCommentAsync = createAsyncThunk("fetchCommentAsync", async (args, thunkAPI) => {
    try {
        console.log(args.id)
        const response = await axios.get(baseUrl + "/media/master-class/comments/" + args.id)

        return thunkAPI.fulfillWithValue(response.data.comments)
    } catch (err) {
        console.log("Error")
        console.log(err)
    }


})

const masterClassSlice = createSlice({
    name: "masterClass/Slice",
    initialState: {
        masterClass: null,
        loading: false,
        error: false,
        errorMsg: "",
        otherCourseLoading: true,
        otherCourseSlice: [],
        isEnrolled: false,
        comments: [],
        commentsLoading: true
    },

    extraReducers: builder => {
        builder.addCase(fetchSingleMasterClass.fulfilled, (state, action) => {
            state.masterClass = action.payload
            state.loading = false
            state.error = false
        })
            .addCase(fetchSingleMasterClass.pending, (state, action) => {
                state.loading = true;
                state.error = false
            })
            .addCase(fetchSingleMasterClass.rejected, (state, action) => {
                state.error = true
                state.loading = false;
                state.errorMsg = action.payload
            })
            .addCase(otherCourseByCreator.fulfilled, (state, action) => {
                state.otherCourseSlice = action.payload
                state.otherCourseLoading = false
            })
            .addCase(enrollForCourse.fulfilled, (state, action) => {
                state.isEnrolled = true;
            })
            .addCase(fetchCommentAsync.fulfilled, (state, action) => {
                state.comments = action.payload
            })
    }
})
export default masterClassSlice.reducer