import React from "react";
import { useSelector } from "react-redux";
import TimeAgo from "react-timeago"

const UpdatesTab = ({ update }) => {
  const state = useSelector(state => state.protection)
  return (
    <>
      <div className="flex lg:items-center max-w-4xl mb-1">
        <div className="rounded-full border border-dark-gray w-12 h-12 overflow-hidden">
          <img
            src={state.user.imageUrl}
            alt="maisha digital youtube logo"
            className="w-full h-full rounded-full"
          />
        </div>
        <form className="w-full pl-5 lg:pl-0 flex items-center justify-between flex-col lg:flex-row">
          <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
            <input
              id="update"
              name="update"
              type="text"
              className="h-11 p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
              placeholder="Add update"
            />
            <label htmlFor="update" className="sr-only">
              Add update
            </label>
          </div>
          <button
            // type="submit"
            className="h-11 w-full max-w-[10rem] ml-auto flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
          >
            Update
          </button>
        </form>
      </div>
      {update.map((e, index) => <div key={index} className="border-l border-strong-red pl-8 mt-8">
        <h2 className="font-bold">
          <TimeAgo date={e.updateTime} />
          {" "}
          <span className="font-normal">by {e.channel.channelName}</span>
        </h2>
        <p className="mt-2">
          {e.content}
        </p>
      </div>)}
    </>
  );
};

export default UpdatesTab;
