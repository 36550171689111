import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSuggestedVideos } from "../../../redux/reducers/mediaSlice";
import ReactTimeago from "react-timeago";

const SuggestedVids = () => {
  const dispatch = useDispatch();
  const { suggested } = useSelector((state) => state.media);

  useEffect(() => {
    dispatch(getSuggestedVideos())
    // eslint-disable-next-line
  }, []);

  const truncate = (title) => {
    if (title.length > 30) {
      return `${title.slice(0, 30)}...`;
    } else {
      return title;
    }
  };

  return (
    <div className="flex-1 mb-10">
      {suggested && suggested.map((video, index) => (
        <>
          {/* DISPLAY ONLY VIDEOS WHICH INDEX IS IN THE RANDOMINDEXES ARRAY */}
          {(
            <Link
              to={
                video._id ? `/media/${video._id}` : "/media"
              }
              aria-hidden="true"
              aria-label="video link"
            // onClick={() => dispatch(suggestedVideos())}
            >
              <div
                key={video._id}
                className="post mb-4 relative group flex justify-between h-[150px] bg-contain "
              >
                <figure className="flex flex-col w-6/12 overflow-hidden">
                  <img
                    src={video.videoThumbnailUrl}
                    alt="media thumbnail"
                    className=" object-cover rounded-lg mb-4 bg-gray "
                  />
                </figure>
                <div className="flex flex-col flex-1 ml-2">
                  <div className="flex justify-between items-start text-sm w-full h-max-content">

                    <h3 className="lg:text-lg font-bold group-hover:text-gray mb-4">
                      {truncate(
                        video.titleOfContent
                          .replace(/&quot;/g, '"')
                          .replace(/&#39;/g, "'")
                      )}
                    </h3>

                  </div>
                  <div className="text-xs lg:text-base">
                    by{" "}
                    <span className="text-strong-red font-bold">
                      {" "}
                      {/* {video.snippet.channelTitle} */}
                      {video.channelId.channelName}
                    </span>
                    <span className="block">
                      <ReactTimeago date={video.postDate} /></span>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </>
      ))}
    </div>

  );
};

export default SuggestedVids;
