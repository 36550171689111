import React, { useEffect } from "react";
import logo from "../../assets/logo/maisha-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getInstagramDetails } from "../../redux/reducers/sharedSlice";
import { useLocation } from "react-router-dom";

const OnInstagram = () => {
  const dispatch = useDispatch();

  const { loading, error, errMsg, instagramDetails } = useSelector(
    (state) => state.shared
  );
  const location = useLocation()

  useEffect(() => {
    dispatch(getInstagramDetails(location.pathname == "/" ? "maisha-digital" : "maisha-arts"));
    // eslint-disable-next-line
  }, []);


  return (
    <section className="bg-dark-gray text-white py-10">
      <div className=" max-w-xl mx-auto lg:max-w-7xl px-5">
        <h2 className="text-lg text-center mb-8 lg:mb-2">
          Maisha Digital on Instagram
        </h2>
        <div className="text-center lg:text-left w-full lg:w-auto mb-6 lg:mb-0 max-md lg:max-w-xs xl:max-w-sm">
          <a
            href="/"
            className="logo flex justify-start flex-row items-center mb-8 lg:mb-6"
          >
            <div className="bg-white w-20 h-20 rounded-full">
              <img src={logo} alt="maisha logo" className="mx-auto px-4 py-2.5" />
            </div>
            <span className="text-xl font-medium ml-4">Maisha Digital</span>
          </a>
        </div>
        <>
          {!error ? (
            <>
              {loading ? (
                <div className="items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch gap-6">
                  {Array(4)
                    .fill()
                    .map((item, index) => (
                      <div
                        key={index}
                        role="status"
                        className="post xl:max-w-[18rem] relative group flex flex-col justify-between animate-pulse"
                      >
                        <div className="rounded-lg mb-4 bg-gray min-h-[198px] lg:min-h-[216px] w-full"></div>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch gap-6">
                  {/* DISPLAY ONLY INSTAGRAM IMAGES OF THIS PAGE */}
                  {instagramDetails
                    .filter((media) => media.media_type === "IMAGE")
                    .slice(0, 4)
                    .map((media, index) => (
                      <div className="flex flex-col overflow-hidden mb-8 lg:mb-0">
                        <a
                          href={media.permalink}
                          target="_blank"
                          rel="noreferrer"
                          className="img-wrapper h-full hover:scale-90 transition-all"
                        >
                          <img
                            src={media.media_url}
                            alt="instagram post"
                            className="object-fill h-full rounded-lg "
                          />
                        </a>
                      </div>
                    ))}
                </div>
              )}
            </>
          ) : (
            <p className=" mt-20 text-center text-very-dark-blue min-h-[20vh]">
              {errMsg}. Reload page
            </p>
          )}

          {/* <div className="flex flex-col overflow-hidden mb-8 lg:mb-0">
            <div className="img-wrapper mb-4">
              <img
                src="https://s3-alpha-sig.figma.com/img/7cf8/2ec1/c23c2f4c5eeacdaf00f7790620913617?Expires=1665964800&Signature=Jf71mFOM69B1Gtk2tppvKUwvCwvClCnQ6LOeSuIkDf9ljjrIg3LLQU9IIQMPcpHemHjsdEzSBpAlFrgU40SAjCUrSdhkNWjQc-mjh~7KT0h0epgyJjCp8VBIpoSp65zl9BICooI5JNSPkt2kk6d754Ym1-0oEXYwqx8~rX4ilbAs2xyQVBSbcCFZHjjvtjImlDyqzv5ktKG4OGBY0LbPyGycxVN2nRnp5J5Fop~aPiEpOL2xxLxRkf3Zg0cguX5uEkvOiEgCBHwEWr5K7Y8jG21z24gYwDxUaKmSxkFT2vHT2ewe-PMatPf5qqTNX0aC769I309OWc9ESR0FMCF1iA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                alt="instagram post"
                className="object-cover rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-col overflow-hidden mb-8 lg:mb-0">
            <div className="img-wrapper mb-4">
              <img
                src="https://s3-alpha-sig.figma.com/img/feba/c95d/c06f5e83ad657966388534f6ea910f0e?Expires=1665964800&Signature=Xq0SaUg0x1sBBPD497KVBkMX5ahSz6MY3Ali6TDNXfVNCdctqGcI0U1vuH5dpTOjXK14~Q20MsoNS6TaABst4j-0gCUTCooktQ5Sorxwy-X-rsktyjBVWbhyPxmhOxYMwGpkI-lGIVSMLVy7cjHIFphc7PSqjUJ~GurRuFDc6Qe0izw5FeQO0PKXuJj5K37ChZ37nKOwF6RR0CvJAISUqSq~m6aFGGXq40gePjN7sMfCe3u3opC00AJO5tPVE8hmMmgol8dK-~9qxpwBLCrATnad1BhFGOOkkhvY~qFzVdbT9FCMzQMyAGk-u~Ou-YfVhA3sjpj2eu2YTa3YS8KQdQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                alt="instagram post"
                className="object-cover rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-col overflow-hidden mb-8 lg:mb-0">
            <div className="img-wrapper mb-4">
              <img
                src="https://s3-alpha-sig.figma.com/img/68f1/eca7/c5d35245d2ae36536a0e236c168007d6?Expires=1665964800&Signature=gQz81qCgKyhFKIfS5J8xr6qRfNVce221t5qL3V9UHKvOnEeLt~rvHxAOk5l-VDaY9gtS0h47i77ASgdRML0pW7TUik9pGnk1QBa5miZ8G~ldzesYrsvzNbEIl4677xcSfXZRYCA2I9~cC9Oee44dRwtZMVdCDjSmW4s8SDYSyk1SezPvsG1zyoRk3GsK~0CzGFngK4H7TeIs~MPs4sJ1fv6TmSJCWX9rjcZzKWpuXj9ZYQ5iaTE7~bXutwwRUgy6FhLCOwj3tMOLjzZ9jGrjWhvMbWpzI81WS2vvBSC7DpG751c8mRbn6Xjj9F-i1gSHn28MCstDFiPZBNpDP0DWIQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                alt="instagram post"
                className="object-cover rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-col overflow-hidden mb-8 lg:mb-0">
            <div className="img-wrapper mb-4 relative">
              <img
                src="https://s3-alpha-sig.figma.com/img/f6a0/99b1/9b4e5b2e65859e6836d219aac84250e8?Expires=1665964800&Signature=DEjQe6q24pzV83OGvACV5KEngVLLJ2pAavcX1C6rrxwlfUuUTL2ekHKLb2EJj-Xf~HdWUletNbzsBSR2DszVPcG6pyMTUKmK53fkClOXStnEZQeJKHQDvYVthwiLJFZq8czOTcUqlj7JTok1dloo5oxYksN8Hw0d6rmIJj8-VCiJGyCdtfT1d-FIwTFonRUeFWu40S22mbjwizesu0M~18QDfF9s4tT61MDH9dE776tFNs4e4jEOLkcIKhB0qKth-K-Yl-by6hHHNaFQZXmdRdslFRjqhR4P0pAsLSe2nag7Bo1o60Qcb~T~4RVG9O7yGUvpsjp59b1IvgAhk8ZNAg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                alt="instagram post"
                className="object-cover rounded-lg"
              />
              <i className="text-white absolute inset-0 flex items-center justify-center">
                <ion-icon name="play"></ion-icon>
              </i>
            </div>
          </div> */}
        </>
        <div className="mt-6 w-fit mx-auto">
          <a
            href="https://www.instagram.com/maishadigital/"
            target="_blank"
            rel="noreferrer"
            className="bg-blue-500 text-white flex items-center justify-center rounded-md py-4 px-6 text-white transition-all"
          >
            <i className="text-2xl fab fa-instagram mr-2"></i>
            Follow on Instagram
          </a>
        </div>
      </div>
    </section>
  );
};

export default OnInstagram;
