import React, { useState } from "react";
import wiki2 from "../../../assets/wiki/wiki-post/wiki-2.png";
import wiki3 from "../../../assets/wiki/wiki-post/wiki-3.png";

const Aside = ({ aside }) => {
  const [toggleCollapse, setToggleCollapse] = useState(false);
  const sideInfo = aside.aside;
  const articleInfo = aside.article
  return (
    <div className="basis-2/5">
      <h2 className="lg:hidden text-2xl sm:text-3xl lg:text-4xl font-bold text-strong-red mb-4">
        {sideInfo.name}
      </h2>
      {/* <time dateTime="13-09-2022" className="lg:hidden mb-6">
        Sunday 13-09-2022
      </time> */}
      <aside className="w-full flex-1 rounded-lg bg-very-light-gray shadow-md lg:ml-8 relative order-1 lg:order-2">
        <img
          src={sideInfo.profileImg}
          alt={sideInfo.profileImg}
          className="absolute top-40 inset-x-0 w-full -z-10"
        />
        <div className="">
          <div className="show  pt-12" id="collapseExample">
            <div className="mx-auto flex items-center justify-center mb-6">
              <img src={sideInfo.profileImg} alt={sideInfo.profileImg} className="w-2/4" />
            </div>
            <p className="text-center font-bold text-lg w-full mb-9">Summary</p>
            <div className="">
              <h4 className="w-full py-2 bg-gray text-white text-base font-bold text-center">
                Biography
              </h4>
              <dl className="info px-4 pt-4">
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Name:</dt>
                  <dd className="flex-1">{sideInfo.name}</dd>
                </div>
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Birth:</dt>
                  <dd className="flex-1">{sideInfo.birth}</dd>
                </div>
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Profession:</dt>
                  <dd className="flex-1">
                    {sideInfo.profession}
                    {/* <a
                      href="/maisha-arts"
                      className="text-strong-red font-medium"
                    >
                      Maisha Arts
                    </a> */}
                  </dd>
                </div>
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Parents:</dt>
                  <dd className="flex-1">
                    {sideInfo.parents.map((e, index) => <a href="/" key={index} className="text-strong-red font-medium block">
                      {e}
                    </a>)}
                  </dd>
                </div>
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Siblings:</dt>
                  <dd className="flex-1">
                    {sideInfo.siblings.map((e, index) => <a href="/" key={index} className="text-strong-red font-medium block">
                      {e}
                    </a>)}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="">
              <h4 className="w-full py-2 bg-gray text-white text-base font-bold text-center">
                Seen in
              </h4>
              <dl className="info px-4 pt-4">
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Maisha Books:</dt>
                  <ul className="flex-1">
                    {sideInfo.maishaBooks.map((e, index) => <li className="" key={index}>
                      <a
                        href="/maisha-arts"
                        className="text-strong-red font-medium block mb-1"
                      >
                        {e}
                      </a>
                    </li>)}


                  </ul>
                </div>
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Maisha Arts:</dt>
                  <dd className="flex-1">
                    {sideInfo.maishaArts.map((e, index) => <a href="/" className="text-strong-red font-medium block" key={index}>
                      {e}
                    </a>)}
                  </dd>
                </div>
                <div className="flex mb-4">
                  <dt className="font-bold w-5/12">Maisha Africa:</dt>
                  <dd className="flex-1">
                    {sideInfo.maishaAfrica.map((e, index) => <a href="/" className="text-strong-red font-medium block" key={index}>
                      {e}
                    </a>)}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="">
              <h4 className="w-full py-2 bg-gray text-white text-base font-bold text-center">
                Content
              </h4>

              <div className="toc px-4 pt-4">
                {articleInfo.map((e, index) => <> <a href={`#${e._id}`} className="text-strong-red font-medium block mb-2">
                  {`${index + 1} ${e.sectionTitle}`}
                </a>
                  <div className="ml-3">
                    {e.subSections.map((subE, subIndex) => <a
                      href={`#${subE._id}`}
                      className="text-strong-red font-medium block mb-2"
                    >
                      {`${index + 1}.${subIndex + 1} ${subE.subSectionTitle}`}
                    </a>)}

                  </div>
                </>
                )}

              </div>
            </div>
          </div>
          <button
            className="flex flex-col my-4 w-full items-center font-bold focus:outline-none outline-none transition duration-150 ease-in-out"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => setToggleCollapse(!toggleCollapse)}
          >
            <span className={toggleCollapse ? "hidden" : null}>
              {" "}
              External links
            </span>
            <div className={"px-4 w-full flex items-center justify-center" + (toggleCollapse ? "" : " hidden")}>
              {/* <img src={wiki2} alt="nana aba rabbit lady" className="w-16 h-16" /> */}

              {aside.externalLinks.map((e, index) => <a target="_blank" href={`${e.link}`} className="ml-8 text-xl font-bold">
                <h2 className="ml-8 text-sm font-bold" >{e.name}</h2>
              </a>)}


            </div>

            <span className="mt-2">
              <svg
                width="30"
                height="10"
                viewBox="0 0 30 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8.5L15 1.5L29 8.5"
                  stroke="#130F26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </aside>
    </div>
  );
};

export default Aside;
