import React from "react";
import AboutCampaignTab from "./AboutCampaignTab";
import CommentsTab from "./CommentsTab";
import SupportersTab from "./SupportersTab";
import UpdatesTab from "./UpdatesTab";

const PostTabs = ({ crowdFund }) => {
  return (
    <div className="mt-16 mb-8 flex flex-col items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto text-dark-gray">
      <ul
        className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
        id="tabs-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-about-campaign"
            className=" nav-link block font-bold !text-gray lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent active"
            id="tabs-about-campaign-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-about-campaign"
            role="tab"
            aria-controls="tabs-about-campaign"
            aria-selected="true"
          >
            About Campaign
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-updates"
            className=" nav-link block font-bold !text-gray lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-updates-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-updates"
            role="tab"
            aria-controls="tabs-updates"
            aria-selected="false"
          >
            Updates
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-supporters"
            className=" nav-link block font-bold !text-gray lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-supporters-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-supporters"
            role="tab"
            aria-controls="tabs-supporters"
            aria-selected="false"
          >
            Supporters
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-comments"
            className=" nav-link block font-bold !text-gray lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-comments-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-comments"
            role="tab"
            aria-controls="tabs-comments"
            aria-selected="false"
          >
            Comments
          </a>
        </li>
      </ul>
      <div className="tab-content w-full" id="tabs-tabContent">
        <div
          className="tab-pane fade min-h-[30vh] show active"
          id="tabs-about-campaign"
          role="tabpanel"
          aria-labelledby="tabs-about-campaign-tab"
        >
          <AboutCampaignTab aboutCampaign={crowdFund.aboutCampaign} />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-updates"
          role="tabpanel"
          aria-labelledby="tabs-updates-tab"
        >
          <UpdatesTab update={crowdFund.update} />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-supporters"
          role="tabpanel"
          aria-labelledby="tabs-supporters-tab"
        >
          <SupportersTab contributors={crowdFund.contributor} />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-comments"
          role="tabpanel"
          aria-labelledby="tabs-comments-tab"
        >
          <CommentsTab comments={crowdFund.comments} />
        </div>
      </div>
    </div>
  );
};

export default PostTabs;
