import React from "react";
import FreeMediaTab from "./FreeMediaTab";
import SubscriptionsTab from "./SubscriptionsTab";

const ChannelsTabs = () => {

  return (
    <section>
      <h2 className="sr-only">Channel Tabs</h2>
      {/* TABS NAVIGATION */}
      <ul
        className="nav nav-tabs sticky top-0 bg-white z-10 md:w-full ioigroup flex items-start lg:items-center flex-wrap list-none border-b-0 pl-0 mb-4 p-4 lg:p-0"
        id="tabs-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-media"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent active"
            id="tabs-media-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-media"
            role="tab"
            aria-controls="tabs-media"
            aria-selected="true"
          >
            Free Media
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-subs"
            className=" nav-link block font-bold !text-gray flex items-center lg:text-2xl border-x-0 border-t-0 border-b-2 border-transparent px-2 lg:px-6 py-3 my-2 hover:border-transparent focus:border-transparent"
            id="tabs-subs-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-subs"
            role="tab"
            aria-controls="tabs-subs"
            aria-selected="true"
          >
            Subscriptions
          </a>
        </li>
      </ul>

      {/* TABS CONTENT */}
      <div
        className="tab-content relative"
        id="tabs-tabContent"
      >
        <div
          className="tab-pane fade min-h-[30vh] show active"
          id="tabs-media"
          role="tabpanel"
          aria-labelledby="tabs-media-tab"
        >
          <FreeMediaTab />
        </div>
        <div
          className="tab-pane fade min-h-[30vh]"
          id="tabs-subs"
          role="tabpanel"
          aria-labelledby="tabs-subs-tab"
        >
          <SubscriptionsTab />
        </div>
      </div>
    </section>
  );
};

export default ChannelsTabs;
