import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchWikiFocus,
  searchInput,
  filterSearchList,
  displayList,
} from "../../redux/reducers/wikiSlice";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, createSearchParams } from "react-router-dom";

const Search = () => {
  const dispatch = useDispatch();
  const { searchText, matchedSearchList, isFocus, preventListClose } =
    useSelector((state) => state.wiki);
  const { register, getValues, setValue, handleSubmit } = useForm({
    mode: "onChange",
  });

  const navigate = useNavigate();

  const submitForm = (data) => {
    navigate({
      pathname: "search",
      search: createSearchParams({
        q: `${data.search.toLowerCase().replaceAll(" ", "%20")}`,
      }).toString(),
    });
  };

  useEffect(() => {
    // MAKES MATCHED SEARCH LIST SAME AS SEARCH SUGGESTION LIST WHEN COMPONENT MOUNTS
    dispatch(filterSearchList());

    // eslint-disable-next-line
  }, [searchText]);

  return (
    <section className="px-5 lg:px-8 xl:px-5 mx-auto w-full max-w-xl lg:max-w-7xl w-full flex flex-row mt-8">
      <h2 className="sr-only">Search</h2>
      <AnimatePresence>
        <motion.div
          layout
          transition={{ type: "tween" }}
          className="relative w-full flex flex-col max-w-2xl mx-auto border border-gray rounded-md transition-all"
        >
          <form
            onSubmit={handleSubmit(submitForm)}
            onChange={() => {
              const value = getValues("search");
              dispatch(searchInput(value));
            }}
            className="flex items-center w-full relative"
          >
            <input
              type="text"
              name="search"
              id="search"
              autoComplete="off"
              value={searchText}
              className="focus:outline-none w-full pl-6 h-14 rounded-md"
              placeholder="Enter key words"
              {...register("search", { required: true })}
              onFocus={() => {
                dispatch(searchWikiFocus(true));
              }}
              onBlur={() => {
                // ENSURE THE SEARCH SUGGESTION LINKS ARE NOT CLOSED WHEN CLICKED
                !preventListClose && dispatch(searchWikiFocus(false));
              }}
            />
            <button type="submit" className="mr-3 pt-1">
              <ion-icon
                name="search"
                role="img"
                className="md hydrated text-xl"
                aria-label="search"
              ></ion-icon>
              <span className="sr-only">search</span>
            </button>
          </form>
          <AnimatePresence>
            {isFocus && (
              <ul
                className="border-t border-gray"
                // ALLOWS LIST ITEM TO BE CLICKED WHEN USER IS IN THE LIST
                onMouseEnter={() => dispatch(displayList(true))}
                onMouseLeave={() => dispatch(displayList(false))}
              >
                {matchedSearchList.map((item, index) => (
                  <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={index}
                    className="w-full p-4 hover:bg-very-light-gray transition-all flex cursor-pointer"
                    onClick={() => {
                      setValue("search", item);
                      dispatch(searchInput(item));
                    }}
                  >
                    <ion-icon
                      name="search"
                      role="img"
                      className="md hydrated text-xl"
                      aria-label="search"
                    ></ion-icon>
                    <span className=" ml-3">{item}</span>
                  </motion.li>
                ))}
              </ul>
            )}
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export default Search;
