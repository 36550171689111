import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../config";


const fecthForumList = createAsyncThunk("fecthForumList", async () => {
    const response = await axios.get(baseUrl + "/forum")
    return response.data.forums
})


const forumListSlice = createSlice({
    name: "forum/ForumList",
    initialState: {
        loading: false,
        forums: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fecthForumList.fulfilled, (state, action) => {
            state.forums = action.payload
            state.loading = false
        });
        builder.addCase(fecthForumList.pending, (state, action) => {
            state.loading = true;
        })
    }
})
export default forumListSlice.reducer;
export { fecthForumList };
