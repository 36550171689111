import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import axios from "axios";

export const formUploadSubmitter = createAsyncThunk("formUploader/submit", async (payload, thunkAPI) => {
  const formData = new FormData();
  formData.append("channelID", "6389c559ec73cbb1d8471397")
  switch (payload["contentType"]) {
    case "video":
      for (let key in payload) {
        await formData.append(key, payload[key])
      }
      await uploadFunction(formData, "video")
      break;
    case "image":
      for (let key in payload) {
        formData.append(key, payload[key])
      }
      await uploadFunction(formData, "image")
      break;
    case "crowdfunding":
      for (let key in payload) {
        formData.append(key, payload[key])
      }
      await uploadFunction(formData, "crowdfunding")
      break;
    case "master-class":
      for (let key in payload) {
        if (key === "files") {
          payload[key].forEach((value) => {
            formData.append("module_name", value.name)
            formData.append("module_file", value.file)
          })
        }
        formData.append(key, payload[key])
      }
      await uploadFunction(formData, "master-class")
      break;
    case "carousel":
      for (let key in payload) {
        formData.append(key, payload[key])
      }
      await uploadFunction(formData, "carousel")
      break;

    default:
      throw new Error("Invalid content type")
  }
})

async function uploadFunction(formData, contentType) {
  try {
    const response = await axios.post(baseUrl + `/media/create/${contentType}`, formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        ContentType: "multipart/form-data"
      },

    })
    const json = await response.data

    return json.media;
    ;
  } catch (err) {
    throw new Error("An Error Occured")
  }


}


const aboutSlice = createSlice({
  name: "about",
  initialState: {
    contentType: "video",
    videoUpload: "storage",
    submitting: false,
    completed: false,
    hasError: false,
    error: "An Error Occured"
  },
  reducers: {

    resetSlice(state, action) {
      state.submitting = false;
      state.completed = false;
      state.hasError = false;
    },
    setContentType: (state, { payload }) => {
      state.contentType = payload
      state.videoUpload = "storage"
    },
    setVideoUpload: (state, { payload }) => {
      state.videoUpload = payload
    }

  }, extraReducers: builder => {
    builder.addCase(formUploadSubmitter.pending, (state) => {
      state.submitting = true
    })
    builder.addCase(formUploadSubmitter.rejected, (state, action) => {
      state.hasError = true;
      state.submitting = false;
      state.completed = true;
    })
    builder.addCase(formUploadSubmitter.fulfilled, (state, action) => {
      state.hasError = false;
      state.submitting = false;
      state.completed = true;
    })
  }
})

export const { setContentType, setVideoUpload, resetSlice } = aboutSlice.actions;
export default aboutSlice.reducer;