import React from "react";
import wiki1 from "../../../assets/wiki/wiki-post/wiki-1.png";

const Article = ({ article }) => {

  const date = new Date(article.editDate)
  console.log(article.title)
  return (
    <div className="basis-3/5">
      <article className="lg:w-10/12 order-2 lg:order-1 relative bg-white">
        <h1 className="lg:block text-2xl sm:text-3xl lg:text-4xl font-bold text-strong-red mb-4">
          {article.title}
        </h1>
        <p className="lg:block ">
          {date.toLocaleDateString('en-US', { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric' })}
        </p>
        {article.article.map((section, index) => <section id={`#${section._id}`} className="mt-10 lg:mt-4" >
          <h2 className="text-xl lg:text-2xl font-bold mb-4">
            <span className="mr-2">{index + 1}</span>
            {section.sectionTitle}
          </h2>
          <p className="mb-4">
            <span className="font-bold">{section.sectionContent.split(" ").splice(0, 3).join(" ") + " "}</span>
            {section.sectionContent.split(" ").splice(3).join(" ")}
          </p>
          {section.sectionImgUrl && <img src={section.sectionImgUrl} alt={section.sectionTitle} className="" />}
          {section.subSections.map((subSection, subIndex) => <div id={`#${subSection._id}`} className="mt-2 ml-4 sm:ml-6 lg:ml-12">


            <h3 className="text-lg lg:text-xl font-bold mb-4">
              <span className="mr-2">{`${index + 1}` + "." + `${subIndex + 1}`}</span>
              {subSection.subSectionTitle}
            </h3>
            <div className="flex items-center justify-between flex-col lg:flex-row">
              {/* Image added here */}
              {subSection.subsectionImgUrl && <img src={subSection.subsectionImgUrl} alt={subSection.subSectionTitle} className="w-64 mr-10" />}
              <div className="w-full">
                <p className="mb-4">
                  {subSection.subSectionContent}
                </p>

              </div>
            </div>
          </div>)}

        </section>)}

      </article>
    </div>
  );
};

export default Article;
