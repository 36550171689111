import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setContentType } from "../../../../redux/reducers/AboutSlice";
import Crowdfunding from "./MediaContentType/Crowdfunding";
import Image from "./MediaContentType/Image";
import MasterClass from "./MediaContentType/MasterClass";
import Video from "./MediaContentType/Video";
import { resetSlice } from "../../../../redux/reducers/AboutSlice";
import { formUploadSubmitter } from "../../../../redux/reducers/AboutSlice";
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from "react-router-dom"
import Carousel from "./MediaContentType/Carousel";




const MediaForm = () => {
  const { contentType, submitting, completed, hasError } = useSelector((state) => state.about);
  const { user } = useSelector((state) => state.protection);
  const dispatch = useDispatch();
  const formData = useRef();
  const thumbnailRef = useRef(null);
  const videoFileRef = useRef(null);
  const smallResolutionRef = useRef(null);
  const mediumResolutionRef = useRef(null);
  const largeResolutionRef = useRef(null);
  const HDResolutionRef = useRef(null);
  const crowdFundingRef = useRef(null);
  const catOneVidRef = useRef(null);
  const catOneImgRef = useRef(null);
  const catTwoImgRef = useRef(null);
  const catThreeImgRef = useRef(null);

  const navigate = useNavigate();
  const [fields, setFields] = useState([])
  const action = () => {
    dispatch(resetSlice())
    navigate("/maisha-arts/profile");

  }
  return (
    <section className="media border border-gray rounded-md flex-1 mb-12 lg:mb-0 w-full">
      <h2 className="bg-gray text-white font-bold p-4">Media details</h2>
      {completed && !hasError && !submitting && <Snackbar
        open={completed && !hasError && !submitting}
        autoHideDuration={3000}

        onClose={action}
        message="Sucess"
        action={action}
      />}
      <form className="p-4" ref={formData} encType="multipart/form-data" method="post" onSubmit={
        (e) => {
          e.preventDefault()
          const { contenttype, contentTitle, upload_video_group, thumbnail, video_file, media_url, tags, forumCategory, contentBody, age_restriction_group, contentSummary, social_media_posting, small_name, s_price, small_quality, m_name, m_price,
            l_name, l_price, l_quality, h_name, h_price, hd_quality,
            campaign, description, target, beneficiary, about, course, price, about_media, car_vid_1, car_img_1, car_desc_1, car_img_2, car_img_3
          } = e.target.elements


          if (contenttype.value === "carousel") {

            dispatch(formUploadSubmitter({
              "contentType": contenttype.value,
              "carDesc": car_desc_1.value,
              "car_img_1": catOneImgRef.current.files[0],
              "car_vid_1": catOneVidRef.current.files[0],
              "car_img_2": catTwoImgRef.current.files[0],
              "car_img_3": catThreeImgRef.current.files[0]
            })
            );
          }

          if (contenttype.value === "video") {
            // Video contents need to have ContentType, Content Title, either {thumbnial && video file} or {Embeded file url}, Tag, Forum Category, Content Body, Age Restriction, Social Media Posting

            // Upload Video Content from the user's device
            if (upload_video_group.value === "storage") {
              dispatch(formUploadSubmitter({
                "contentType": contenttype.value, "title": contentTitle.value,
                "thumbnail": thumbnailRef.current.files[0],
                "video_file": videoFileRef.current.files[0],
                "tags": tags.value, "forumCategory": forumCategory.value, "contentBody": contentBody.value, "ageRestriction": age_restriction_group.value, "socialMedia": social_media_posting.value
              }))
              // console.log(age_restriction_group.value)
            } else {
              // Upload video Content from a url
              //submit ContentType, Content Title,Embeded file url, Tag, Forum Category, Content Body, Age Restriction, Social Media Posting
              dispatch(formUploadSubmitter({
                "contentType": contenttype.value, "title": contentTitle.value,
                "embededUrl": media_url.value,
                "tags": tags.value, "forumCategory": forumCategory.value, "contentBody": contentBody.value, "ageRestriction": age_restriction_group.value, "socialMedia": social_media_posting.value
              }))
            }


          } else if (contenttype.value === "image") {
            // This means that the content type is an image
            dispatch(formUploadSubmitter({
              "contentType": contenttype.value, "title": contentTitle.value,
              "contentSummary": contentSummary.value, "small_name": small_name.value,
              "s_price": s_price.value, "small_quality": smallResolutionRef.current.files[0],
              "m_name": m_name.value, "m_price": m_price.value, "m_quality": mediumResolutionRef.current.files[0],
              "l_name": l_name.value, "l_price": l_price.value, "l_quality": largeResolutionRef.current.files[0],
              "h_name": h_name.value, "h_price": h_price.value, "hd_quality": HDResolutionRef.current.files[0],
              "tags": tags.value, "ageRestriction": age_restriction_group.value,
            }))

          }
          else if (contenttype.value === "crowdfunding") {
            // This means that the content type is a crowdfunding
            dispatch(formUploadSubmitter({
              "contentType": contenttype.value, "campaign": campaign.value,
              "description": description.value, "target": target.value,
              "media": crowdFundingRef.current.files[0], "beneficiary": beneficiary.value,
              "about": about.value, "ageRestriction": age_restriction_group.value,
            }))

          }
          else if (contenttype.value === "master-class") {
            // This means that the content type is a master class

            dispatch(formUploadSubmitter({
              "contentType": contenttype.value, "title": contentTitle.value,
              "courseDescription": course.value, "price": price.value,
              "files": fields,
            }))

          }
        }

      } >
        {/* TYPE OF CONTENT */}
        <div className="mb-8">
          <label htmlFor="contenttype" className="block mb-2">
            Type of content<span className="text-strong-red">*</span>
          </label>
          <select
            id="contenttype"
            name="contenttype"
            className="form-select form-select-lg !shadow-none bg-clip-padding bg-no-repeat appearance-none px-3 py-2 w-full h-14 border border-gray rounded-md transition ease-in-out text-dark-gray bg-white cursor-pointer focus:text-dark-gray focus:border-strong-red focus:outline-none"
            aria-label="type of content"
            onChange={(e) => {

              dispatch(setContentType(e.target.value))
            }}
          >
            <option value="video">Video</option>
            <option value="image">Image</option>
            <option value="crowdfunding">Crowdfunding</option>
            <option value="master-class">Master Class</option>
            {user && user.maishaAccess === "maisha-admin" && <option value="carousel">Carousel</option>}
          </select>
        </div>

        {/* NAME */}
        {contentType == "carousel" && <Carousel catOneImgRef={catOneImgRef} catOneVidRef={catOneVidRef} catTwoImgRef={catTwoImgRef} catThreeImgRef={catThreeImgRef} />}
        {contentType !== "crowdfunding" && contentType !== "carousel" && (
          <div className="mb-8">
            <label htmlFor="contentTitle" className="block mb-2">
              Title of content<span className="text-strong-red">*</span>
            </label>
            <input
              id="contentTitle"
              name="contentTitle"
              type="text"
              className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
              placeholder="Title"
              onChange={(event) => {

              }}
            />
          </div>
        )}

        {/* VIDEO CONTENTTYPE */}
        {contentType === "video" && <Video thumbnailRef={thumbnailRef} videoFileRef={videoFileRef} />}

        {/* IMAGE CONTENTTYPE */}
        {contentType === "image" && <Image smallResolutionRef={smallResolutionRef} mediumResolutionRef={mediumResolutionRef} largeResolutionRef={largeResolutionRef} HDResolutionRef={HDResolutionRef} />}

        {/* CROWDFUNDING CONTENTTYPE */}
        {contentType === "crowdfunding" && <Crowdfunding crowdFundingRef={crowdFundingRef} />}

        {/* MASTERCLASS CONTENTTYPE */}
        {contentType === "master-class" && <MasterClass setFields={setFields} fields={fields} />}

        {/* AGE RESTRICTION */}
        {contentType !== "master-class" && contentType !== "carousel" && (
          <fieldset className="age-restriction mb-8">
            {/* ALL AGE */}
            <legend className="block mb-2 text-base">
              Age restriction<span className="text-strong-red">*</span>
            </legend>
            <div className="flex items-center justify-between">
              <div className="flex items-center mr-4">
                <input
                  id="all-age"
                  type="radio"
                  value="all-age"
                  name="age_restriction_group"
                  className="w-4 h-4 text-strong-red accent-strong-red bg- border-gray focus:ring-none focus:outline-none"
                  defaultChecked
                />
                <label
                  htmlFor="all-age"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  All age
                </label>
              </div>

              {/* PG */}
              <div className="flex items-center mr-4">
                <input
                  id="pg"
                  type="radio"
                  value="pg"
                  name="age_restriction_group"
                  className="w-4 h-4 text-strong-red accent-strong-red bg- border-gray focus:ring-none focus:outline-none"
                />
                <label
                  htmlFor="pg"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  PG
                </label>
              </div>

              {/* 14+ */}
              <div className="flex items-center mr-4">
                <input
                  id="fourteen-plus"
                  type="radio"
                  value="fourteen-plus"
                  name="age_restriction_group"
                  className="w-4 h-4 text-strong-red accent-strong-red bg- border-gray focus:ring-none focus:outline-none"
                />
                <label
                  htmlFor="fourteen-plus"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  14+
                </label>
              </div>

              {/* 18+ only */}
              <div className="flex items-center mr-4">
                <input
                  id="eighteen-plus"
                  type="radio"
                  value="eighteen-plus"
                  name="age_restriction_group"
                  className="w-4 h-4 text-strong-red accent-strong-red bg- border-gray focus:ring-none focus:outline-none"
                />
                <label
                  htmlFor="eighteen-plus"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  18+ only
                </label>
              </div>
            </div>
          </fieldset>
        )}

        {/* SOCIAL MEDIA POSTING */}
        {contentType === "video" && (
          <div className="mb-8">
            <label htmlFor="social-media-posting" className="flex mb-2">
              Social media posting
              <span className="mx-1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                    stroke="#414142"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 11.875C8.34518 11.875 8.625 11.5952 8.625 11.25C8.625 10.9048 8.34518 10.625 8 10.625C7.65482 10.625 7.375 10.9048 7.375 11.25C7.375 11.5952 7.65482 11.875 8 11.875Z"
                    fill="#414142"
                  />
                  <path
                    d="M8 9V8.5C8.34612 8.5 8.68446 8.39736 8.97225 8.20507C9.26003 8.01278 9.48434 7.73947 9.61679 7.4197C9.74924 7.09993 9.7839 6.74806 9.71637 6.40859C9.64885 6.06913 9.48218 5.75731 9.23744 5.51256C8.9927 5.26782 8.68087 5.10115 8.34141 5.03363C8.00194 4.9661 7.65007 5.00076 7.3303 5.13321C7.01053 5.26566 6.73722 5.48997 6.54493 5.77775C6.35264 6.06554 6.25 6.40388 6.25 6.75"
                    stroke="#414142"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
            <input
              id="social-media-posting"
              name="social_media_posting"
              type="text"
              className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
              placeholder="Enter a caption to be posted on your social media"
            />
            <div className="flex items-center justify-center lg:justify-start my-4 flex-wrap">
              <button
                type="button"
                className="bg-dark-gray px-6 py-2 text-white border border-dark-gray font-bold mr-2 rounded-md my-2"
              >
                Facebook
              </button>
              <button
                type="button"
                className="bg-white px-6 py-2 text-dark-gray border border-dark-gray font-bold mx-2 rounded-md my-2"
              >
                Twitter
              </button>
              <button
                type="button"
                className="bg-white px-6 py-2 text-dark-gray border border-dark-gray font-bold mx-2 rounded-md my-2"
              >
                Instagram
              </button>
            </div>

            {/* CHECKBOX */}
            <div className="flex items-center mb-4">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 accent-strong-red text-strong-red bg-gray rounded border-gray focus:outline-none"
              />
              <label
                htmlFor="default-checkbox"
                className="ml-2 text-sm font-medium text-gray"
              >
                Send to all connected social media
              </label>
            </div>
          </div>
        )}
        {/* SUBMIT BUTTON */}
        {submitting && <LoadingButton loading variant="outlined" className=" px-6 py-2 font-bold rounded-md mb-3 w-full lg:w-fit mt-2 lg:ml-auto">
          Submit
        </LoadingButton>}
        {!submitting && <button
          type="submit"
          className="bg-strong-red px-6 py-2 text-white border border-strong-red font-bold rounded-md mb-3 w-full lg:w-fit mt-2 lg:ml-auto"
        >
          Save
        </button>}
        <small className="block text-strong-red mb-3">
          “*” indicates required fields
        </small>
      </form>
    </section>
  );
};

export default MediaForm;
