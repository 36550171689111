import React from "react";
import { useSelector } from "react-redux";
import digitalImg from "../../assets/wiki/digital.svg";
import artImg from "../../assets/logo/logo_arts.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Hero = () => {
  const { isFocus } = useSelector((state) => state.wiki);
  let location = useLocation();

  return (
    <section className="px-5 lg:px-8 xl:px-5 mx-auto w-full max-w-xl lg:max-w-7xl w-full flex items-center flex-col mt-10 transition-all relative">
      <AnimatePresence>
        <motion.figure
          key="modal"
          initial={{ height: "0", opacity: 0, scale: 0.5 }}
          animate={{ height: "auto", opacity: 1, scale: 1 }}
          exit={{ height: "0", opacity: 0, scale: 0.5 }}
          className="w-full mx-auto"
        >
          {/* CHECK IF IT IS DIGITAL OR ARTS */}
          {!location.pathname.split("/").includes("maisha-arts") ? (
            <>
              <img
                src={digitalImg}
                alt="laptop"
                className="lg:w-5/12 mx-auto"
              />
            </>
          ) : (
            <>
              <img
                src={artImg}
                alt="painting palette"
                className="lg:w-3/12 mx-auto"
              />
            </>
          )}
        </motion.figure>
          <h1 className="font-caveat text-xl sm:text-2xl lg:text-4xl max-w-lg lg:max-w-screen-sm text-center mx-auto">
            “Not everyone who chased the zebra caught it, but he who caught,
            chased it”{" "}
            <span className="text-strong-red">- African Proverb</span>
          </h1>
      </AnimatePresence>
      {isFocus && (
        <AnimatePresence>
          <motion.h2
            key="modal"
            initial={{ height: "0", opacity: 0, scale: 0.5 }}
            animate={{ height: "auto", opacity: 1, scale: 1 }}
            exit={{ height: "0", opacity: 0, scale: 0.5 }}
            className="font-bold text-strong-red text-xl sm:text-2xl mt-4 mx-auto w-full text-center transition-all "
          >
            Search Maisha Digital
          </motion.h2>
        </AnimatePresence>
      )}
    </section>
  );
};

export default Hero;
