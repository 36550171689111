import React from "react";
import { useNavigate } from "react-router-dom";
import MediaForm from "../../components/Profile/UploadAndEdit/UploadMedia/MediaForm";
import WikiForm from "../../components/Profile/UploadAndEdit/UploadMedia/WikiForm";

const UploadMedia = () => {
  const navigate = useNavigate();

  return (
    <section className="text-dark-gray py-12 px-5 lg:px-8 xl:px-0 mx-auto w-full max-w-xl lg:max-w-7xl 2xl:max-w-8xl">
      <div className="flex items-end sm:items-center  justify-between lg:w-5/6 mx-auto">
        <div className="flex items-start sm:items-center flex-col sm:flex-row">
          <button className="mb-6 sm:mb-0 sm:mr-8" onClick={() => navigate(-1)} aria-label="previous page link">
            <i class="fas fa-arrow-left"></i>
          </button>
          <h1 className="font-bold text-2xl">Upload Media</h1>
        </div>
        <button className="bg-strong-red px-6 py-2 text-white border border-strong-red font-bold mx-2 rounded-md">
          Submit for approval
        </button>
      </div>
      <div className="mt-12 flex flex-wrap flex-col lg:flex-row justify-between gap-x-8 lg:w-5/6 mx-auto">
        {/* UPLOAD MEDIA */}
        <MediaForm />
        {/* WIKI ENTRY */}
        <WikiForm />
      </div>
    </section>
  );
};

export default UploadMedia;
