import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import maisha from "../../assets/logo/maisha.svg";
import { getAllVideos } from "../../redux/reducers/mediaSlice";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";

const WorkPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const {
    youtubePageTokens,
    filterBy,
    sortBy,
    error,
    errMsg,
    loading,
    success,
    videos,
  } = useSelector((state) => state.media);

  const curPage = 1;

  useEffect(() => {
    // PAGE TOKEN IS BASED ON CURRENT PAGE
    // dispatch(
    //   // getAllVideos({
    //   //   pageToken: youtubePageTokens[curPage - 1],
    //   //   filterBy,
    //   //   sortBy,
    //   // })
    // );

    // eslint-disable-next-line
  }, []);

  return (
    <section className="mt-12 px-5 lg:px-8 xl:px-0 mx-auto max-w-xl lg:max-w-7xl 2xl:max-w-8xl mx-4">
      <div className="flex items-center justify-start mb-6">

        <div className="logo">
          {/* <img src={maisha1} alt="" className="" />
        <img src={maisha2} alt="" className="" /> */}
          <img src={state.imageUrl} alt="" className="w-32 h-32 p-5 rounded-full" />
        </div>
        <h1 className="font-bold text-xl lg:text-3xl">{state.clientName}</h1>
      </div>
      <div className="mb-6">

        <p className="mt-4">
          {state.about}
        </p>
      </div>

      <section className="">
        <h2 className="font-bold text-lg lg:text-2xl mb-6">Client media</h2>
        <div className="videos">
          {/* {state.clientMediaUrls.map((e, index) => {
            return <div className="my-4 mx-10">
              <ReactPlayer key={index} url={e} width="100%" height="100%" />
            </div>

          })} */}

          {!error ? (
            <>
              {loading ? (
                <>
                  <div className="items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch">
                    {Array(12)
                      .fill()
                      .map((item, index) => (
                        <div
                          key={index}
                          role="status"
                          className="post xl:max-w-[18rem] md:mx-3 lg:mx-4 mb-8 relative group flex flex-col justify-between animate-pulse"
                        >
                          <div className="rounded-lg mb-4 bg-very-light-gray min-h-[198px] lg:min-h-[216px] w-full"></div>
                          <span className="sr-only">Loading...</span>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="items-start justify-center flex-wrap w-full mb-8 max-w-xl lg:max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch">
                    {
                      // videos.items

                      state.clientMediaUrls.map((video) => {
                        console.log(state.clientMediaUrls)
                        return (
                          <article
                            key={video._id}
                            className="post xl:max-w-[18rem] md:mx-3 lg:mx-4 mb-8 relative group flex flex-col justify-between"
                          >
                            <figure className="flex flex-col overflow-hidden relative">
                              <img
                                src={video.videoThumbnailUrl}
                                alt="media thumbnail"
                                className=" object-cover rounded-lg mb-4 bg-gray min-h-[198px] lg:min-h-[216px]" />
                              {/* <a href="" className="absolute inset-0 text-red"> */}
                              {true && (
                                <Link
                                  to={`/media/${video._id}`}
                                  aria-hidden="true"
                                  className="absolute inset-0 flex items-center justify-center group"
                                  aria-label="video link"
                                >
                                </Link>
                              )}
                              {/* <i className="text-2xl hover:text-[#ff0000] fab fa-youtube"></i>
          </a> */}
                            </figure>
                          </article>
                        );
                      })}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <p className=" mt-20 text-center text-very-dark-blue min-h-[30vh]">
                {errMsg}. Reload page
              </p>
            </>
          )}
        </div>
      </section>
    </section>
  );
};

export default WorkPage;