import React from "react";
import { Link } from "react-router-dom";
import aboutHeader from "../../../assets/channels/channel2.png";
import logo from "../../../assets/channels/intro_visual_art.png";
import EditChannel from "../../Profile/UploadAndEdit/EditChannel";

const Header = () => {
  return (
    <header className="w-full pb-7 border-b border-gray mb-5 relative">
      <img
        src={aboutHeader}
        alt="maisha banner"
        className="w-full min-h-[20vh] max-h-[40vh] object-cover border border-gray text-dark-gray rounded-md"
      />
      <div className="logo p-3 w-fit rounded-full border border-dark-gray mx-auto -mt-10 relative bg-white overflow-hidden">
        <img
          src={logo}
          alt="maisha logo"
          className="w-20 h-20 lg:w-40 lg:h-40"
        />
      </div>
      <h1 className="text-2xl lg:text-4xl font-bold mt-4 lg:mt-8 text-center">
        Bright Ackwerh
      </h1>

      <div className="upload-edit-btns flex items-center justify-center flex-wrap my-6 lg:absolute lg:top-[36%] xl:top-[40%] right-0">
        {/* <Link
          to="upload"
          className="bg-white px-6 py-2 text-strong-red border border-strong-red font-bold mx-2 rounded-md mb-3"
        >
          Upload media
        </Link>
        <button
          className="bg-strong-red px-6 py-2 text-white border border-strong-red font-bold mx-2 rounded-md mb-3"
          data-bs-toggle="modal"
          data-bs-target="#editChannelModal"
        >
          Edit Channel
        </button> */}
        <button
          className="bg-strong-red px-6 py-2 text-white border border-strong-red font-bold mx-2 rounded-md mb-3"
        >
          Subscribe
        </button>
      </div>

      {/* MODAL
      <EditChannel /> */}

      <div className="counts flex items-center justify-center mb-6 flex-wrap">
        <span className="flex items-center mb-3 mx-2">
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.1609 8.0531C13.1609 9.7991 11.7449 11.2141 9.99889 11.2141C8.25289 11.2141 6.83789 9.7991 6.83789 8.0531C6.83789 6.3061 8.25289 4.8911 9.99889 4.8911C11.7449 4.8911 13.1609 6.3061 13.1609 8.0531Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.998 15.3549C13.806 15.3549 17.289 12.6169 19.25 8.0529C17.289 3.4889 13.806 0.7509 9.998 0.7509H10.002C6.194 0.7509 2.711 3.4889 0.75 8.0529C2.711 12.6169 6.194 15.3549 10.002 15.3549H9.998Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="ml-2">5.4k views</p>
        </span>
        <span className="flex items-center mb-3 mx-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.592 15.2068C13.281 15.2068 16.434 15.7658 16.434 17.9988C16.434 20.2318 13.302 20.8068 9.592 20.8068C5.902 20.8068 2.75 20.2528 2.75 18.0188C2.75 15.7848 5.881 15.2068 9.592 15.2068Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.59108 12.0198C7.16908 12.0198 5.20508 10.0568 5.20508 7.6348C5.20508 5.2128 7.16908 3.2498 9.59108 3.2498C12.0121 3.2498 13.9761 5.2128 13.9761 7.6348C13.9851 10.0478 12.0351 12.0108 9.62208 12.0198H9.59108Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.4844 10.8816C18.0854 10.6566 19.3184 9.2826 19.3214 7.6196C19.3214 5.9806 18.1264 4.6206 16.5594 4.3636"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.5957 14.7322C20.1467 14.9632 21.2297 15.5072 21.2297 16.6272C21.2297 17.3982 20.7197 17.8982 19.8957 18.2112"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="ml-2">5.4k subscribers</p>
        </span>
        <span className="flex items-center mb-3 mx-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 21.75C18.1569 21.75 19.5 20.4069 19.5 18.75C19.5 17.0931 18.1569 15.75 16.5 15.75C14.8431 15.75 13.5 17.0931 13.5 18.75C13.5 20.4069 14.8431 21.75 16.5 21.75Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 8.25C18.1569 8.25 19.5 6.90685 19.5 5.25C19.5 3.59315 18.1569 2.25 16.5 2.25C14.8431 2.25 13.5 3.59315 13.5 5.25C13.5 6.90685 14.8431 8.25 16.5 8.25Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.9777 6.87186C11.8469 8.24113 8.52148 10.3781 8.52148 10.3781"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.52148 13.6219L13.9777 17.1281"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="ml-2">5.4k shares</p>
        </span>
        <span className="flex items-center mb-3 mx-2">
          <svg
            width="120"
            height="24"
            viewBox="0 0 120 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9189 14.3201C17.6599 14.5711 17.5409 14.9341 17.5999 15.2901L18.4889 20.2101C18.5639 20.6271 18.3879 21.0491 18.0389 21.2901C17.6969 21.5401 17.2419 21.5701 16.8689 21.3701L12.4399 19.0601C12.2859 18.9781 12.1149 18.9341 11.9399 18.9291H11.6689C11.5749 18.9431 11.4829 18.9731 11.3989 19.0191L6.96888 21.3401C6.74988 21.4501 6.50188 21.4891 6.25888 21.4501C5.66688 21.3381 5.27188 20.7741 5.36888 20.1791L6.25888 15.2591C6.31788 14.9001 6.19888 14.5351 5.93988 14.2801L2.32888 10.7801C2.02688 10.4871 1.92188 10.0471 2.05988 9.65011C2.19388 9.25411 2.53588 8.96511 2.94888 8.90011L7.91888 8.17911C8.29688 8.14011 8.62888 7.91011 8.79888 7.57011L10.9889 3.08011C11.0409 2.98011 11.1079 2.88811 11.1889 2.81011L11.2789 2.74011C11.3259 2.68811 11.3799 2.64511 11.4399 2.61011L11.5489 2.57011L11.7189 2.50011H12.1399C12.5159 2.53911 12.8469 2.76411 13.0199 3.10011L15.2389 7.57011C15.3989 7.89711 15.7099 8.12411 16.0689 8.17911L21.0389 8.90011C21.4589 8.96011 21.8099 9.25011 21.9489 9.65011C22.0799 10.0511 21.9669 10.4911 21.6589 10.7801L17.9189 14.3201Z"
              fill="#9B1C28"
            />
            <path
              d="M41.9189 14.3201C41.6599 14.5711 41.5409 14.9341 41.5999 15.2901L42.4889 20.2101C42.5639 20.6271 42.3879 21.0491 42.0389 21.2901C41.6969 21.5401 41.2419 21.5701 40.8689 21.3701L36.4399 19.0601C36.2859 18.9781 36.1149 18.9341 35.9399 18.9291H35.6689C35.5749 18.9431 35.4829 18.9731 35.3989 19.0191L30.9689 21.3401C30.7499 21.4501 30.5019 21.4891 30.2589 21.4501C29.6669 21.3381 29.2719 20.7741 29.3689 20.1791L30.2589 15.2591C30.3179 14.9001 30.1989 14.5351 29.9399 14.2801L26.3289 10.7801C26.0269 10.4871 25.9219 10.0471 26.0599 9.65011C26.1939 9.25411 26.5359 8.96511 26.9489 8.90011L31.9189 8.17911C32.2969 8.14011 32.6289 7.91011 32.7989 7.57011L34.9889 3.08011C35.0409 2.98011 35.1079 2.88811 35.1889 2.81011L35.2789 2.74011C35.3259 2.68811 35.3799 2.64511 35.4399 2.61011L35.5489 2.57011L35.7189 2.50011H36.1399C36.5159 2.53911 36.8469 2.76411 37.0199 3.10011L39.2389 7.57011C39.3989 7.89711 39.7099 8.12411 40.0689 8.17911L45.0389 8.90011C45.4589 8.96011 45.8099 9.25011 45.9489 9.65011C46.0799 10.0511 45.9669 10.4911 45.6589 10.7801L41.9189 14.3201Z"
              fill="#9B1C28"
            />
            <path
              d="M65.9189 14.3201C65.6599 14.5711 65.5409 14.9341 65.5999 15.2901L66.4889 20.2101C66.5639 20.6271 66.3879 21.0491 66.0389 21.2901C65.6969 21.5401 65.2419 21.5701 64.8689 21.3701L60.4399 19.0601C60.2859 18.9781 60.1149 18.9341 59.9399 18.9291H59.6689C59.5749 18.9431 59.4829 18.9731 59.3989 19.0191L54.9689 21.3401C54.7499 21.4501 54.5019 21.4891 54.2589 21.4501C53.6669 21.3381 53.2719 20.7741 53.3689 20.1791L54.2589 15.2591C54.3179 14.9001 54.1989 14.5351 53.9399 14.2801L50.3289 10.7801C50.0269 10.4871 49.9219 10.0471 50.0599 9.65011C50.1939 9.25411 50.5359 8.96511 50.9489 8.90011L55.9189 8.17911C56.2969 8.14011 56.6289 7.91011 56.7989 7.57011L58.9889 3.08011C59.0409 2.98011 59.1079 2.88811 59.1889 2.81011L59.2789 2.74011C59.3259 2.68811 59.3799 2.64511 59.4399 2.61011L59.5489 2.57011L59.7189 2.50011H60.1399C60.5159 2.53911 60.8469 2.76411 61.0199 3.10011L63.2389 7.57011C63.3989 7.89711 63.7099 8.12411 64.0689 8.17911L69.0389 8.90011C69.4589 8.96011 69.8099 9.25011 69.9489 9.65011C70.0799 10.0511 69.9669 10.4911 69.6589 10.7801L65.9189 14.3201Z"
              fill="#9B1C28"
            />
            <path
              d="M89.9189 14.3201C89.6599 14.5711 89.5409 14.9341 89.5999 15.2901L90.4889 20.2101C90.5639 20.6271 90.3879 21.0491 90.0389 21.2901C89.6969 21.5401 89.2419 21.5701 88.8689 21.3701L84.4399 19.0601C84.2859 18.9781 84.1149 18.9341 83.9399 18.9291H83.6689C83.5749 18.9431 83.4829 18.9731 83.3989 19.0191L78.9689 21.3401C78.7499 21.4501 78.5019 21.4891 78.2589 21.4501C77.6669 21.3381 77.2719 20.7741 77.3689 20.1791L78.2589 15.2591C78.3179 14.9001 78.1989 14.5351 77.9399 14.2801L74.3289 10.7801C74.0269 10.4871 73.9219 10.0471 74.0599 9.65011C74.1939 9.25411 74.5359 8.96511 74.9489 8.90011L79.9189 8.17911C80.2969 8.14011 80.6289 7.91011 80.7989 7.57011L82.9889 3.08011C83.0409 2.98011 83.1079 2.88811 83.1889 2.81011L83.2789 2.74011C83.3259 2.68811 83.3799 2.64511 83.4399 2.61011L83.5489 2.57011L83.7189 2.50011H84.1399C84.5159 2.53911 84.8469 2.76411 85.0199 3.10011L87.2389 7.57011C87.3989 7.89711 87.7099 8.12411 88.0689 8.17911L93.0389 8.90011C93.4589 8.96011 93.8099 9.25011 93.9489 9.65011C94.0799 10.0511 93.9669 10.4911 93.6589 10.7801L89.9189 14.3201Z"
              fill="#9B1C28"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M109.104 4.17701L110.932 7.82776C111.111 8.18616 111.456 8.43467 111.857 8.49218L115.945 9.08062C116.955 9.22644 117.357 10.4505 116.626 11.1519L113.67 13.9924C113.38 14.2718 113.247 14.6733 113.316 15.0676L114.014 19.0778C114.186 20.0698 113.13 20.8267 112.227 20.3574L108.573 18.4627C108.215 18.2768 107.786 18.2768 107.427 18.4627L103.773 20.3574C102.87 20.8267 101.814 20.0698 101.987 19.0778L102.684 15.0676C102.753 14.6733 102.62 14.2718 102.33 13.9924L99.3737 11.1519C98.6427 10.4505 99.0446 9.22644 100.055 9.08062L104.143 8.49218C104.544 8.43467 104.89 8.18616 105.069 7.82776L106.896 4.17701C107.348 3.27433 108.652 3.27433 109.104 4.17701Z"
              stroke="#414142"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="ml-2">500 have rated this</p>
        </span>
      </div>
      <div className="dark-buttons flex items-center justify-center mb-6 flex-wrap">
        <button className="bg-dark-gray px-6 py-2 text-white font-bold mx-2 rounded-md mb-3">
          Music
        </button>
        <button className="bg-dark-gray px-6 py-2 text-white font-bold mx-2 rounded-md mb-3">
          Animated
        </button>
        <button className="bg-dark-gray px-6 py-2 text-white font-bold mx-2 rounded-md mb-3">
          Video
        </button>
        <button className="bg-dark-gray px-6 py-2 text-white font-bold mx-2 rounded-md mb-3">
          Visual Arts
        </button>
      </div>
      <div className="light-buttons flex items-center justify-center mb-6 flex-wrap">
        <button className="bg-white px-6 py-2 text-dark-gray border bordere-dark-gray font-bold mx-2 rounded-md mb-3">
          Civic education
        </button>
        <button className="bg-white px-6 py-2 text-dark-gray border bordere-dark-gray font-bold mx-2 rounded-md mb-3">
          Children
        </button>
        <button className="bg-white px-6 py-2 text-dark-gray border bordere-dark-gray font-bold mx-2 rounded-md mb-3">
          Social jusice
        </button>
        <button className="bg-white px-6 py-2 text-dark-gray border bordere-dark-gray font-bold mx-2 rounded-md mb-3">
          Feminism
        </button>
        <button className="bg-white px-6 py-2 text-dark-gray border bordere-dark-gray font-bold mx-2 rounded-md mb-3">
          Comedy
        </button>
      </div>
      <div className="info mb-7 text-lg lg:text-xl flex flex-col lg:flex-row items-center justify-center text-center">
        <span className="uppercase">
          CREATIVE DIGITAL GRAPHICS, ANIMATION AND COMMUNICATION
        </span>
        <span className="mx-3">I</span>
        <span className="">
          We are telling African stories with creative media
        </span>
      </div>
      <div className="socials text-dark-gray flex flex-wrap items-center justify-center">
        <a
          href="#"
          aria-label="facebook link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fab fa-facebook"></i>
        </a>
        <a
          href="#"
          aria-label="instagram link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fab fa-instagram"></i>
        </a>
        <a
          href="#"
          aria-label="twitter link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fab fa-twitter"></i>
        </a>
        <a
          href="#"
          aria-label="youtube link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fab fa-youtube"></i>
        </a>
        <a
          href="#"
          aria-label="email link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fas fa-envelope"></i>
          <span className="ml-2">Email us</span>
        </a>
        <a
          href="#"
          aria-label="phone link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fas fa-phone-alt"></i>
          <span className="ml-2">Call us</span>
        </a>
        <a
          href="#"
          aria-label="whatsapp link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fab fa-whatsapp"></i>
          <span className="ml-2">Contact us</span>
        </a>
        <a
          href="#"
          aria-label="website link"
          className="mx-3 lg:mx-5 mb-3 lg:mb-2"
        >
          <i className="text-2xl hover:text-strong-red fas fa-globe-africa"></i>
          <span className="ml-2 text-strong-red">Official website</span>
        </a>
        <div className="flag mx-3 lg:mx-5 mb-3 lg:mb-2" aria-label="ghana flag">
          <svg
            width="50"
            height="33"
            viewBox="0 0 50 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_242_18514)">
              <path
                d="M49.9999 10.5403H0V0.482789C0 0.00671571 0.386034 -0.379318 0.862108 -0.379318H49.1379C49.614 -0.379318 50 0.00671571 50 0.482789L49.9999 10.5403Z"
                fill="#FF4B55"
              />
              <path
                d="M49.1378 32.3793H0.862108C0.386034 32.3793 0 31.9933 0 31.5172V21.4598H49.9999V31.5172C49.9999 31.9934 49.6139 32.3793 49.1378 32.3793Z"
                fill="#73AF00"
              />
              <path
                d="M49.9999 10.5401H0V21.459H49.9999V10.5401Z"
                fill="#FFE15A"
              />
              <path
                d="M25.3629 11.8896L26.2626 14.5871L29.1061 14.6092C29.4742 14.612 29.6269 15.0819 29.3308 15.3006L27.0433 16.9899L27.9011 19.7011C28.0122 20.0521 27.6125 20.3425 27.3129 20.1283L24.9994 18.4746L22.6859 20.1281C22.3865 20.3423 21.9868 20.0519 22.0977 19.7009L22.9555 16.9897L20.668 15.3004C20.3719 15.0817 20.5244 14.612 20.8927 14.609L23.7362 14.587L24.6359 11.8894C24.7524 11.5404 25.2464 11.5404 25.3629 11.8896Z"
                fill="#464655"
              />
            </g>
            <defs>
              <clipPath id="clip0_242_18514">
                <rect
                  width="50"
                  height="50"
                  fill="white"
                  transform="translate(0 -9)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Header;
