import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { searchInput } from "../../redux/reducers/wikiSlice";
import logoImg from "../../assets/logo/maisha-logo.svg";
import { AuthActions } from "../../redux/reducers/authSlice";
import { motion, useTransform, useScroll } from "framer-motion";
import shopImg from "../../assets/header/shop.png"


const Header = ({ children }) => {
  const authState = useSelector(state => state.protection)
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [homeToggle, setHomeToggle] = useState(false);
  const navigate = useNavigate()
  const menuToggle = () => {
    setHomeToggle(false);
    setMenuDisplay(!menuDisplay);
  };
  const { scrollYProgress } = useScroll()
  let location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes("wiki/search")) {
      dispatch(searchInput(""));
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  // SEARCH
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.wiki);
  const { register, getValues, handleSubmit } = useForm({
    mode: "onChange",
  });


  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const submitForm = (data) => {

    navigate({
      // CHECK IF IT'S MAISHA DIGITAL OR ARTS B4 LOADING PATHNAME
      pathname:
        location.pathname.split("/").at(1) === "maisha-arts"
          ? "/maisha-arts/wiki/search"
          : "/wiki/search",
      search: createSearchParams({
        q: `${data.search.toLowerCase().replaceAll(" ", "-")}`,
      }).toString(),
    });
  };

  return (
    <motion.header animate={{ scale: Math.min(1, 1 - scrollYProgress + 0.5) }} className="py-3 px-5 lg:px-8 2xl:px-0 mx-auto z-10 shadow-[0px_1px_1px_0px_#8888881A] sticky top-0 bg-white ">
      <div
        onClick={() => setMenuDisplay(false)}
        className={
          "screen -z-20 fixed inset-0 opacity-0 bg-black h-screen hidden lg:!hidden transition-all " +
          (menuDisplay ? "!opacity-60 !z-10 !block" : "")
        }
      ></div>
      <div className="wrapper max-w-xl lg:max-w-7xl 2xl:max-w-8xl mx-auto relative flex h-16 py-4 items-center justify-between">
        <>{children}</>
        <button
          type="button"
          aria-label="hamburger menu toggle"
          onClick={menuToggle}
          className={
            "z-30 top-4 right-6 group lg:hidden " +
            (menuDisplay ? "fixed sm:absolute top-7 sm:top-4" : "absolute")
          }
        >
          <div className="relative flex items-center justify-center rounded-full w-6 h-6 transform transition-all duration-200">
            <div
              className={
                "flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center " +
                (menuDisplay ? "-rotate-[45deg]" : "")
              }
            >
              <div
                className={
                  "bg-strong-red h-[2px] w-[20px] rounded transform transition-all duration-300 origin-right delay-75" +
                  (menuDisplay
                    ? " -translate-y-[1px] h-[1px] w-1/2 -rotate-90 bg-"
                    : "")
                }
              ></div>
              <div
                className={
                  "bg-strong-red h-[2px] rounded " + (menuDisplay ? "bg-" : "")
                }
              ></div>
              <div
                className={
                  "bg-strong-red h-[2px] w-[20px] rounded self-end transform transition-all duration-300 origin-left delay-75 " +
                  (menuDisplay
                    ? "translate-y-[1px] h-[1px] w-1/2 -rotate-90 bg-"
                    : "")
                }
              ></div>
            </div>
          </div>
        </button>

        <nav
          className={
            "menu text-base bg-white lg:flex-1 lg:w-full fixed inset-0 left-1/3 z-20 h-screen px-7 translate-x-full transition-all ease-in-out duration-500 lg:left-0 lg:translate-x-0 lg:relative lg:p-0 lg:h-auto lg:flex lg:justify-between lg:items-center " +
            (menuDisplay ? "!translate-x-0" : "")
          }
        >
          <div className=" lg:w-[30vw] font-normal text-center lg:text-left flex flex-col lg:flex-row lg:items-center pt-20 lg:p-0 lg:mx-3 xl:mx-9 lg:h-inherit mb-12 lg:mb-0">
            <div className="dropdown relative">
              <button
                onClick={() => {
                  setHomeToggle((value) => !value)
                }}
                before="Home"
                className="
                dropdown-toggle group mb-5 pt-1 flex items-center lg:pt-0 lg:mb-0 mx-auto lg:mx-1 xl:mx-2 cursor-pointer transition-all
                relative before:content-[attr(before)] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red
                "
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Home
                <svg
                  className=" ml-2 h-5 w-4 text-dark-gray group-hover:text-strong-red"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="caret-down"
                  role="img"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              <ul
                className={`dropdown-menu min-w-max absolute bg-white z-50 left-0 py-1 text-left rounded-lg shadow-lg mt-1 ${homeToggle == false ? "hidden" : ""}`}
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    onClick={() => {
                      setHomeToggle(false)
                      return setMenuDisplay(false);
                    }}
                    className="dropdown-item !bg-white py-3 px-6 block w-full transition-all relative before:content-['Maisha_Digital'] before:absolute inset-0 hover:before:font-bold !text-white before:text-dark-gray hover:before:text-strong-red"
                    to="/maisha-digital"
                  >
                    Maisha Digital
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      setHomeToggle(false)
                      return setMenuDisplay(false);
                    }}
                    className="dropdown-item !bg-white py-3 px-6 block w-full transition-all relative before:content-['Maisha_Art'] before:absolute inset-0 hover:before:font-bold !text-white before:text-dark-gray hover:before:text-strong-red"
                    to="/maisha-arts"
                  >
                    Maisha Art
                  </NavLink>
                </li>
              </ul>
            </div>
            <NavLink
              onClick={() => setMenuDisplay(false)}
              to="media"
              className={({ isActive }) =>
                "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 lg:flex lg:items-center cursor-pointer transition-all relative before:content-['Media'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                (!isActive
                  ? ""
                  : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
              }
            >
              Media
            </NavLink>
            <NavLink
              onClick={() => setMenuDisplay(false)}
              to="wiki"
              className={({ isActive }) =>
                "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 lg:flex lg:items-center cursor-pointer transition-all relative before:content-['Wiki'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                (!isActive
                  ? ""
                  : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
              }
            >
              Wiki
            </NavLink>
            <NavLink
              onClick={() => setMenuDisplay(false)}
              to="forum"
              className={({ isActive }) =>
                "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 lg:flex lg:items-center cursor-pointer transition-all relative before:content-['Forum'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                (!isActive
                  ? ""
                  : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
              }
            >
              Forum
            </NavLink>
            {/* CHECK IF IT IS DIGITAL OR ARTS */}
            {location.pathname.split("/").includes("maisha-arts") && (
              <NavLink
                onClick={() => setMenuDisplay(false)}
                to="channels"
                className={({ isActive }) =>
                  "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 whitespace-nowrap lg:flex lg:items-center cursor-pointer transition-all relative before:content-['Channels'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                  (!isActive
                    ? ""
                    : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
                }
              >
                Channels
              </NavLink>
            )}
            <NavLink
              onClick={() => setMenuDisplay(false)}
              to="crowdfunding"
              className={({ isActive }) =>
                "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 lg:flex lg:items-center cursor-pointer transition-all relative before:content-['Crowdfunding'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                (!isActive
                  ? ""
                  : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
              }
            >
              Crowdfunding
            </NavLink>
            {/* CHECK IF IT IS DIGITAL OR ARTS */}
            {!location.pathname.split("/").includes("maisha-arts") && (
              <NavLink
                onClick={() => setMenuDisplay(false)}
                to="our-works"
                className={({ isActive }) =>
                  "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 whitespace-nowrap lg:flex lg:items-center cursor-pointer transition-all relative before:content-['Our_works'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                  (!isActive
                    ? ""
                    : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
                }
              >
                Our works
              </NavLink>
            )}
            <NavLink
              onClick={() => setMenuDisplay(false)}
              to={authState?.user.maishaAccess === "maisha-admin" ? "about" : "dashboard"}
              className={({ isActive }) =>
                "mb-5 pt-1 lg:pt-0 lg:mb-0 lg:mx-1 xl:mx-2 lg:flex lg:items-center cursor-pointer transition-all relative before:content-['About'] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red    lg:after:content-[''] after:absolute after:-bottom-4 after:inset-x-0 after:-z-10 after:lg:z-10 after:h-1 after:bg-strong-red after:w-0 after:mx-auto after:transition-all " +
                (!isActive
                  ? ""
                  : "after:!w-1/2 text-strong-red before:text-strong-red before:font-bold")
              }
            >
              About
            </NavLink>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-center">
            <div className="mb-4 lg:mb-0">
              <label
                htmlFor="search"
                className="block text-sm font-medium sr-only"
              >
                Search
              </label>
              <form
                onSubmit={handleSubmit(submitForm)}
                onChange={() => {
                  const value = getValues("search");
                  dispatch(searchInput(value));
                }}
                className="relative flex items-center"
              >
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={searchText}
                  className="focus:outline-none w-full pl-6 h-10 rounded-l-md relative border border-gray border-r-transparent"
                  placeholder="Search"
                  {...register("search", { required: true })}
                />
                <button type="submit" className="mr-3 pt-1" aria-label="search">
                  <ion-icon name="search"></ion-icon>
                  <span className="sr-only">search</span>
                </button>
                <div className="absolute inset-0 flex py-1.5 pr-1.5 border border-gray rounded-md -z-10"></div>
              </form>
            </div>
            <div className="mb-4 lg:mb-0 flex w-full lg:w-fit items-center justify-center text-center max-w-[15rem]">


              {authState.user == null && <NavLink
                onClick={() => setMenuDisplay(false)}
                to="/login"
                className="text-white mx-auto bg-dark-gray h-10 w-full lg:w-auto px-7 rounded-md lg:ml-4 flex items-center justify-center cursor-pointer hover:bg-very-light-gray hover:text-dark-gray transition-all"
              >
                Login
              </NavLink>}
              {authState.user !== null && <div className="mx-2 flex items-center cursor-pointer" onClick={(e) => {
                navigate("dashboard")
              }} ><img className="rounded-full w-10 h-10" src={authState.user?.imageUrl ?? logoImg} /><span className="truncate"> {authState.user?.fullname}</span>
                <div className="dropdown relative">
                  <button

                    className="
                    dropdown-toggle group mb-5 pt-1 flex items-center lg:pt-0 lg:mb-0 mx-auto lg:mx-2 xl:mx-4 cursor-pointer transition-all
                    relative before:content-[attr(before)] before:absolute inset-0 hover:before:font-bold text-white before:text-dark-gray hover:before:text-strong-red
                    "
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >

                    <svg
                      className=" ml-2 h-5 w-5 text-dark-gray group-hover:text-strong-red"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="caret-down"
                      role="img"
                    >

                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <ul
                    className="dropdown-menu min-w-max absolute bg-white z-50 left-0 py-1 text-left rounded-lg shadow-lg mt-1 hidden"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li>
                      <NavLink
                        // onClick={() => setMenuDisplay(false)}
                        className="dropdown-item !bg-white py-3 px-6 block w-full transition-all relative before:content-['Profile'] before:absolute inset-0 hover:before:font-bold !text-white before:text-dark-gray hover:before:text-strong-red"
                        to="/profile"
                      >
                        Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => dispatch(AuthActions.logout())}
                        className="dropdown-item !bg-white py-3 px-6 block w-full transition-all relative before:content-['Logout'] before:absolute inset-0 hover:before:font-bold !text-white before:text-dark-gray hover:before:text-strong-red"
                      // to="/maisha-arts"
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </div>

              </div>}
              <div className="flex items-center hover:text-strong-red hover:font-bold cursor-pointer" onClick={(e) => {
                navigate("shop")
              }}>
                <img src={shopImg} className="w-[30px]" alt="Shop" />
                Shop
              </div>

            </div>
          </div>
        </nav>
      </div>
    </motion.header>
  );
};

export default Header;
