import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";

export const fecthOurWorks = createAsyncThunk("Fecth/OurWork", async () => {
    const response = await fetch(baseUrl + "/our-work/all", {
        headers: {
            "Content-Type": "application/json",
            "accept": "application/json"
        },
        method: "GET"
    })
    const data = await response.json()
    console.log(data);
    return data["works"]
})
export const fecthSingleWork = createAsyncThunk("Fecth/SingleWork", async ({ id }) => {
    const response = await fetch(baseUrl + `/our-work/get-client-work/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "accept": "application/json"
        },
        method: "GET"
    })
    const data = await response.json()
    console.log(data);
    return data["work"]
})

const ourWorkSlice = createSlice({
    name: "Work Slice",
    initialState: {
        works: [],
        selectedWork: null,
        loading: false,
        error: false
    },
    extraReducers: builder => {
        builder.addCase(fecthOurWorks.fulfilled, (state, action) => {
            state.works = action.payload
            state.loading = false
        })
        builder.addCase(fecthOurWorks.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fecthOurWorks.rejected, (state, action) => {
            state.loading = false
            state.error = true
        })
        builder.addCase(fecthSingleWork.fulfilled, (state, action) => {
            state.selectedWork = action.payload
            state.loading = false
        })
        builder.addCase(fecthSingleWork.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fecthSingleWork.rejected, (state, action) => {
            state.loading = false
            state.error = true
        })
    }
})
export default ourWorkSlice.reducer