import React, { useEffect, useState } from "react";
import course1 from "../../../assets/channels/intro_digital_art.png";
import course2 from "../../../assets/channels/digital_art2.png";
import course3 from "../../../assets/channels/digital_art3.png";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import { PaystackButton } from "react-paystack";
import { enrollForCourse, otherCourseByCreator } from "../../../redux/reducers/masterClassSlice";
import { useNavigate } from "react-router-dom";


const ClassDetails = ({ masterClass, otherCourses, othersLoading }) => {

  const [currentWatch, setCurrentWatch] = useState(0);
  const [completedIndexes, setCompletedIndexes] = useState([]);
  const [timePlayed, setTimePlayed] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const { user } = useSelector((state) => state.protection);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  function secondsToTimeOfWatch(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var seconds = seconds % 60;

    if (hours === 0) {
      minutes = String(minutes).padStart(2, "0");
      seconds = String(seconds).padStart(2, "0");
      return minutes + ":" + seconds;
    }
    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return hours + ":" + minutes + ":" + seconds;
  }

  useEffect(() => {
    setIsPlaying(true);
    dispatch(otherCourseByCreator({
      id: masterClass.channelId._id
    }))
  }, []);

  useEffect(() => {
    if (currentWatch >= masterClass.modules.length) {
      setIsPlaying(false);
      return;
    }
    setTimePlayed(0);
    setIsPlaying(true);
  }, [currentWatch]);
  const handleVideoEnded = () => {
    setCompletedIndexes((currValues) => [...currValues, currentWatch]);
    if (masterClass.modules.length - 2 >= currentWatch) {
      setCurrentWatch((currValue) => currValue + 1);
      return
    }

  };
  function enrollforCourse(id) {
    dispatch(enrollForCourse({
      courseID: id
    }))
  }
  return (
    <>
      <div className="flex flex-col lg:flex-row items-start justify-center flex-wrap lg:gap-x-6 w-full max-w-xl lg:max-w-7xl mx-auto">
        <h1 className="sr-only">Master class</h1>
        <div className="w-full lg:w-8/12 mb-8 lg:mb-0">
          <div className="bg-very-light-gray min-h-[35vh] rounded-md w-full" onContextMenu={(e) => {
            e.preventDefault()
          }}>
            <ReactPlayer
              width="100%"
              url={masterClass.modules[currentWatch].url}
              controls={true}
              playing={isPlaying}
              onPause={(e) => {
                setIsPlaying(false)
              }}
              onPlay={(e) => {
                setIsPlaying(true)
              }}
              onProgress={(state) => {
                setTimePlayed(Math.floor(state.playedSeconds / masterClass.modules[currentWatch].videoLenght));
              }}
              onEnded={handleVideoEnded}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  }
                }
              }}
            />

          </div>
          {/* MORE DETAILS */}
          <div className="mt-8">
            <div className="flex items-center justify-center sm:justify-between flex-wrap">
              <div id="user-detail" className="flex items-center">
                <div className="user-icon w-16 h-16 rounded-full border overflow-hidden">
                  <img
                    src={masterClass.channelId.channelThumbnailUrl}
                    alt="user icon"
                    className="w-full h-full"
                  />
                </div>
                <h2 className="font-medium text-2xl lg:text-3xl ml-4">
                  {masterClass.channelId.channelName}
                </h2>
              </div>
              <div className="flex items-center justify-center sm:justify-start gap-6 flex-wrap mt-4 ">
                <div className="flex items-center gap-4">
                  <div className="border border-strong-red py-1 px-2 text-strong-red flex items-center rounded-md">
                    <svg
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2.42705L13.9809 8.52356L14.1493 9.0418H14.6942H21.1044L15.9184 12.8096L15.4776 13.1299L15.646 13.6482L17.6268 19.7447L12.4408 15.9768L12 15.6565L11.5592 15.9768L6.37316 19.7447L8.35404 13.6482L8.52242 13.1299L8.08158 12.8096L2.89559 9.0418H9.30583H9.85074L10.0191 8.52356L12 2.42705Z"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                      />
                    </svg>
                    <span className="ml-2">{masterClass.channelId.stars.length}</span>
                  </div>

                  <div className="border border-strong-red py-1 px-2 text-strong-red flex items-center rounded-md">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 21.75C18.1569 21.75 19.5 20.4069 19.5 18.75C19.5 17.0931 18.1569 15.75 16.5 15.75C14.8431 15.75 13.5 17.0931 13.5 18.75C13.5 20.4069 14.8431 21.75 16.5 21.75Z"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 8.25C18.1569 8.25 19.5 6.90685 19.5 5.25C19.5 3.59315 18.1569 2.25 16.5 2.25C14.8431 2.25 13.5 3.59315 13.5 5.25C13.5 6.90685 14.8431 8.25 16.5 8.25Z"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.9777 6.87207L8.52148 10.3783"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.52148 13.6221L13.9777 17.1283"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="ml-2">{masterClass.channelId.shares.length}</span>
                  </div>

                  <div className="border border-strong-red py-1 px-2 text-strong-red flex items-center rounded-md">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.592 13.207C11.281 13.207 14.434 13.766 14.434 15.999C14.434 18.232 11.302 18.807 7.592 18.807C3.902 18.807 0.75 18.253 0.75 16.019C0.75 13.785 3.881 13.207 7.592 13.207Z"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.59108 10.02C5.16908 10.02 3.20508 8.057 3.20508 5.635C3.20508 3.213 5.16908 1.25 7.59108 1.25C10.0121 1.25 11.9761 3.213 11.9761 5.635C11.9851 8.048 10.0351 10.011 7.62208 10.02H7.59108Z"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.4844 8.88226C16.0854 8.65726 17.3184 7.28326 17.3214 5.62026C17.3214 3.98126 16.1264 2.62126 14.5594 2.36426"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5957 12.7324C18.1467 12.9634 19.2297 13.5074 19.2297 14.6274C19.2297 15.3984 18.7197 15.8984 17.8957 16.2114"
                        stroke="#9B1C28"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="ml-2">{masterClass.channelId.followers.length}</span>
                  </div>
                </div>

                {!masterClass.enrolledUsers.includes(user._id) && <PaystackButton
                  amount={masterClass.price * 100}
                  email={user.email}
                  publicKey={process.env.REACT_APP_TEST_KEY}
                  currency="GHS"
                  onClose={() => {
                    console.log("Closed")
                  }}
                  onSuccess={() => {
                    enrollforCourse(masterClass._id)
                  }}
                  className="bg-very-light-gray rounded-md my-6 py-4 px-6 text-dark-gray font-medium mb-8 hover:bg-gray hover:text-white transition-all"
                >
                  <button className="bg-strong-red py-3 px-6 text-white flex items-center rounded-md">
                    Enroll for GHS {masterClass.price.toLocaleString("en-US")}
                  </button>
                </PaystackButton>}
              </div>
            </div>
            <div className="flex items-center flex-wrap justify-between mt-8">
              <h3 className="text-xl lg:text-2xl font-bold">
                {masterClass.title}
              </h3>

              <div className="flex">
                <p className="">
                  Published: {new Date(masterClass.postDate).toLocaleDateString("en-US")} by{" "}
                  <span className="text-strong-red font-bold">
                    {masterClass.channelId.channelName}
                  </span>
                </p>
                <div className="ml-4 flex items-center">
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.1609 8.0536C13.1609 9.7996 11.7449 11.2146 9.99889 11.2146C8.25289 11.2146 6.83789 9.7996 6.83789 8.0536C6.83789 6.3066 8.25289 4.8916 9.99889 4.8916C11.7449 4.8916 13.1609 6.3066 13.1609 8.0536Z"
                      stroke="#414142"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.998 15.355C13.806 15.355 17.289 12.617 19.25 8.05298C17.289 3.48898 13.806 0.750977 9.998 0.750977H10.002C6.194 0.750977 2.711 3.48898 0.75 8.05298C2.711 12.617 6.194 15.355 10.002 15.355H9.998Z"
                      stroke="#414142"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ml-2">{masterClass.enrolledUsers.length} Views</span>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <h4 className="font-bold mb-4">About the course</h4>
              <p className="">
                {masterClass.description}
              </p>
            </div>

            <div className="mt-8">
              <h4 className="font-bold mb-4">Other courses by the author</h4>
              <div className="grid items-center lg:items-start justify-between lg:gap-x-6 mb-8 w-full max-w-xl lg:max-w-7xl mx-auto">
                <div className="flex-1 w-full mb-8 lg:mb-0 flex flex-col sm:flex-row sm:gap-x-4">
                  {otherCourses.length === 0 && (
                    <div>
                      This Creator has no Other course
                    </div>
                  )}

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {otherCourses.map((e, index) => (
                      <article
                        className="w-full relative flex flex-col justify-between cursor-pointer"
                        onClick={() => {
                          navigate("/maisha-arts/channels/masterclass/class?id=" + e._id);
                        }}
                        key={index}
                      >
                        <figure className="flex flex-col overflow-hidden h-40 rounded-lg">
                          <img
                            src={e.thumbnailUrl}
                            alt="media thumbnail"
                            className="object-cover rounded-lg mb-4 bg-gray min-h-[198px] lg:min-h-[216px]"
                          />
                        </figure>
                        <p className="text-center mt-4">{e.title}</p>
                      </article>
                    ))}
                  </div>
                </div>
                {/* <div className="flex-1 w-full lg:w-8/12 mb-8 lg:mb-0">
                  <div className="flex flex-col sm:flex-row sm:gap-x-4 items-start"></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 w-full mb-8 lg:mb-0">

          <div className="flex items-center justify-between font-bold text-xl sm:text-xl lg:text-3xl">
            <h2 className="">Videos in this course</h2>
            <span className="">{masterClass.modules.length}</span>
          </div>
          {/* COURSE VIDEOS */}
          <div className="">
            {masterClass.modules.map((module, index) => {
              if (module.url === "") {
                return <div className="mt-4 flex items-center justify-between" onClick={(e) => {

                }}>
                  <div className="flex items-center">
                    <div className="bg-very-light-gray w-20 h-20 rounded-full flex items-center justify-center">
                      <i className="fas fa-lock text-gray"></i>
                    </div>
                    <span className="text-gray mx-4 flex-1">
                      {module.title}
                    </span>
                  </div>
                  <div className="font-bold">{secondsToTimeOfWatch(module.videoLenght)}</div>
                </div>
              } else if (index === currentWatch) {
                if (isPlaying) {
                  return <div className="mt-4 flex items-center justify-between cursor-pointer" onClick={(e) => {
                    setIsPlaying(false)
                  }}>
                    <div className="flex items-center">
                      <div
                        className={`bg-[conic-gradient(#9B1C28_${timePlayed * 100}%,_#A4A4A4_0)] w-20 h-20 rounded-full relative flex items-center justify-center
                                 before before:bg-gray before:w-[4.5rem] before:h-[4.5rem] before:rounded-full before:absolute`}
                      >
                        <i className="fas fa-pause text-white relative"></i>

                      </div>
                      <span className="text-gray mx-4 flex-1">
                        {module.title}
                      </span>
                    </div>
                    <div className="font-bold">{secondsToTimeOfWatch(module.videoLenght)}</div>
                  </div>
                } else {
                  return <div className="mt-4 flex items-center justify-between cursor-pointer" onClick={(e) => {
                    setIsPlaying(true)
                  }}>
                    <div className="flex items-center">
                      <div className="bg-dark-gray w-20 h-20 rounded-full flex items-center justify-center">
                        <i className="fas fa-play text-white"></i>
                      </div>
                      <span className="text-gray mx-4 flex-1">
                        {module.title}
                      </span>
                    </div>
                    <div className="font-bold">{secondsToTimeOfWatch(module.videoLenght)}</div>
                  </div>
                }
              } else if (completedIndexes.includes(index)) {

                return <div key={index} className="mt-4 flex items-center justify-between" onClick={(e) => {
                  setCurrentWatch(index)
                  setIsPlaying(true)
                }}>
                  <div className="flex items-center">
                    <div className="bg-strong-red w-20 h-20 rounded-full flex items-center justify-center">
                      <i className="fas fa-check text-white"></i>
                    </div>
                    <span className="text-gray mx-4 flex-1">
                      {module.title}
                    </span>
                  </div>
                  <div className="font-bold">{secondsToTimeOfWatch(module.videoLenght)}</div>
                </div>
              }
              return <div className="mt-4 flex items-center justify-between cursor-pointer" onClick={(e) => {
                setCurrentWatch(index)
              }}>
                <div className="flex items-center">
                  <div className="bg-dark-gray w-20 h-20 rounded-full flex items-center justify-center">
                    <i className="fas fa-play text-white"></i>
                  </div>
                  <span className="text-gray mx-4 flex-1">
                    {module.title}
                  </span>
                </div>
                <div className="font-bold">{secondsToTimeOfWatch(module.videoLenght)}</div>
              </div>
            })}
          </div>

          {!masterClass.enrolledUsers.includes(user._id) && <PaystackButton
            amount={masterClass.price * 100}
            email={user.email}
            publicKey={process.env.REACT_APP_TEST_KEY}
            currency="GHS"
            onClose={() => {
              console.log("Closed")
            }}
            onSuccess={() => {
              enrollforCourse(masterClass._id)
            }}
            className="bg-very-light-gray rounded-md w-full my-6 py-4 px-6 text-dark-gray font-medium mb-8 hover:bg-gray hover:text-white transition-all"
          >
            Enroll for this course to view all classes
          </PaystackButton>}
        </div>
      </div>
    </>
  );
};

export default ClassDetails;
