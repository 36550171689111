import React from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";

const Notices = () => {
  let params = useParams();

  return (
    <div className="mb-24">
      <div className="sticky top-0 pb-4 bg-white w-full flex items-center justify-between text-dark-gray mb-4 z-10">
        <div className="">
          <NavLink
            to=""
            className={({ isActive }) =>
              "font-bold text-2xl lg:text-3xl " +
              (!isActive ? "text-gray" : "text-dark-gray")
            }
            end
          >
            Notices
          </NavLink>
          {params.title && (
            <Link to={params.title} className="">
              <i className="fas fa-chevron-right mx-6"></i>
              <span className="text-xl lg:text-2xl font-bold text-dark-gray">
                {params.title.charAt(0).toUpperCase() +
                  params.title.slice(1).toLowerCase()}
              </span>
            </Link>
          )}
        </div>
        <>
          {params.title ? (
            <Link
              to=""
              className="flex items-center justify-center text-strong-red"
            >
              <ion-icon name="arrow-back"></ion-icon>
              <span className="ml-2 font-bold underline">Go back</span>
            </Link>
          ) : (
            <div className="">
              <span className="font-bold">7 Topics {""}</span>
              <span className="text-gray font-normal">showing 7 of 7</span>
            </div>
          )}
        </>
      </div>
      <Outlet />
    </div>
  );
};

export default Notices;
