import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const Forum = () => {
  return (
    <div className="box-border lg:h-[120vh] w-full scroll-smooth px-5 lg:px-8 2xl:px-0 items-start justify-center flex-wrap w-full max-w-xl lg:max-w-7xl mx-auto relative mt-12 overflow-hidden">
      <h1 className="font-bold text-3xl lg:text-4xl mb-12">
        Maisha Africa Forums
      </h1>
      <div className="sticky flex flex-col lg:flex-row lg:h-full lg:space-x-6">
        <div className="lg:h-full flex-1 mb-8 lg:mb-0">
          <div className="flex items-center">
            <h2 className="text-gray font-bold text-2xl lg:text-3xl mr-4">
              Forum
            </h2>
            <hr className="flex-1 text-gray" />
          </div>
          <nav className="links text-dark-gray mt-8">
            <NavLink
              to=""
              className={({ isActive }) =>
                "group pr-3 py-3 my-2 flex items-center text-lg lg:text-xl " +
                (!isActive
                  ? " hover:bg-very-light-gray"
                  : "text-strong-red font-bold border-r-2  border-strong-red hover")
              }
              end
            >
              <ion-icon name="notifications" class="text-2xl mr-3"></ion-icon>
              <span className="truncate">Notices</span>
            </NavLink>
            <NavLink
              to="educational-videos"
              className={({ isActive }) =>
                "group pr-3 py-3 my-2 flex items-center text-lg lg:text-xl " +
                (!isActive
                  ? " hover:bg-very-light-gray"
                  : "text-strong-red font-bold border-r-2  border-strong-red hover")
              }
            >
              <div className="text-xs border border-inherit p-1 rounded-full mr-3 flex items-center justify-center">
                <ion-icon name="play" class="relative ml-px"></ion-icon>
              </div>
              <span className="truncate">Educational Videos</span>
            </NavLink>
            <NavLink
              to="educational-literature"
              className={({ isActive }) =>
                "group pr-3 py-3 my-2 flex items-center text-lg lg:text-xl " +
                (!isActive
                  ? " hover:bg-very-light-gray"
                  : "text-strong-red font-bold border-r-2  border-strong-red hover")
              }
            >
              <i className="fas fa-book mr-3"></i>
              <span className="truncate">Educational Literature</span>
            </NavLink>
            <NavLink
              to="maisha-store"
              className={({ isActive }) =>
                "group pr-3 py-3 my-2 flex items-center text-lg lg:text-xl " +
                (!isActive
                  ? " hover:bg-very-light-gray"
                  : "text-strong-red font-bold border-r-2  border-strong-red hover")
              }
            >
              <ion-icon name="cart" class="mr-3"></ion-icon>
              <span className="truncate">Maisha Store</span>
            </NavLink>
          </nav>
        </div>
        <div className="w-full lg:w-3/4 overflow-y-scroll scrollbar-hide sticky flex flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Forum;
