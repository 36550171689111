import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";

export const getComment = createAsyncThunk("getComments", async (mediaId) => {
    const response = await fetch(baseUrl + "/media/comments/" + mediaId)
    return (await response.json())["comments"]
})
export const postComment = createAsyncThunk("postComment", async (payload, action) => {
    console.log(payload.content)
    const response = await fetch(baseUrl + "/media/comments/" + payload.mediaId, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            "author": "63a424d21d9fd786cfcf43fe",
            "content": payload.content
        })
    })
    return (await response.json())["comments"]
})
export const postReply = createAsyncThunk("postReply", async (payload, action) => {
    console.log(payload.content)
    const response = await fetch(baseUrl + "/media/replies/" + payload.commentId, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify({
            "author": "63a424d21d9fd786cfcf43fe",
            "content": payload.content
        })
    })
    return (await response.json())["comment"]
})


const commentSlice = createSlice({
    name: "CommentSlice",
    initialState: {
        comments: [],
        loaded: false,
        commentLoading: false,
        error: false,
        buttonLoading: false,
        replyLoading: false
    },
    extraReducers: builder => {
        builder.addCase(getComment.fulfilled, (state, action) => {
            state.comments = action.payload
            state.commentLoading = false
            state.loaded = true
        })
        builder.addCase(getComment.pending, (state, action) => {
            state.commentLoading = true
        })
        //
        builder.addCase(getComment.rejected, (state, action) => {
            console.log("GEt Comment failed")
        })

        builder.addCase(postComment.fulfilled, (state, action) => {

            // state.comments = action.payload
            state.comments = action.payload.comments
            state.commentLoading = false
            state.loaded = true

        })
        builder.addCase(postComment.pending, (state, action) => {
            state.commentLoading = true
        })
        builder.addCase(postComment.rejected, (state, action) => {
            console.log("Post Comment failed")
        })
        builder.addCase(postReply.fulfilled, (state, action) => {

            // state.comments = action.payload
            // state.comments = action.payload.comments

            console.log(state.comments.map(comment => {
                console.log(typeof comment)

                if (comment._id == action.payload._id) {
                    return action.payload
                }
                return comment
            }))
            state.replyLoading = false
            // state.loaded = true

        })
        builder.addCase(postReply.pending, (state, action) => {
            state.replyLoading = true
        })
        builder.addCase(postReply.rejected, (state, action) => {
            console.log("Post Comment failed")
        })
    }
})
export default commentSlice.reducer;