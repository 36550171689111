import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideos } from "../../../redux/reducers/mediaSlice";
import Posts from '../../Media/Posts'

const SubscriptionsTab = () => {

  const dispatch = useDispatch();

  const { youtubePageTokens, filterBy, sortBy } = useSelector(
    (state) => state.media
  );

  const curPage = localStorage.getItem("curPage")
    ? localStorage.getItem("curPage")
    : 1;

  useEffect(() => {
    // PAGE TOKEN IS BASED ON CURRENT PAGE
    // dispatch(
    //   getAllVideos({
    //     pageToken: youtubePageTokens[curPage - 1],
    //     filterBy,
    //     sortBy,
    //   })
    // );

    // eslint-disable-next-line
  }, [curPage]);

  return (
    <>
      <Posts />
    </>

  )
}

export default SubscriptionsTab