import aiej from '../../assets/our-works/AIEJ.png'
import akadi from '../../assets/our-works/Akadi.png'
import ayuli from '../../assets/our-works/Ayuli.png'
import casa from '../../assets/our-works/Casa.png'
import curatingDreams from '../../assets/our-works/Curating_Dreams.png'
import eaaMedia from '../../assets/our-works/EAA_ Media.png'
import jambo from '../../assets/our-works/Jambo.png'
import leadForGhana from '../../assets/our-works/Lead_for_Ghana.png'
import maishaArts from '../../assets/logo/logo_arts.svg'
import maishaDigital from '../../assets/logo/maisha-logo.svg'
import masi from '../../assets/our-works/Masi.png'
import rabito from '../../assets/our-works/Rabito.png'
import rotaryClub from '../../assets/our-works/Rotary_Club.png'
import sbl from '../../assets/our-works/SBL.png'
import schoolMall from '../../assets/our-works/School_Mall.png'
import sproxil from '../../assets/our-works/Sproxil.png'
import theEduColl from '../../assets/our-works/The_Edu._Coll..png'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fecthOurWorks } from "../../redux/reducers/ourWorksSlice"
import { useEffect } from 'react'
const Ourworks = () => {
  const state = useSelector(state => state.ourWorkSlice)
  const dispatchFN = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatchFN(fecthOurWorks())
  }, [])

  return (
    <section>
      <ul className="columns-1 sm:columns-3 lg:columns-5  xl:columns-6 my-8 px-5 lg:px-8 xl:px-0 mx-auto max-w-xl lg:max-w-7xl 2xl:max-w-8xl gap-x-3" >

        {/* <li className="p-0 mb-2"><Link to="event-town" className=""><div className="w-40 h-40 bg-very-light-gray rounded-full flex mx-auto items-center justify-center">Event Town</div></Link></li> */}
        {state.works.map((e, index) => {
          return <li key={index} className="p-0 mb-2  " onClick={
            () => navigate(`${e._id}`, { state: e })
          } >
            <img src={e.imageUrl} alt="jambo icon" className="mx-auto rounded-full  w-48 h-48 hover:scale-105 transition-all duration-200 shadow-md" />
            {/* <Link to={
            {
              pathname: `/our-works/${e._id}`,
              state: e
            }} className=""><img src={e.imageUrl} alt="jambo icon" className="mx-auto rounded-full  w-48 h-48 hover:scale-105 transition-all duration-200" /></Link> */}
          </li>
        })}

        {/* <li className="p-0 mb-2"><Link to="sbl" className=""><img src={sbl} alt="sbl icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="maisha-arts" className=""><img src={maishaArts} alt="maisha art icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="rabito" className=""><img src={rabito} alt="rabito icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="masi" className=""><img src={masi} alt="masi icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="akadi" className=""><img src={akadi} alt="akadi icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="aiej" className=""><img src={aiej} alt="aiej icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="ayuli" className=""><img src={ayuli} alt="ayuli icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="bridge-global" className=""><div className="w-40 h-40 bg-very-light-gray rounded-full flex mx-auto items-center justify-center">Brigdge Global</div></Link></li>
        <li className="p-0 mb-2"><Link to="maisha-digital" className=""><img src={maishaDigital} alt="maisha icon" className="mx-auto m-4" /></Link></li>
        <li className="p-0 mb-2"><Link to="ophelia-swayne" className=""><div className="w-40 h-40 bg-very-light-gray rounded-full flex mx-auto items-center justify-center">Ophelia Swayne</div></Link></li>
        <li className="p-0 mb-2"><Link to="eaa-media" className=""><img src={eaaMedia} alt="e a a Media icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="the-education-collaborative" className=""><img src={theEduColl} alt="the education collaborative icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="curating-dreams" className=""><img src={curatingDreams} alt="curating dreams icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="sproxil" className=""><img src={sproxil} alt="sproxil icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="casa" className=""><img src={casa} alt="casa icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="lead-for-ghana" className=""><img src={leadForGhana} alt="lead for ghana icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="school-mall" className=""><img src={schoolMall} alt="school mall icon" className="mx-auto" /></Link></li>
        <li className="p-0 mb-2"><Link to="rotary-club" className=""><img src={rotaryClub} alt="rotary club icon" className="mx-auto" /></Link></li> */}
        {/* <li className="p-0 mb-2"><Link to="new-eden" className=""><div className="w-40 h-40 bg-very-light-gray rounded-full flex mx-auto items-center justify-center">New Eden</div></Link></li> */}

      </ul>
    </section>

  )
}

export default Ourworks