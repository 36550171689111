import React from 'react'
import AboutTabs from '../../components/Profile/ProfileTabs/AboutTabs'
import Header from '../../components/Profile/Header'

const Profile = () => {
  return (
    <div className="py-3 px-5 2xl:px-0 mx-auto max-w-xl mx-auto lg:max-w-7xl">
      <Header />
      <AboutTabs />
    </div>
  )
}

export default Profile