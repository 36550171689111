import ChannelsTabs from "../../components/Channels/ChannelsTabs/ChannelsTabs"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { fetchMedias } from "../../redux/reducers/channelSlice"
import { useEffect } from "react"

const Channels = () => {
  const state = useSelector(state => state.channelSlice)
  const dispatchFN = useDispatch()


  useEffect(() => {
    dispatchFN(fetchMedias());
  }, []
  )

  return (
    <div className="text-very-dark-gray py-12 px-5 lg:px-8 xl:px-0 mx-auto relative max-w-xl lg:max-w-7xl 2xl:max-w-8xl">
      <section className="flex flex-col items-center lg:items-start justify-center">
        <h1 className="mb-6 text-4xl font-bold">Master Class</h1>
        <div className="flex items-center justify-center flex-wrap lg:gap-x-8">

          {state.masterclass.map((e, index) => {
            if (index !== state.masterclass.lenght - 1) {
              return <article key={index} className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
                <img
                  src={e.channelId.channelThumbnailUrl}
                  alt="channel icon"
                  className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
                />
                <Link to={`masterclass/class?id=${e._id}`} className="font-bold text-center hover:text-gray transition-all">
                  {e.title + ` (${e.modules?.length ?? 0} parts)`}

                </Link>
                <p className="mt-2">{e.channelId.channelName}</p>
              </article>
            }
            return <article key={index} className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
              <img
                src={e.channelId.channelThumbnailUrl}
                alt="channel icon"
                className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
              />
              <Link to={`masterclass/class?id=${e._id}`} className="font-bold text-center hover:text-gray transition-all">
                {e.title + ` (${e.modules.length} ` + e.modules.length > 1 ? "parts)" : "part)"}
              </Link>
              <p className="mt-2">{e.channelId.channelName}</p>
            </article>
          })}
          {/* <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
            <img
              src={channel1}
              alt="channel icon"
              className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
            />
            <Link to="masterclass/class" className="font-bold text-center hover:text-gray transition-all">
              Introduction to Visual Arts (10 parts)
            </Link>
            <p className="mt-2">Bright Ackwerh</p>
          </article>
          <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
            <img
              src={channel2}
              alt="channel icon"
              className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
            />
            <Link to="masterclass/class" className="font-bold text-center hover:text-gray transition-all">
              Advanced Visual Arts training (10 parts)
            </Link>
            <p className="mt-2">Bright Ackwerh</p>
          </article>
          <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
            <img
              src={channel3}
              alt="channel icon"
              className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
            />
            <Link to="masterclass/class" className="font-bold text-center hover:text-gray transition-all">
              Introduction to Digital Arts (10 parts)
            </Link>
            <p className="mt-2">Bright Ackwerh</p>
          </article>
          <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
            <img
              src={channel4}
              alt="channel icon"
              className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
            />
            <Link to="masterclass/class" className="font-bold text-center hover:text-gray transition-all">
              Digital Arts II Finding your style (10 parts)
            </Link>
            <p className="mt-2">Bright Ackwerh</p>
          </article>
          <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
            <img
              src={channel5}
              alt="channel icon"
              className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
            />
            <Link to="masterclass/class" className="font-bold text-center hover:text-gray transition-all">
              Digital Arts III Finding your style (10 parts)
            </Link>
            <p className="mt-2">Bright Ackwerh</p>
          </article> */}
        </div>
      </section>
      <ChannelsTabs />
    </div>
  );
};

export default Channels;
