// import React, { useEffect } from "react";

// import Posts from '../../Media/Posts'
// import { useDispatch, useSelector } from "react-redux";
// import { getAllVideos } from "../../../redux/reducers/mediaSlice";

// const ImagesTab = () => {
//     const dispatch = useDispatch();

//   const { youtubePageTokens, filterBy, sortBy } = useSelector(
//     (state) => state.media
//   );


//   const curPage = localStorage.getItem("curPage")
//   ? localStorage.getItem("curPage")
//   : 1;

// useEffect(() => {
//   // PAGE TOKEN IS BASED ON CURRENT PAGE
//   dispatch(
//     getAllVideos({
//       pageToken: youtubePageTokens[curPage - 1],
//       filterBy,
//       sortBy,
//     })
//   );

//   // eslint-disable-next-line
// }, [curPage]);

//   return (
// <>
// <Posts />
// </>
//     )
// }

// export default ImagesTab

import { useState } from "react";
import maishaLogo from "../../../../assets/logo/maisha-logo.svg";
import brightAckwerh from "../../../../assets/channels/intro_visual_art.png";
import channel1 from "../../../../assets/channels/channel1.png";
import channel2 from "../../../../assets/channels/channel2.png";
import channel3 from "../../../../assets/channels/channel3.png";
import channel4 from "../../../../assets/channels/channel4.png";
import channel5 from "../../../../assets/channels/channel5.png";
import channel6 from "../../../../assets/channels/channel6.png";
import channel7 from "../../../../assets/channels/channel7.png";
import channel8 from "../../../../assets/channels/channel8.png";
import { Link } from "react-router-dom";

const ImagesTab = () => {
  const [channelImgs, setchannelImgs] = useState([channel1, channel2, channel3, channel4, channel5, channel6, channel7, channel8]);
  // const [title, settitle] = useState(["Maisha Arts", "Bright Ackwerh"]);
  return (
    <div className="flex flex-wrap items-center justify-start md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch lg:gap-x-4 mx-auto">
      {/* <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
        <div className="w-48 h-48 lg:w-56 lg:h-56 flex items-center justify-center rounded-lg overflow-hidden border mb-4">
        <img
          src={maishaLogo}
          alt="channel icon"
          className="bg-light-gray w-28 lg:w-32"
        /></div>
        <Link to="channel-about" className="mt-2 hover:text-gray transition-all font-bold">Maisha Arts</Link>
      </article> */}
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      <article className="flex flex-col items-center justify-center w-full mb-7 mx-3 lg:mx-0">
        <img
          src={channel1}
          alt="channel icon"
          className="bg-light-gray w-full h-56 rounded-lg overflow-hidden border mb-4"
        />
        <Link to="/maisha-art/channels/masterclass/class" className="hover:text-gray transition-all font-bold">Introduction to </Link>
        <p className="mt-4">Bright Ackwerh</p>
      </article>
      {/* {
        channelImgs.map(img=>(
          <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
          <img
            src={img}
            alt="channel icon"
            className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 r overflow-hidden border mb-4"
          />
          <Link to="channel-about" className="mt-2 hover:text-gray transition-all font-bold">Channel Name</Link>
        </article>
        ))
      } */}
    </div>
  );
};

export default ImagesTab;
