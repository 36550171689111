import React, { useEffect } from 'react'
import PostDetails from '../../../components/Crowdfunding/CrowdfundingPost/PostDetails'
import PostTabs from '../../../components/Crowdfunding/CrowdfundingPost/PostTabs/PostTabs'

import { useSelector, useDispatch } from 'react-redux'
import { oneCrowdFundingThunk } from '../../../redux/reducers/crowdFunding'
import { useParams } from 'react-router-dom'

const CrowdfundingPost = () => {
  const { title } = useParams()

  const state = useSelector(state => state.crowdFunding);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(oneCrowdFundingThunk(title))
  }, [title])
  console.log(state.crowdFund)
  return (
    <div className="mt-16 px-5 lg:px-8 xl:px-4 mb-8 min-h-[50vh]">

      {state.crowdFund == null || state.loading == true ? <p>Loading</p> : <PostDetails crowdFund={state.crowdFund} />}
      {state.crowdFund == null || state.loading == true ? <p>Loading</p> : <PostTabs crowdFund={state.crowdFund} />}

    </div>
  )
}

export default CrowdfundingPost