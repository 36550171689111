import React from 'react'

const Image = (props) => {
  return (
    <>
      {/* CONTENT SUMMARY */}
      <div className="mb-8">
        <label htmlFor="contentSummary" className="block mb-2">
          Content summary<span className="text-strong-red">*</span>
        </label>
        <input
          id="contentSummary"
          name="contentSummary"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="Summary"
        />
      </div>

      {/* UPLOAD IMAGE */}
      <div className="flex flex-col justify-between gap-x-6">
        <label
          // htmlFor="s-quality"
          className="block mb-4 pb-4 mt-2 font-bold border-b border-gray"
        >
          Upload image<span className="text-strong-red">*</span>
        </label>
        {/* small */}
        <div className="mb-8 w-full">
          <label
            // htmlFor="s-quality"
            className="block mb-2 mt-2 font-medium"
          >
            Option 1: Small
          </label>

          <div className="flex flex-col md:flex-row items-center justify-between w-full">
            <label htmlFor="s-quality" className="sr-only">
              Image quality
            </label>
            <input
              id="s-quality"
              name="small_name"
              type="text"
              className="h-14 p-3 w-full border border-gray md:w-7/12 md:mr-4 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Image quaity eg. (640 x 425)"
            />
            <label htmlFor="s-price" className="sr-only">
              Enter price
            </label>
            <input
              id="s-price"
              name="s_price"
              type="text"
              className="h-14 p-3 w-full border border-gray md:flex-1 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Enter price"
            />
          </div>
          {/* file */}
          <label
            // className="block mb-2 mt-2 font-medium text-gray-900 dark:text-white"
            className="sr-only"
            htmlFor="small"
          >
            choose image
          </label>
          <input
            className="block mt-4 w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
            id="small"
            type="file"
            name="small_quality"
            ref={props.smallResolutionRef}
          />
        </div>

        {/* medium */}
        <div className="mb-8 w-full">
          <label
            // htmlFor="m-quality"
            className="block mb-2 mt-2 font-medium"
          >
            Option 2: Medium
          </label>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <label htmlFor="m-quality" className="sr-only">
              Image quality
            </label>
            <input
              id="m-quality"
              name="m_name"
              type="text"
              className="h-14 p-3 w-full border border-gray md:w-7/12 md:mr-4 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Image quaity eg. (1920 x 1275)"
            />
            <label htmlFor="m-price" className="sr-only">
              Enter price
            </label>
            <input
              id="m-price"
              name="m_price"
              type="text"
              className="h-14 p-3 w-full border border-gray md:flex-1 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Enter price"
            />
          </div>
          {/* file */}
          <label
            // className="block mb-2 mt-2 font-medium text-gray-900 dark:text-white"
            className="sr-only"
            htmlFor="medium"
          >
            choose image
          </label>
          <input
            className="block mt-4 w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
            id="medium"
            type="file"
            ref={props.mediumResolutionRef}
            name="m_quality"
          />
        </div>

        {/* large */}
        <div className="mb-8 w-full">
          <label
            // htmlFor="l-quality"
            className="block mb-2 mt-2 font-medium"
          >
            Option 3: Large
          </label>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <label htmlFor="l-quality" className="sr-only">
              Image quality
            </label>
            <input
              id="l-quality"
              name="l_name"
              type="text"
              className="h-14 p-3 w-full border border-gray md:w-7/12 md:mr-4 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Image quaity eg. (2400 x 1594)"
            />
            <label htmlFor="l-price" className="sr-only">
              Enter price
            </label>
            <input
              id="l-price"
              name="l_price"
              type="text"
              className="h-14 p-3 w-full border border-gray md:flex-1 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Enter price"
            />
          </div>
          {/* file */}
          <label
            // className="block mb-2 mt-2 font-medium text-gray-900 dark:text-white"
            className="sr-only"
            htmlFor="large"
          >
            choose image
          </label>
          <input
            className="block mt-4 w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
            id="large"
            type="file"
            ref={props.largeResolutionRef}
            name="l_quality"
          />
        </div>

        {/* hd */}
        <div className="mb-8 w-full">
          <label
            // htmlFor="h-quality"
            className="block mb-2 mt-2 font-medium"
          >
            Option 4: HD
          </label>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <label htmlFor="h-quality" className="sr-only">
              Image quality
            </label>
            <input
              id="h-quality"
              name="h_name"
              type="text"
              className="h-14 p-3 w-full border border-gray md:w-7/12 md:mr-4 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Image quaity eg. 4k"
            />
            <label htmlFor="h-price" className="sr-only">
              Enter price
            </label>
            <input
              id="h-price"
              name="h_price"
              type="text"
              className="h-14 p-3 w-full border border-gray md:flex-1 placeholder-gray focus:outline-none focus:border-strong-red rounded-md mb-4 md:mb-0"
              placeholder="Enter price"
            />
          </div>
          {/* file */}
          <label
            // className="block mb-2 mt-2 font-medium text-gray-900 dark:text-white"
            className="sr-only"
            htmlFor="hd"
          >
            choose image
          </label>
          <input
            className="block mt-4 w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
            id="hd"
            type="file"
            ref={props.HDResolutionRef}
            name="hd_quality"
          />
        </div>
      </div>

      {/* TAGS */}
      <div className="mb-8">
        <label htmlFor="tags" className="block mb-2">
          Tags
        </label>
        <select
          id="tags"
          name="tags"
          className="form-select form-select-lg !shadow-none bg-clip-padding bg-no-repeat appearance-none px-3 py-2 w-full h-14 border border-gray rounded-md transition ease-in-out text-dark-gray bg-white cursor-pointer focus:text-dark-gray focus:border-strong-red focus:outline-none"
          aria-label="Tags"
        >
          <option defaultValue={""}>Click to select tags</option>
          <option value="music">Music</option>
          <option value="education">Education</option>
          <option value="video">Video</option>
          <option value="visual-arts">Visual arts</option>
          <option value="civic-education">Civic education</option>
          <option value="arts">Arts</option>
          <option value="children">children</option>
          <option value="animated">Animated</option>
          <option value="social-justice">Social justice</option>
        </select>
      </div>
    </>
  )
}

export default Image