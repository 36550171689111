import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fecthRecommendedForYou } from "../../redux/reducers/recommendedSlice";
import ReactTimeago from "react-timeago";
import { Link } from "react-router-dom";

const RecommendedForYou = () => {
  const { loading, error, medias } = useSelector(state => state.recommended)
  console.log(medias)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fecthRecommendedForYou())
  }, [])
  return (
    <section className="max-w-xl mx-auto lg:max-w-7xl">
      <h2 className="text-lg text-center mb-8">Recommended for you</h2>
      <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-evenly mb-8 max-w-6xl mx-auto px-5">
        {loading && Array(4).map((dummy) => {
          <figure className="bg-strong-red flex flex-col h-[155px] xl:h-[180px] w-[300px] xl:w-[336px] object-cover rounded-lg mb-8 lg:mb-0 animate-pulse">

            <figcaption className="flex flex-col text-center">
              <h3 className="w-[100px animate-pulse"></h3>
              <h3 className="w-[100px animate-pulse"></h3>

              <h3 className="w-[100px animate-pulse"></h3>
            </figcaption>
          </figure>
        })}


        {!loading && !error && medias.map((media) =>

          <Link to={`/media/${media._id}`}>
            <figure className="flex flex-col overflow-hidden mb-8 lg:mb-0">
              <img
                src={media.videoThumbnailUrl}
                alt="plantain chips 1.3"
                className="h-[155px] xl:h-[180px] w-[300px] xl:w-[336px] object-cover rounded-lg mb-4"
              />
              <figcaption className="flex flex-col text-center">
                <h3 className="text-base font-bold mb-2"> {media.titleOfContent}</h3>
                <p className="mb-2">
                  <ReactTimeago date={media.postDate} />

                </p>
                <p className="mb-2">
                  by{" "}
                  <span className="text-strong-red font-bold">{media.channelId?.channelName}</span>
                </p>
              </figcaption>
            </figure>
          </Link>
        )}
      </div>
    </section>
  );
};

export default RecommendedForYou;
