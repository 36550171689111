import React, { useState } from 'react';
import { createPortal } from "react-dom"

const doc = document.getElementById("flash-msg")
const FlashMessage = (props) => {
    const display = useState(false);

    return (
        <div className='w-screen bg-zinc-200'>

        </div>
    )
}

export default createPortal(FlashMessage, doc)