import React from "react";

const EditChannel = () => {
  return (
    <div
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="editChannelModal"
      tabIndex="-1"
      aria-labelledby="editChannelModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog relative w-full px-4 !mx-auto max-w-xl lg:max-w-4xl pointer-events-none">
        <form className="modal-content p-6 lg:p-12 border-none shadow-lg relative flex flex-col w-fit my-6 mx-auto sm:w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="modalheader flex flex-shrink-0 items-center justify-between mb-6 border-b border-gray-200 rounded-t-md">
            <h5
              className="text-xl font-medium leading-normal text-gray-800"
              id="editChannelModalLabel"
            >
              Edit Channel
            </h5>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <ion-icon name="close" class="text-xl"></ion-icon>
            </button>
          </div>
          <div className="modal-body relative">
            {/* BANNER */}
            <div className="banner">
              <div className="w-full h-[30vh] flex items-center justify-center flex-col border border-gray text-dark-gray rounded-md">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.825 10.6248C9.75249 10.4422 9.73396 10.2426 9.77164 10.0498C9.80931 9.85703 9.90159 9.67911 10.0375 9.53727L15.2875 4.28727C15.4771 4.09948 15.7332 3.99414 16 3.99414C16.2669 3.99414 16.5229 4.09948 16.7125 4.28727L21.9625 9.53727C22.0984 9.67911 22.1907 9.85703 22.2284 10.0498C22.266 10.2426 22.2475 10.4422 22.175 10.6248C22.1004 10.8091 21.9726 10.967 21.8078 11.0784C21.6431 11.1897 21.4489 11.2494 21.25 11.2498H17V18.9998C17 19.265 16.8947 19.5193 16.7071 19.7069C16.5196 19.8944 16.2652 19.9998 16 19.9998C15.7348 19.9998 15.4804 19.8944 15.2929 19.7069C15.1054 19.5193 15 19.265 15 18.9998V11.2498H10.75C10.5512 11.2494 10.3569 11.1897 10.1922 11.0784C10.0274 10.967 9.89957 10.8091 9.825 10.6248ZM27 17.9998C26.7348 17.9998 26.4804 18.1051 26.2929 18.2927C26.1054 18.4802 26 18.7345 26 18.9998V25.9998H6V18.9998C6 18.7345 5.89464 18.4802 5.70711 18.2927C5.51957 18.1051 5.26522 17.9998 5 17.9998C4.73478 17.9998 4.48043 18.1051 4.29289 18.2927C4.10536 18.4802 4 18.7345 4 18.9998V25.9998C4 26.5302 4.21071 27.0389 4.58579 27.414C4.96086 27.7891 5.46957 27.9998 6 27.9998H26C26.5304 27.9998 27.0392 27.7891 27.4142 27.414C27.7893 27.0389 28 26.5302 28 25.9998V18.9998C28 18.7345 27.8947 18.4802 27.7071 18.2927C27.5196 18.1051 27.2652 17.9998 27 17.9998Z"
                    fill="#A4A4A4"
                  />
                </svg>

                <div className="">
                  <label
                    htmlFor="bannerUpload"
                    className="m-auto text-strong-red underline"
                  >
                    Upload banner
                  </label>
                  <input
                    type="file"
                    name="banner-upload"
                    className="hidden cursor-pointer"
                    id="bannerUpload"
                  />
                </div>
              </div>
            </div>

            {/* CHANNEL */}
            <div className="channel mt-8">
              <div className="flex items-start justify-center flex-wrap lg:gap-x-8">
                <div className="w-full lg:w-1/4 h-[25vh] flex items-center justify-center flex-col border border-gray text-dark-gray rounded-md">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.825 10.6248C9.75249 10.4422 9.73396 10.2426 9.77164 10.0498C9.80931 9.85703 9.90159 9.67911 10.0375 9.53727L15.2875 4.28727C15.4771 4.09948 15.7332 3.99414 16 3.99414C16.2669 3.99414 16.5229 4.09948 16.7125 4.28727L21.9625 9.53727C22.0984 9.67911 22.1907 9.85703 22.2284 10.0498C22.266 10.2426 22.2475 10.4422 22.175 10.6248C22.1004 10.8091 21.9726 10.967 21.8078 11.0784C21.6431 11.1897 21.4489 11.2494 21.25 11.2498H17V18.9998C17 19.265 16.8947 19.5193 16.7071 19.7069C16.5196 19.8944 16.2652 19.9998 16 19.9998C15.7348 19.9998 15.4804 19.8944 15.2929 19.7069C15.1054 19.5193 15 19.265 15 18.9998V11.2498H10.75C10.5512 11.2494 10.3569 11.1897 10.1922 11.0784C10.0274 10.967 9.89957 10.8091 9.825 10.6248ZM27 17.9998C26.7348 17.9998 26.4804 18.1051 26.2929 18.2927C26.1054 18.4802 26 18.7345 26 18.9998V25.9998H6V18.9998C6 18.7345 5.89464 18.4802 5.70711 18.2927C5.51957 18.1051 5.26522 17.9998 5 17.9998C4.73478 17.9998 4.48043 18.1051 4.29289 18.2927C4.10536 18.4802 4 18.7345 4 18.9998V25.9998C4 26.5302 4.21071 27.0389 4.58579 27.414C4.96086 27.7891 5.46957 27.9998 6 27.9998H26C26.5304 27.9998 27.0392 27.7891 27.4142 27.414C27.7893 27.0389 28 26.5302 28 25.9998V18.9998C28 18.7345 27.8947 18.4802 27.7071 18.2927C27.5196 18.1051 27.2652 17.9998 27 17.9998Z"
                    fill="#A4A4A4"
                  />
                </svg>
                  <div className="">
                    <label
                      htmlFor="channelImgUpload"
                      className="m-auto text-strong-red underline"
                    >
                      Upload channel image
                    </label>
                    <input
                      type="file"
                      name="channel-img-upload"
                      className="hidden cursor-pointer"
                      id="channelImgUpload"
                    />
                  </div>
                </div>
                <div className="w-full mt-8 lg:mt-0 lg:flex-1 flex items-start justify-center flex-col text-dark-gray mb-6">
                  <label
                    htmlFor="channelName"
                    className="font-bold text-xl mb-4"
                  >
                    Channel name
                  </label>
                  <input
                    type="text"
                    className="h-12 py-4 mb-6 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                    placeholder="Channel name"
                    id="channelName"
                  />
                  <label
                    htmlFor="channelDescription"
                    className="font-bold text-xl mb-4"
                  >
                    Channel description
                  </label>
                  <input
                    type="text"
                    className="h-12 py-4 mb-6 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                    placeholder="Channel description"
                    id="channelDescription"
                  />
                </div>
              </div>
            </div>

            {/* SOCIALS */}
            <div className="socials mt-4 lg:mt-8 grid grid-cols-1 lg:grid-cols-2 flex-wrap justify-between lg:gap-x-6">
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="facebook" className="mr-3">
                  <i className="text-2xl fab fa-facebook"></i>
                  <span className="sr-only">Facebook link</span>
                </label>
                <input
                  type="text"
                  name="facebook"
                  id="facebook"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Facebook link"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="instagram" className="mr-3">
                  <i className="text-2xl fab fa-instagram"></i>
                  <span className="sr-only">Instagram link</span>
                </label>
                <input
                  type="text"
                  name="instagram"
                  id="instagram"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Instagram link"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="twitter" className="mr-3">
                  <i className="text-2xl fab fa-twitter"></i>
                  <span className="sr-only">Twitter link</span>
                </label>
                <input
                  type="text"
                  name="twitter"
                  id="twitter"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Twitter link"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="youtube" className="mr-3">
                  <i className="text-2xl fab fa-youtube"></i>
                  <span className="sr-only">Youtube link</span>
                </label>
                <input
                  type="text"
                  name="youtube"
                  id="youtube"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Youtube link"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="channelEmail" className="mr-3">
                  <i className="text-2xl fas fa-envelope"></i>
                  <span className="sr-only">email link</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="channelEmail"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Email"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="phone" className="mr-3">
                  <i className="text-2xl fas fa-phone-alt"></i>
                  <span className="sr-only">Phone</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Phone number"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="whatsapp" className="mr-3">
                  <i className="text-2xl fab fa-whatsapp"></i>
                  <span className="sr-only">whatsapp link</span>
                </label>
                <input
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Whatsapp link"
                />
              </div>
              <div className="flex items-center justify-center w-full mb-6">
                <label htmlFor="website" className="mr-3">
                  <i className="text-2xl fas fa-globe-africa"></i>
                  <span className="sr-only">Official website</span>
                </label>
                <input
                  type="text"
                  name="website"
                  id="website"
                  className="h-12 py-4 px-6 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                  placeholder="Website link"
                />
              </div>
            </div>

            {/* DESCRIPTION */}
            <div className="description">
              <div className="w-full h-[30vh] py-3 px-4 flex flex-col border border-gray text-dark-gray rounded-md">
                <div className="buttons flex">
                  <div className="mx-1" aria-label="bold text">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 4H12.5C13.381 4.00004 14.2425 4.25865 14.9779 4.74378C15.7132 5.2289 16.29 5.9192 16.6367 6.72907C16.9834 7.53894 17.0847 8.43276 16.9282 9.29969C16.7716 10.1666 16.3641 10.9685 15.756 11.606C16.4386 12.0013 17.0053 12.5692 17.3992 13.2526C17.7931 13.9361 18.0003 14.7112 18 15.5C18 16.0909 17.8836 16.6761 17.6575 17.2221C17.4313 17.768 17.0998 18.2641 16.682 18.682C16.2641 19.0998 15.768 19.4313 15.2221 19.6575C14.6761 19.8836 14.0909 20 13.5 20H6V18H7V6H6V4ZM9 11H12.5C13.163 11 13.7989 10.7366 14.2678 10.2678C14.7366 9.79893 15 9.16304 15 8.5C15 7.83696 14.7366 7.20107 14.2678 6.73223C13.7989 6.26339 13.163 6 12.5 6H9V11ZM9 13V18H13.5C14.163 18 14.7989 17.7366 15.2678 17.2678C15.7366 16.7989 16 16.163 16 15.5C16 14.837 15.7366 14.2011 15.2678 13.7322C14.7989 13.2634 14.163 13 13.5 13H9Z"
                        fill="#414142"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="1.5"
                        stroke="#A4A4A4"
                      />
                    </svg>
                  </div>
                  <div className="mx-1" aria-label="ordered list">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.983 19H4V18H5.989V17.5H4.995V16.5H5.99V16H4V15H6.983V19ZM21 18H9.069V16H21V18ZM6.983 14H4V13.1L5.79 11H4V10H6.983V10.9L5.193 13H6.983V14ZM21 13H9.069V11H21V13ZM6.486 9H5.491V6H4.5V5H6.486V9ZM21 8H9.069V6H21V8Z"
                        fill="#414142"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="1.5"
                        stroke="#A4A4A4"
                      />
                    </svg>
                  </div>
                  <div className="mx-1" aria-label="unordered list">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 18H8V16H20V18ZM6 18H4V16H6V18ZM20 13H8V11H20V13ZM6 13H4V11H6V13ZM20 8H8.023V6H20V8ZM6 8H4V6H6V8Z"
                        fill="#414142"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="1.5"
                        stroke="#A4A4A4"
                      />
                    </svg>
                  </div>
                </div>
                <hr className="w-full lg:w-60 my-2 text-gray" />
                <div className="relative h-full">
                  <label htmlFor="addDescription" className="m-auto sr-only">
                    Add description
                  </label>
                  <input
                    type="text"
                    name="add-description"
                    className="absolute top-0 w-full h-full cursor-pointer py-4 px-6"
                    id="addDescription"
                    placeholder="Add description"
                  />
                </div>
              </div>
            </div>

            {/* SUBMIT BUTTON */}
            <button type="submit" className="bg-strong-red px-6 py-2 text-white border border-strong-red font-bold rounded-md mb-3 w-full lg:w-fit mt-8 lg:ml-auto">Submit Changes</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditChannel;
