import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
export const crowdFundingThunk = createAsyncThunk("crowd", async () => {
    console.log("Started")
    const crowdFundings = await fetch(baseUrl + "/media/all?mediaType=crowdfunding")
    const toReturn = (await crowdFundings.json()).media

    return toReturn;
})
export const oneCrowdFundingThunk = createAsyncThunk("crowd/Single", async (id, thunkApi) => {
    console.log("Fetching")
    const crowdFundings = await fetch(baseUrl + `/media/crowdfunding/${id}`)
    const toReturn = (await crowdFundings.json()).media

    return toReturn;
})

const crowdFunding = createSlice({
    name: "crowdFunding/Slice",
    initialState: {
        fundings: [],
        loading: false,
        error: false,
        crowdFund: null

    },
    extraReducers: builder => {
        builder.addCase(crowdFundingThunk.fulfilled, (state, action) => {

            state.fundings = [...action.payload]
            console.log(state.fundings[0])
        })
        builder.addCase(crowdFundingThunk.pending, (state, action) => {

        })
        builder.addCase(crowdFundingThunk.rejected, (state, action) => {

        })
        builder.addCase(oneCrowdFundingThunk.fulfilled, (state, action) => {
            state.crowdFund = action.payload
            state.loading = false;
        })
        builder.addCase(oneCrowdFundingThunk.pending, (state, action) => {
            state.loading = true;
        })
    }
})

export default crowdFunding.reducer