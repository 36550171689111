import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchInput } from "../../../redux/reducers/wikiSlice";
import { useForm } from "react-hook-form";
import {
  useNavigate,
  createSearchParams,
  useSearchParams
} from "react-router-dom";

const Search = () => {
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.wiki);
  const { register, getValues, handleSubmit } = useForm({
    mode: "onChange",
  });

  const navigate = useNavigate();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q");

  useEffect(() => {
    // GET QUERY TEXT IF SEARCHTEXT IS EMPTY
    dispatch(searchInput(searchText || query.replaceAll("%20", " ")));

    // eslint-disable-next-line
  }, []);

  const submitForm = (data) => {
    navigate({
      pathname: "/wiki/search",
      search: createSearchParams({
        q: `${data.search.toLowerCase().replaceAll(" ", "-")}`,
      }).toString(),
    });
    window.location.reload()
  };

  return (
    <div className="max-w-xl lg:max-w-7xl 2xl:max-w-8xl mx-auto relative flex flex-col lg:flex-row py-4 items-center justify-start mt-12 mb-8">
      <h1 className="font-bold text-strong-red text-xl sm:text-2xl mt-4 lg:mt-0 mx-auto lg:mx-0 lg:mr-8 mb-4 lg:mb-0 text-center transition-all ">
        Search Maisha Digital
      </h1>
      <form
        onSubmit={handleSubmit(submitForm)}
        onChange={() => {
          const value = getValues("search");
          dispatch(searchInput(value));
        }}
        className="flex items-center w-full max-w-xl relative border border-gray rounded-md"
      >
        <input
          type="text"
          name="search"
          id="search"
          autoComplete="off"
          value={searchText}
          className="focus:outline-none w-full pl-6 h-14 rounded-md"
          placeholder="Enter key words"
          {...register("search", { required: true })}
        />
        <button type="submit" className="mr-3 pt-1">
          <span className="sr-only">search</span>
          <ion-icon
            name="search"
            role="img"
            className="md hydrated text-xl"
            aria-label="search"
          ></ion-icon>
        </button>
      </form>
    </div>
  );
};

export default Search;
