import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';


export default function Alerts(props) {

    return <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
        // onClick={() => props.close()}
        >
            {props.children}
        </Backdrop>
    </div>

}




