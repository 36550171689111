import React, { useEffect } from "react";

import Posts from '../../Media/Posts'
import { useDispatch, useSelector } from "react-redux";
import { getAllVideos } from "../../../redux/reducers/mediaSlice";

const ImagesTab = () => {
  const dispatch = useDispatch();

  const { youtubePageTokens, filterBy, sortBy } = useSelector(
    (state) => state.media
  );


  const curPage = localStorage.getItem("curPage")
    ? localStorage.getItem("curPage")
    : 1;

  useEffect(() => {
    // PAGE TOKEN IS BASED ON CURRENT PAGE
    dispatch(
      getAllVideos()
    );

    // eslint-disable-next-line
  }, [curPage]);

  return (
    <>
      <Posts />
    </>
  )
}

export default ImagesTab