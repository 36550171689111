import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../config";
import axios from "axios";
import { getCountry } from "../../utils/location_detector";

const signIn = createAsyncThunk("authSlice/SignIn", async ({ email, password }) => {

    const url = baseUrl + "/user/login";
    try {
        const response = await axios.post(url, { "email": email, "password": password }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })

        localStorage.setItem("token", response.data.user.token)
        return response.data;
    } catch (err) {
        console.log(err)
        throw new Error(err.response)
    }



},)

export const signUp = createAsyncThunk("authSlice/signUp", async ({ fullname, email, password }, thunkAPI) => {
    const url = baseUrl + "/user/register";
    try {
        const response = await axios.post(url, JSON.stringify({
            "fullname": fullname, "email": email, "country": getCountry(),
            "password": password
        }), {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response)
    }
})


const AuthSlice = createSlice(
    {
        name: "Auth Slice",
        initialState: {
            initial: true,
            user: null,
            mode: "login",
            loading: false,
            error: false,
            sucess: false,
            errorMsg: "An Error Occured! Please try again"
        },
        reducers: {
            logout(state) {
                state.initial = true
                state.mode = "login"
                state.loading = false
                state.error = false
                state.errorMsg = "An Error Occured! Please try again"
                state.user = null;
            }
        },
        extraReducers: builder => {
            builder.addCase(signIn.pending, (state) => {
                state.loading = true
                state.initial = false;

            })
            builder.addCase(signIn.rejected, (state) => {
                state.loading = false;
                state.error = true
                console.log("Error")

            })
            builder.addCase(signIn.fulfilled, (state, action) => {
                console.log(action.payload)
                if (action.payload.status === "error") {
                    state.error = true;
                    state.loading = false;
                    state.errorMsg = action.payload.message
                } else {
                    state.user = action.payload.user
                    state.loading = false;
                    state.error = false;
                }
            })
            builder.addCase(signUp.pending, (state) => {
                state.mode = "signup";
                state.initial = true;
                state.loading = true;
                state.user = null;
                state.error = false;
            })
            builder.addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.sucess = true;
                state.error = false
                console.log(action.payload)
            })
            builder.addCase(signUp.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorMsg = action.payload.message


            })
        },

    }
);
export const signInAction = signIn;
export const AuthActions = AuthSlice.actions

export default AuthSlice.reducer