import React from "react";
import { Link } from "react-router-dom";
import Timeago from "react-timeago"

const SupportersTab = ({ contributors }) => {
  return (
    <>

      <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:items-stretch gap-x-6">
        {/* SUPPORTERS */}
        {contributors
          .map((item, index) => (
            <div key={index} className="flex items-center justify-center mb-8">
              <div
                className="w-20 h-20 bg-gray rounded-full mr-4"
                aria-label="user-profile-img"
              ></div>
              <div className="user-info">
                <span className="font-bold">{item.contributor.fullName} </span>
                <span className="">
                  <Timeago date={item.timeContributed} />
                </span>
                <div className="block mt-2">${item.amountContributed}</div>
              </div>
            </div>
          ))}
      </div>
      {/* <div className="pagination flex items-center justify-center my-6">
        <span className="font-bold">Page</span>
        <nav
          className="relative flex items-center font-bold"
          aria-label="pagination"
        >
          <Link
            to=""
            className="relative inline-flex items-center p-4 text-sm font-medium text-gray hover:text-dark-gray"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              x-description="Heroicon name: solid/chevron-left"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
          <div className="flex">
            <Link
              to=""
              className="p-4 inline-flex items-center text-sm text-gray hover:text-dark-gray"
            >
              1
            </Link>
            <Link
              to=""
              className="p-4 inline-flex items-center text-sm text-gray hover:text-dark-gray"
            >
              2
            </Link>
            <Link
              to=""
              className="p-4 inline-flex items-center text-sm text-gray hover:text-dark-gray"
            >
              3
            </Link>
          </div>
          <Link
            to=""
            className="relative inline-flex items-center p-4 text-sm font-medium text-gray hover:text-dark-gray"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              x-description="Heroicon name: solid/chevron-right"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </nav>
      </div> */}
    </>
  );
};

export default SupportersTab;
