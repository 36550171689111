import React from "react";
import AboutTabs from "../../components/Channels/ChannelAboutUser/ChannelAboutTabs/AboutTabs";
// import AboutTabs from "../../components/About/AboutTabs/AboutTabs";
import Header from "../../components/Channels/ChannelAboutUser/Header";

const ChannelAbout = () => {
  return (
    <div className="py-3 px-5 2xl:px-0 mx-auto max-w-xl mx-auto lg:max-w-7xl">
      <Header />
      <AboutTabs />
    </div>
  );
};

export default ChannelAbout;
