import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVideoUpload } from "../../../../../redux/reducers/AboutSlice";

const Video = (props) => {
  const { videoUpload } = useSelector((state) => state.about);
  const dispatch = useDispatch();

  return (
    <>
      {/* UPLOAD VIDEO */}
      <div className="mb-8">
        <label className="block mb-2">
          Upload video<span className="text-strong-red">*</span>
        </label>
        <fieldset className="flex">
          <legend className="sr-only">upload video</legend>
          <div className="flex items-center mr-4">
            <input
              onClick={(e) => {

                dispatch(setVideoUpload(e.target.value))
              }}
              defaultChecked
              id="upload-video"
              type="radio"
              value="storage"
              name="upload_video_group"
              className="w-4 h-4 text-strong-red accent-strong-red bg- border-gray focus:ring-none focus:outline-none"
            // className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="upload-video"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Upload for storage
            </label>
          </div>
          <div className="flex items-center mr-4">
            <input
              onClick={(e) => {

                dispatch(setVideoUpload(e.target.value))
              }}
              id="embed-url"
              type="radio"
              value="url"
              name="upload_video_group"
              className="w-4 h-4 text-strong-red accent-strong-red bg- border-gray focus:ring-none focus:outline-none"
            />
            <label
              htmlFor="embed-url"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Embed URL
            </label>
          </div>
        </fieldset>
      </div>

      {/* FILES */}
      {videoUpload === "storage" && (
        <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-x-6">
          {/* THUMBNAIL */}
          <div className="mb-8">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 text-black"
              htmlFor="thumbnail"
            >
              Thumbnail
            </label>
            <input
              name="thumbnail"
              className="block w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
              id="thumbnail"
              type="file"
              ref={props.thumbnailRef}
            />
          </div>

          {/* VIDEO */}
          <div className="mb-8">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 text-black"
              htmlFor="video"
            >
              Video file<span className="text-strong-red">*</span>
            </label>
            <input
              className="block w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
              id="video"
              type="file"
              name="video_file"
              ref={props.videoFileRef}
            />
          </div>
        </div>
      )}

      {/* MEDIA URL */}
      {videoUpload === "url" && (
        <div className="mb-8">
          <label htmlFor="media-url" className="block mb-2">
            Embed media URl<span className="text-strong-red">*</span>
          </label>
          <input
            id="media-url"
            name="media_url"
            type="text"
            className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
            placeholder="Media link"
          />
        </div>
      )}

      {/* TAGS */}
      <div className="mb-8">
        <label htmlFor="tags" className="block mb-2">
          Tags
        </label>
        <select
          id="tags"
          name="tags"
          className="form-select form-select-lg !shadow-none bg-clip-padding bg-no-repeat appearance-none px-3 py-2 w-full h-14 border border-gray rounded-md transition ease-in-out text-dark-gray bg-white cursor-pointer focus:text-dark-gray focus:border-strong-red focus:outline-none"
          aria-label="Tags"
        >
          <option defaultValue={""}>Click to select tags</option>
          <option value="music">Music</option>
          <option value="education">Education</option>
          <option value="video">Video</option>
          <option value="visual-arts">Visual arts</option>
          <option value="civic-education">Civic education</option>
          <option value="arts">Arts</option>
          <option value="children">children</option>
          <option value="animated">Animated</option>
          <option value="social-justice">Social justice</option>
        </select>
      </div>

      {/* FORUM CATEGORY */}
      <div className="mb-8">
        <label htmlFor="forumCategory" className="block mb-2">
          Forum Category<span className="text-strong-red">*</span>
        </label>
        <select
          id="forumCategory"
          name="forumCategory"
          className="form-select form-select-lg !shadow-none bg-clip-padding bg-no-repeat appearance-none px-3 py-2 w-full h-14 border border-gray rounded-md transition ease-in-out text-dark-gray bg-white cursor-pointer focus:text-dark-gray focus:border-strong-red focus:outline-none"
          aria-label="Tags"
        >
          <option defaultValue={""}>Choose category</option>
          <option value="image">Image</option>
          <option value="crowdfunding">Crowdfunding</option>
          <option value="master-class">Master Class</option>
        </select>
        <small className="text-gray">
          Leaving this section blank turns comments off.
        </small>
      </div>

      {/* CONTENT BODY */}
      <div className="content-body mb-8">
        <label htmlFor="contentBody" className="mb-2">
          Content body
        </label>
        <div className="w-full h-[30vh] py-3 flex flex-col border border-gray text-dark-gray rounded-md">
          <div className="buttons flex border-b border-gray p-3">
            <div className="mx-1" aria-label="bold text">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 4H12.5C13.381 4.00004 14.2425 4.25865 14.9779 4.74378C15.7132 5.2289 16.29 5.9192 16.6367 6.72907C16.9834 7.53894 17.0847 8.43276 16.9282 9.29969C16.7716 10.1666 16.3641 10.9685 15.756 11.606C16.4386 12.0013 17.0053 12.5692 17.3992 13.2526C17.7931 13.9361 18.0003 14.7112 18 15.5C18 16.0909 17.8836 16.6761 17.6575 17.2221C17.4313 17.768 17.0998 18.2641 16.682 18.682C16.2641 19.0998 15.768 19.4313 15.2221 19.6575C14.6761 19.8836 14.0909 20 13.5 20H6V18H7V6H6V4ZM9 11H12.5C13.163 11 13.7989 10.7366 14.2678 10.2678C14.7366 9.79893 15 9.16304 15 8.5C15 7.83696 14.7366 7.20107 14.2678 6.73223C13.7989 6.26339 13.163 6 12.5 6H9V11ZM9 13V18H13.5C14.163 18 14.7989 17.7366 15.2678 17.2678C15.7366 16.7989 16 16.163 16 15.5C16 14.837 15.7366 14.2011 15.2678 13.7322C14.7989 13.2634 14.163 13 13.5 13H9Z"
                  fill="#414142"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="1.5"
                  stroke="#A4A4A4"
                />
              </svg>
            </div>
            <div className="mx-1" aria-label="ordered list">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.983 19H4V18H5.989V17.5H4.995V16.5H5.99V16H4V15H6.983V19ZM21 18H9.069V16H21V18ZM6.983 14H4V13.1L5.79 11H4V10H6.983V10.9L5.193 13H6.983V14ZM21 13H9.069V11H21V13ZM6.486 9H5.491V6H4.5V5H6.486V9ZM21 8H9.069V6H21V8Z"
                  fill="#414142"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="1.5"
                  stroke="#A4A4A4"
                />
              </svg>
            </div>
            <div className="mx-1" aria-label="unordered list">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 18H8V16H20V18ZM6 18H4V16H6V18ZM20 13H8V11H20V13ZM6 13H4V11H6V13ZM20 8H8.023V6H20V8ZM6 8H4V6H6V8Z"
                  fill="#414142"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="1.5"
                  stroke="#A4A4A4"
                />
              </svg>
            </div>
            <div className="mx-1" aria-label="links">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25 12H15.75"
                  stroke="#414142"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.125 15.75H7C6.00544 15.75 5.05161 15.3549 4.34835 14.6517C3.64509 13.9484 3.25 12.9946 3.25 12C3.25 11.0054 3.64509 10.0516 4.34835 9.34835C5.05161 8.64509 6.00544 8.25 7 8.25H10.125"
                  stroke="#414142"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.875 15.75H17C17.9946 15.75 18.9484 15.3549 19.6517 14.6517C20.3549 13.9484 20.75 12.9946 20.75 12C20.75 11.0054 20.3549 10.0516 19.6517 9.34835C18.9484 8.64509 17.9946 8.25 17 8.25H13.875"
                  stroke="#414142"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="1.5"
                  stroke="#A4A4A4"
                />
              </svg>
            </div>
          </div>
          {/* <hr className="w-full lg:w-60 my-2 text-gray" /> */}
          <div className="relative h-full px-3">
            <textarea
              name="contentBody"
              className=" w-full h-full cursor-pointer focus:outline-none py-4"
              id="contentBody"
              placeholder="Add content"
            // cols="30"
            // rows="10"
            ></textarea>
            {/* <input
              type="text"
              name="content-body"
              className=" w-full h-full cursor-pointer py-4 px-6"
              id="contentBody"
              placeholder="Add content"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
