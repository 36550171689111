import React from "react";
import ReactPlayer from 'react-player';

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const VideoDetails = (props) => {
  const dispatch = useDispatch();
  let params = useParams();
  const { selectedVideo } = useSelector((state) => state.media);


  const opts = {
    width: "100%",
    borderRadius: "2rem",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const videoReady = (event) => {
    event.target.pauseVideo();
  };
  const { contentFileUrl, titleOfContent, likes, comments, postDate, views, favourite, description } = props.selected
  return (
    <>

      {/* <video src={props.selected.contentFileUrl} width="640" height="480" controls autoPlay /> */}
      <ReactPlayer url={contentFileUrl} controls />


      {props.selected && (

        <div className="mt-4">
          <h1 className="font-bold text-xl">
            {titleOfContent}
            {/* {curVideo.items[0].snippet.title
              .replace(/&quot;/g, '"')
              .replace(/&#39;/g, "'")} */}
          </h1>
          <div className="mt-4 flex items-center justify-between max-w-lg">
            <p className="published-at pr-4 sm:pr-0">
              Published:{" "}
              {new Date(postDate)
                .toDateString()
                .replaceAll(" ", "-")}{" "}
              by{" "}
              <span className="font-bold text-strong-red">
                {selectedVideo.channelId.channelName}
              </span>
            </p>
            <div className="view-count flex items-center w-36 text-xs lg:text-base">
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1614 8.05311C13.1614 9.79911 11.7454 11.2141 9.99938 11.2141C8.25338 11.2141 6.83838 9.79911 6.83838 8.05311C6.83838 6.30611 8.25338 4.89111 9.99938 4.89111C11.7454 4.89111 13.1614 6.30611 13.1614 8.05311Z"
                  stroke="#414142"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.998 15.355C13.806 15.355 17.289 12.617 19.25 8.05298C17.289 3.48898 13.806 0.750977 9.998 0.750977H10.002C6.194 0.750977 2.711 3.48898 0.75 8.05298C2.711 12.617 6.194 15.355 10.002 15.355H9.998Z"
                  stroke="#414142"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-2">
                {/* {curVideo.items[0].statistics.viewCount} Views */}
                {views + " Views"}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between mt-8 lg:mt-0">
            <div className="banner flex items-center justify-start w-full">
              <div className="md:w-16 w-10 md:h-16 h-10 rounded-full overflow-clip">
                <img
                  src={selectedVideo.channelId.channelThumbnailUrl}
                  alt="maisha digital youtube logo"
                  className="w-full"
                />
              </div>

              <h2 className="font-medium text-xl lg:text-2xl ml-3">
                {selectedVideo.channelId.channelName}
              </h2>
            </div>
            <div className="statistics mt-4 mb-6 flex items-center justify-between w-full max-w-sm md:max-w-[10rem]">
              {likes !== 0 && (
                <div className="likes-count flex items-center ">
                  <svg
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                    className="style-scope yt-icon block w-6 h-6"
                  // style="pointer-events: none; display: block; width: 100%; height: 100%;"
                  >
                    <g className="style-scope yt-icon">
                      <path
                        d="M18.77,11h-4.23l1.52-4.94C16.38,5.03,15.54,4,14.38,4c-0.58,0-1.14,0.24-1.52,0.65L7,11H3v10h4h1h9.43 c1.06,0,1.98-0.67,2.19-1.61l1.34-6C21.23,12.15,20.18,11,18.77,11z M7,20H4v-8h3V20z M19.98,13.17l-1.34,6 C18.54,19.65,18.03,20,17.43,20H8v-8.61l5.6-6.06C13.79,5.12,14.08,5,14.38,5c0.26,0,0.5,0.11,0.63,0.3 c0.07,0.1,0.15,0.26,0.09,0.47l-1.52,4.94L13.18,12h1.35h4.23c0.41,0,0.8,0.17,1.03,0.46C19.92,12.61,20.05,12.86,19.98,13.17z"
                        className="style-scope yt-icon"
                      ></path>
                    </g>
                  </svg>
                  <span className="ml-2">
                    {likes}
                  </span>
                </div>
              )}
              <div className="comments-count flex items-center">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.0714 18.0699C15.0152 21.1263 10.4898 21.7867 6.78642 20.074C6.23971 19.8539 5.79148 19.676 5.36537 19.676C4.17849 19.683 2.70117 20.8339 1.93336 20.067C1.16555 19.2991 2.31726 17.8206 2.31726 16.6266C2.31726 16.2004 2.14642 15.7602 1.92632 15.2124C0.212831 11.5096 0.874109 6.98269 3.93026 3.92721C7.8316 0.0244319 14.17 0.0244322 18.0714 3.9262C21.9797 7.83501 21.9727 14.1681 18.0714 18.0699Z"
                    stroke="#414142"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.9393 11.4131H14.9483"
                    stroke="#414142"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.9306 11.4131H10.9396"
                    stroke="#414142"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.92128 11.4131H6.93028"
                    stroke="#414142"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">
                  {/* {curVideo.items[0].statistics.commentCount !== "0" ? (
                    <>{curVideo.items[0].statistics.commentCount}</>
                  ) : (
                    <>-</>
                  )}{" "} */}
                  "Comments"
                </span>
              </div>
              <div className="favorite-count flex items-center">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9184 12.32C15.6594 12.571 15.5404 12.934 15.5994 13.29L16.4884 18.21C16.5634 18.627 16.3874 19.049 16.0384 19.29C15.6964 19.54 15.2414 19.57 14.8684 19.37L10.4394 17.06C10.2854 16.978 10.1144 16.934 9.93939 16.929H9.66839C9.57439 16.943 9.48239 16.973 9.39839 17.019L4.96839 19.34C4.74939 19.45 4.50139 19.489 4.25839 19.45C3.66639 19.338 3.27139 18.774 3.36839 18.179L4.25839 13.259C4.31739 12.9 4.19839 12.535 3.93939 12.28L0.328388 8.78C0.0263875 8.487 -0.0786125 8.047 0.0593875 7.65C0.193388 7.254 0.535388 6.965 0.948388 6.9L5.91839 6.179C6.29639 6.14 6.62839 5.91 6.79839 5.57L8.98839 1.08C9.04039 0.98 9.10739 0.888 9.18839 0.81L9.27839 0.74C9.32539 0.688 9.37939 0.645 9.43939 0.61L9.54839 0.57L9.71839 0.5H10.1394C10.5154 0.539 10.8464 0.764 11.0194 1.1L13.2384 5.57C13.3984 5.897 13.7094 6.124 14.0684 6.179L19.0384 6.9C19.4584 6.96 19.8094 7.25 19.9484 7.65C20.0794 8.051 19.9664 8.491 19.6584 8.78L15.9184 12.32Z"
                    fill="#9B1C28"
                  />
                </svg>
                <span className="ml-2">
                  {favourite !== 0 ? (
                    <>{favourite}</>
                  ) : (
                    <>-</>
                  )}
                </span>
              </div>
            </div>
          </div>
          {description && (
            <div className="mt-6">
              <h2 className="font-bold text-2xl mb-2">
                {/* {curVideo.items[0].snippet.channelTitle} */}
                "Channel Title 3"
              </h2>
              <time dateTime={postDate}>
                {new Date(postDate)
                  .toDateString()
                  .replaceAll(" ", "-")}
              </time>
              <p className="mt-3">{description}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default VideoDetails;
