import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { motion } from "framer-motion"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { getComment, postComment, postReply } from "../../../redux/reducers/commentsSlice";



const VideoComments = (props) => {
  const {
    comments,
    commentLoading,
    error,
    buttonLoading,
    replyLoading,
    loaded
  } = useSelector((state) => state.comment);

  const id = props.id;

  const commentCount = props.commentCount;

  const dispatch = useDispatch();
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [curComment, setCurrComment] = useState("");


  const commentRef = useRef("")
  const replyRef = useRef("")
  const scaleVariants = {
    visible: { scale: 1, opacity: 1 },
    hidden: { scale: 0.5, opacity: 0 }
  };
  return (
    <>
      {!error ? (
        <>
          {commentLoading ? (
            <p className="min-h-[30vh] text-center w-full font-semibold mt-10">
              Loading...
            </p>
          ) : (
            <div className="my-8">
              <div className="">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-lg">
                    {comments ? (
                      <>{commentCount}</>
                    ) : (
                      <>-</>
                    )}
                    {""} Comments
                  </h2>
                  <Link
                    to="/forum"
                    className="font-bold text-strong-red underline"
                  >
                    Go to forum
                  </Link>
                </div>
                <div className="mt-10 flex lg:items-center">
                  <div className="rounded-full border border-dark-gray w-12 h-12 overflow-hidden">
                    <img
                      src="https://yt3.ggpht.com/xGjOkcPW4OShTDzH9sFwNU0EfZbhL3fjgtEZ1AyUOKIiO4V1XOPXzGHd5Ewc1yWBdAuPG2YNCuI=s88-c-k-c0x00ffffff-no-rj"
                      alt="maisha digital youtube logo"
                      className="w-full h-full rounded-full"
                    />
                  </div>
                  <div className="w-full pl-5 lg:pl-0 flex items-center justify-between flex-col lg:flex-row">
                    <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
                      <input
                        id="comment"
                        name="comment"
                        type="text"
                        className="h-11 p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
                        placeholder="Comment here"
                        ref={commentRef}
                      />
                      <label htmlFor="comment" className="sr-only">
                        Comment here
                      </label>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (commentRef.current.value != "") {
                          dispatch(postComment({ mediaId: id, content: commentRef.current.value }))
                        }

                      }}
                      className="h-11 w-full max-w-[10rem] ml-auto flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </div>
              <>
                {comments && (
                  <div className="comments mt-10">


                    {comments.slice(0, 10).map((comment, index) => (

                      <div
                        key={comment._id}
                        className="comment-container flex mb-10"
                      >
                        <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
                          <img
                            src={
                              comment.userId.imageUrl
                            }
                            alt="author profile icon"
                            className="w-full"
                          />
                        </div>
                        <div className="flex-1 user-info">
                          <div className="flex text-dark-gray">
                            <h3 className="font-bold user-name">
                              {
                                comment.userId.fullname
                              }
                            </h3>
                            <span className="ml-2 text-gray">
                              <TimeAgo
                                date={
                                  comment.postDate
                                }
                              />
                            </span>
                          </div>
                          <div className="comment-text">
                            {comment.content}{" "}
                            {""}
                            <button
                              onClick={() => {
                                setShowReplyForm(true)
                                setCurrComment(comment._id)
                              }}
                              className="border-b font-bold ml-1 hover:text-strong-red"
                            >
                              Reply
                            </button>
                          </div>
                          {showReplyForm && curComment === comment._id && (

                            // REPLY COMMENT FORM
                            !replyLoading ?
                              <motion.div variants={scaleVariants}
                                animate={showReplyForm && curComment === comment._id ? "visible" : "hidden"} className="mt-8 flex lg:items-center" >
                                <div className="rounded-full border border-dark-gray w-9 lg:w-12 h-9 lg:h-12 overflow-hidden">
                                  {/* <img
                                src="user's image display icon"
                                alt="user's display icon"
                                className="w-full h-full rounded-full"
                              /> */}
                                </div>
                                <div className="w-full flex-1 pl-5 lg:pl-0 flex items-center justify-between flex-row flex-wrap lg:flex-nowrap">
                                  <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
                                    <input
                                      id="reply"
                                      name="reply"
                                      type="text"
                                      className="h-8 text-sm p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
                                      placeholder="Reply here"
                                      ref={replyRef}
                                    />
                                    <label htmlFor="reply" className="sr-only">
                                      Reply here
                                    </label>
                                  </div>
                                  <button
                                    // type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(postReply({ commentId: comment._id, content: replyRef.current.value }))
                                      setShowReplyForm(false);
                                    }}
                                    className="h-8 text-sm w-full flex-1 lg:flex-none lg:max-w-[7rem] ml-auto mr-2 lg:mr-0 flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
                                  >
                                    Reply
                                  </button>
                                  <button
                                    // type="submit"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCurrComment("")
                                    }}
                                    className="text-sm p-2 ml-3 flex items-center justify-center bg-very-light-gray text-gray rounded-md hover:bg-gray hover:text-very-light-gray transtion-all"
                                  >
                                    <ion-icon
                                      name="close"
                                      className="text-lg"
                                    ></ion-icon>
                                  </button>
                                </div>
                              </motion.div> : <p>Loading Replies</p>)
                          }
                          {comment.replies.length > 0 && (
                            <div className="comment-replies">
                              <Accordion elevation={0} >
                                <AccordionSummary
                                  expandIcon={<i class="fa-solid fa-expand"></i>}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <h4 className="font-bold text-strong">
                                    {comment.replies.length} Replies
                                  </h4>
                                </AccordionSummary>
                                {comment.replies.map(
                                  (reply, index) => (<AccordionDetails>
                                    <div className="accordion-body py-4 lg:px-5" key={index}>
                                      <div className="comment-container flex">
                                        <div className="rounded-full w-12 h-12 overflow-hidden mr-4">
                                          <img
                                            src={
                                              reply.userId.imageUrl
                                            }
                                            alt="author profile icon"
                                            className="w-full"
                                          />{" "}
                                        </div>
                                        <div className="flex-1 user-info">
                                          <div className="flex items-center text-dark-gray">
                                            <h3 className="user-name font-bold flex-1">
                                              {
                                                reply.userId.fullname

                                              }
                                            </h3>
                                            <span className="ml-2 text-gray text-xs sm:text-sm lg:text-base">
                                              <TimeAgo
                                                date={
                                                  reply.postDate
                                                }
                                              />
                                            </span>
                                          </div>
                                          <div className="comment-text">
                                            {reply.content}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionDetails>


                                  )
                                )}


                              </Accordion>
                              <div className="accordion" id="repliesAccordion">
                                <div className="accordion-item bg-white">
                                  <div
                                    className="accordion-header mb-0"
                                    id={`heading1${comment._id.slice(0, 5)}`}
                                  >


                                  </div>

                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>

            </div>
          )}
        </>
      ) : (
        <>
          <p className=" mt-20 text-center text-very-dark-blue">
            Reload page
          </p>
        </>
      )
      }
    </>
  );
};

export default VideoComments;
