import React from 'react'

import { motion } from 'framer-motion'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import ShopItem from './ShopItem';
const Shop = () => {
    const navigate = useNavigate()
    return (

        <div className="flex justify-center items-center min-h-screen flex-col my-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 my-5">
                {Array(20).fill().map((_, index) => (
                    <ShopItem index={index} />
                ))}
            </div>
            <div className='flex items-center mt-10 justify-center'>
                <p className='mx-5 font-bold'>Page</p>
                <Pagination count={10} />
            </div>

            <div className='flex justify-center items-start min-h-screen flex-col my-10'>
                <p className='font-bold text-left'>Recently Veiwed</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 my-5">
                    {Array(5).fill().map((_, index) => (
                        <ShopItem index={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Shop