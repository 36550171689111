import React from 'react'

const Crowdfunding = (props) => {
  return (
    <>
      {/* NAME OF CAMPAIGN */}
      <div className="mb-8">
        <label htmlFor="campaign" className="block mb-2">
          Name of campaign<span className="text-strong-red">*</span>
        </label>
        <input
          id="campaign"
          name="campaign"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="Name"
        />
      </div>

      {/* DESCRIPTION */}
      <div className="mb-8">
        <label htmlFor="description" className="block mb-2">
          Description<span className="text-strong-red">*</span>
        </label>
        <input
          id="description"
          name="description"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="Description"
        />
      </div>

      {/* TARGET */}
      <div className="mb-8">
        <label htmlFor="target" className="block mb-2">
          Target
        </label>
        <input
          id="target"
          name="target"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="e.g $10,000"
        />
      </div>

      {/* UPLOAD MEDIA */}
      <div className="mb-8">
        <label
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          htmlFor="media"
        >
          Upload media
        </label>
        <input
          className="block w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
          id="media"
          type="file"
          ref={props.crowdFundingRef}
          name="media"

        />
      </div>

      {/* BENEFICIARY */}
      <div className="mb-8">
        <label htmlFor="beneficiary" className="block mb-2">
          Beneficiary
        </label>
        <input
          id="beneficiary"
          name="beneficiary"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="Name"
        />
      </div>

      {/* ABOUT CAMPAIGN */}
      <div className="mb-8">
        <label htmlFor="about" className="block mb-2">
          About campaign<span className="text-strong-red">*</span>
        </label>
        <input
          id="about"
          name="about"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="About"
        />
      </div>
    </>
  )
}

export default Crowdfunding