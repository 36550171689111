import React from 'react'
import dummyImage from "../../assets/shop/dummyItem.png"
import { motion } from 'framer-motion'
import { useNavigate, useLocation } from 'react-router-dom'

const ShopItem = ({ index }) => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <motion.div key={index} whileHover={{ scale: 1.05 }} onClick={(e) => {
            if (location.pathname.includes("maisha-digital")) {
                navigate(`/maisha-digital/shop/${index}`)
            } else {
                navigate(`/maisha-arts/shop/${index}`)
            }

        }} transition={{
            duration: 1
        }} className="bg-white p-4 rounded-md w-64 h-64 flex flex-col items-center cursor-pointer">
            <img src={dummyImage} />
            <p className='font-bold'>African Carved Calabash</p>
            <p>275 GHS</p>
        </motion.div>
    )
}

export default ShopItem