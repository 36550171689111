import React from "react";

const Suggestions = () => {
  return (
    <section className="px-5 lg:px-8 xl:px-5 mx-auto w-full max-w-xl lg:max-w-7xl w-full flex flex-col items-center mt-10 mb-32">
      <h2 className="text-gray mb-8 font-bold">Suggestions</h2>
      <div className="flex flex-col lg:flex-row">
        <a
          href="#"
          className="mb-6 lg:mb-0 bg-very-light-gray lg:mx-8 mx-auto text-dark-gray py-4 w-full lg:w-auto px-6 rounded-md lg:ml-4 flex items-center justify-center cursor-pointer hover:bg-very-light-gray hover:text-dark-gray transition-all"
        >
          Plantain Chips
        </a>
        <a
          href="#"
          className="mb-6 lg:mb-0 bg-very-light-gray lg:mx-8 mx-auto text-dark-gray py-4 w-full lg:w-auto px-6 rounded-md lg:ml-4 flex items-center justify-center cursor-pointer hover:bg-very-light-gray hover:text-dark-gray transition-all"
        >
          Maisha Digital
        </a>
        <a
          href="#"
          className="mb-6 lg:mb-0 bg-very-light-gray lg:mx-8 mx-auto text-dark-gray py-4 w-full lg:w-auto px-6 rounded-md lg:ml-4 flex items-center justify-center cursor-pointer hover:bg-very-light-gray hover:text-dark-gray transition-all"
        >
          Maisha Arts
        </a>
      </div>
    </section>
  );
};

export default Suggestions;
