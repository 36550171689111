import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";

import { baseUrl } from "../../config"

export const getCarousel = createAsyncThunk(
    'media/getCarousel',

    async (limit = 10) => {
        const response = await fetch(baseUrl + `/media/one/carousel`)
        return (await response.json())['carousel']
    })
const carouselSlice = createSlice({
    name: "Carousel/Slice",
    initialState: {
        loading: false,
        error: false,
        carousel: null
    },
    extraReducers: builder => {
        builder.addCase(getCarousel.fulfilled, (state, action) => {
            state.carousel = action.payload;
            state.loading = false;
            state.error = false;
            console.log(action.payload)

        })
        builder.addCase(getCarousel.pending, (state, action) => {
            state.loading = true;
            state.error = false
        })
        builder.addCase(getCarousel.rejected, (state, action) => {
            state.loading = false;
            state.error = true
        })
    }

})
export default carouselSlice.reducer;