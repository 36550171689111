import { configureStore } from '@reduxjs/toolkit'
import mediaReducer from "./reducers/mediaSlice";
import userReducer from "./reducers/userSlice";
import wikiReducer from "./reducers/wikiSlice";
import forumReducer from "./reducers/forumSlice";
import sharedReducer from "./reducers/sharedSlice";
import aboutReducer from "./reducers/AboutSlice";
import authReducer from './reducers/authSlice';
import addCourseSlice from './reducers/addCourseSlice';
import crowdFunding from './reducers/crowdFunding';
import channelSlice from './reducers/channelSlice';
import ourWorkSlice from "./reducers/ourWorksSlice"
import commentsSlice from './reducers/commentsSlice';
import protectionSlice from "./reducers/protectionSlice"
import CarouselSlice from './reducers/CarouselSlice';
import forumListSlice from './reducers/forumListSlice';
import recommendedSlice from './reducers/recommendedSlice';
import masterClassSlice from './reducers/masterClassSlice';

export const store = configureStore({
  reducer: {
    addCourse: addCourseSlice,
    auth: authReducer,
    media: mediaReducer,
    user: userReducer,
    wiki: wikiReducer,
    forum: forumReducer,
    shared: sharedReducer,
    about: aboutReducer,
    crowdFunding: crowdFunding,
    channelSlice: channelSlice,
    ourWorkSlice: ourWorkSlice,
    comment: commentsSlice,
    protection: protectionSlice,
    carousel: CarouselSlice,
    forumList: forumListSlice,
    recommended: recommendedSlice,
    masterclass: masterClassSlice
  },
})