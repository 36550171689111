import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changeSortFunction } from "../../redux/reducers/mediaSlice";


const Filter = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const {
    videos,
    sorts,
    filters,
    currentPage,
    pageCount

  } = useSelector((state) => state.media);

  const handleFilter = (e) => {
    // SET FILTERBY VALUE

  };

  const handleSort = (e) => {
    dispatch(changeSortFunction(e.targetValue));

  };

  return (
    <section className="wrapper mt-8 flex flex-col lg:flex-row items-center justify-between max-w-xl lg:max-w-7xl px-5 lg:px-8 xl:px-5 mx-auto sm">
      <h2 className="sr-only">Filter</h2>
      <div className="mb-6 lg:mb-0 w-full lg:w-fit">
        {videos && (
          <p className="text-gray font-bold">
            Showing {videos.length > 20 ? 20 : videos.length} of {videos.length}
          </p>
        )}
      </div>
      <div className="flex items-center flex-col lg:flex-row w-full lg:w-fit">
        <div className="sort-container mb-4 lg:mb-0 flex items-center justify-between w-full lg:w-fit relative">
          <span className="text-base md:text-xl mr-2 lg:mr-4">Sort by:</span>
          <select
            className="appearance-none px-3 py-2 border border-gray rounded-md transition ease-in-out m-0 focus:outline-none bg-white xl:w-48 cursor-pointer pr-8 lg:-r-0"
            aria-label="Default sorting select"
            name="sortBy"
            onChange={handleSort}
            defaultValue="date"
          >
            {sorts.map((sort, index) => (
              <option value={sort}>{sort}</option>
            ))}
          </select>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.8449 5.71572L8.38875 0.162771C8.28531 0.0584973 8.1456 0 8 0C7.8544 0 7.71469 0.0584973 7.61125 0.162771L2.15511 5.71572C2.08109 5.79601 2.03092 5.89598 2.0104 6.00408C1.98988 6.11218 1.99985 6.22398 2.03916 6.32655C2.0809 6.42761 2.1511 6.51384 2.24092 6.57437C2.33073 6.63489 2.43614 6.66701 2.54386 6.66666H13.4561C13.5639 6.66701 13.6693 6.63489 13.7591 6.57437C13.8489 6.51384 13.9191 6.42761 13.9608 6.32655C14.0001 6.22398 14.0101 6.11218 13.9896 6.00408C13.9691 5.89598 13.9189 5.79601 13.8449 5.71572Z"
                fill="#A4A4A4"
              />
              <path
                d="M13.9608 9.67358C13.9191 9.57247 13.8489 9.4862 13.7591 9.42565C13.6693 9.3651 13.5639 9.33297 13.4561 9.33332H2.54386C2.43614 9.33297 2.33073 9.3651 2.24092 9.42565C2.1511 9.4862 2.0809 9.57247 2.03916 9.67358C1.99985 9.77619 1.98988 9.88803 2.0104 9.99617C2.03092 10.1043 2.08109 10.2043 2.15511 10.2847L7.61125 15.8399C7.71554 15.9426 7.85493 16 8 16C8.14507 16 8.28446 15.9426 8.38875 15.8399L13.8449 10.2847C13.9189 10.2043 13.9691 10.1043 13.9896 9.99617C14.0101 9.88803 14.0001 9.77619 13.9608 9.67358Z"
                fill="#A4A4A4"
              />
            </svg>
          </span>
        </div>
        <div className="filter-container mb-4 lg:mb-0 flex items-center justify-between w-full lg:w-fit lg:ml-8 relative">
          <span className="text-base md:text-xl mr-2 lg:mr-4">Filter by:</span>
          <select
            className="appearance-none px-3 py-2 border border-gray rounded-md transition ease-in-out m-0 focus:outline-none bg-white xl:w-48 cursor-pointer pr-8 lg:-r-0"
            aria-label="Default filtering select"
            name="filterBy"
            onChange={handleFilter}
          >
            {filters.map((filter, index) => (
              <option value={filter} key={index} className="">
                {filter}
              </option>
            ))}
          </select>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.8449 5.71572L8.38875 0.162771C8.28531 0.0584973 8.1456 0 8 0C7.8544 0 7.71469 0.0584973 7.61125 0.162771L2.15511 5.71572C2.08109 5.79601 2.03092 5.89598 2.0104 6.00408C1.98988 6.11218 1.99985 6.22398 2.03916 6.32655C2.0809 6.42761 2.1511 6.51384 2.24092 6.57437C2.33073 6.63489 2.43614 6.66701 2.54386 6.66666H13.4561C13.5639 6.66701 13.6693 6.63489 13.7591 6.57437C13.8489 6.51384 13.9191 6.42761 13.9608 6.32655C14.0001 6.22398 14.0101 6.11218 13.9896 6.00408C13.9691 5.89598 13.9189 5.79601 13.8449 5.71572Z"
                fill="#A4A4A4"
              />
              <path
                d="M13.9608 9.67358C13.9191 9.57247 13.8489 9.4862 13.7591 9.42565C13.6693 9.3651 13.5639 9.33297 13.4561 9.33332H2.54386C2.43614 9.33297 2.33073 9.3651 2.24092 9.42565C2.1511 9.4862 2.0809 9.57247 2.03916 9.67358C1.99985 9.77619 1.98988 9.88803 2.0104 9.99617C2.03092 10.1043 2.08109 10.2043 2.15511 10.2847L7.61125 15.8399C7.71554 15.9426 7.85493 16 8 16C8.14507 16 8.28446 15.9426 8.38875 15.8399L13.8449 10.2847C13.9189 10.2043 13.9691 10.1043 13.9896 9.99617C14.0101 9.88803 14.0001 9.77619 13.9608 9.67358Z"
                fill="#A4A4A4"
              />
            </svg>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Filter;
