import React, { useEffect } from "react";
import Aside from "../../components/Wiki/SearchResults/Aside";
import ResultItem from "../../components/Wiki/SearchResults/ResultItem";
import Search from "../../components/Wiki/SearchResults/Search";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchAsyncThunk } from "../../redux/reducers/wikiSlice";


const SearchResults = () => {
  const [query, setQuery] = useSearchParams()
  const dispatchFN = useDispatch();
  const state = useSelector(state => state.wiki)
  useEffect(() => {
    dispatchFN(searchAsyncThunk(query.get("q")))
  }, [])


  return (
    <>
      <section className="search px-5 lg:px-8 2xl:px-0">
        <Search />
      </section>
      <div className="px-5 lg:px-8 2xl:px-0 mb-10">
        <div className="flex flex-col lg:flex-row gap-x-8 max-w-xl lg:max-w-7xl 2xl:max-w-8xl mx-auto">
          <div className="lg:w-7/12">
            <section className="results">
              <h2 className="font-bold text-gray mb-6">Results:</h2>

              {
                state.result.length === 0 ? <h3 className="font-bold text-gray mb-6">No result found</h3> :
                  state.result.map((e, index) => <ResultItem key={index} e={e} />)}


            </section>
          </div>
          <Aside />
        </div>
      </div>
    </>
  );
};

export default SearchResults;
