import { createAsyncThunk, createSlice, reje } from "@reduxjs/toolkit";
// let instagram = require('../../instagram.json')
import axios from "axios";
import { baseUrl } from "../../config";

export const getInstagramDetails = createAsyncThunk('shared/getInstagramDetails', async (thunkAPI) => {
  try {
    let instagramData = await axios.get(`${baseUrl}/insta/instagram/${thunkAPI}`)
    return instagramData.data["data"]

  } catch (err) {
    thunkAPI.rejectWithValue(err.message)
  }

})

const sharedSlice = createSlice({
  name: "shared",
  initialState: {
    loading: true,
    error: false,
    errMsg: '',
    success: '',
    instagramDetails: []
  },
  reducers: {
  },
  extraReducers: {
    [getInstagramDetails.pending]: (state) => {
      state.loading = true
      state.success = false
    },
    [getInstagramDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.instagramDetails = payload
      state.success = true
    },
    [getInstagramDetails.rejected]: (state, action) => {
      state.loading = false
      state.success = false
      state.error = true
      state.errMsg = action.error.message
    },
  }
})

// export const { } = sharedSlice.actions;
export default sharedSlice.reducer;
