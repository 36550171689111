import React from "react";
import Timeago from "react-timeago";

const CommentsTab = ({ comments }) => {
  return (
    <>
      <div className="flex lg:items-center max-w-4xl mb-1">
        <div className="rounded-full border border-dark-gray w-12 h-12 overflow-hidden">
          <img
            src="https://yt3.ggpht.com/xGjOkcPW4OShTDzH9sFwNU0EfZbhL3fjgtEZ1AyUOKIiO4V1XOPXzGHd5Ewc1yWBdAuPG2YNCuI=s88-c-k-c0x00ffffff-no-rj"
            alt="maisha digital youtube logo"
            className="w-full h-full rounded-full"
          />
        </div>
        <form className="w-full pl-5 lg:pl-0 flex items-center justify-between flex-col lg:flex-row">
          <div className="relative w-full mb-2 lg:mb-0 py-3 lg:py-0 lg:mx-4">
            <input
              id="comment"
              name="comment"
              type="text"
              className="h-11 p-3 w-full border-b border-gray placeholder-gray focus:outline-none focus:border-strong-red"
              placeholder="Comment here"
            />
            <label htmlFor="comment" className="sr-only">
              Comment here
            </label>
          </div>
          <button
            // type="submit"
            className="h-11 w-full max-w-[10rem] ml-auto flex items-center justify-center bg-strong-red text-white rounded-md hover:bg-very-light-gray hover:text-dark-gray transtion-all"
          >
            Comment
          </button>
        </form>
      </div>
      <div className="mx-auto flex flex-col justify-start">
        {/* COMMENTS */}
        {comments
          .map((item, index) => (
            <div key={index} className="flex items-start justify-center mb-8">
              <div
                className="w-20 h-20 bg-gray rounded-full mr-4"
                aria-label="user-profile-img"
              >
                <img src={item.userId.imageUrl} />
              </div>
              <div className="user-info flex-1">
                <span className="font-bold">{item.userId.fullname} </span>
                <span className="">

                  <Timeago date={item.postDate} />
                </span>
                {/* <div className="block mt-1">$100 - 5 hours ago</div> */}
                <p className="mt-1">
                  {item.content}
                </p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default CommentsTab;
