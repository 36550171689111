import React from 'react'
import Hero from '../../components/Wiki/Hero'
import Search from '../../components/Wiki/Search'
import Suggestions from '../../components/Wiki/Suggestions'

const Wiki = () => {
  return (
    <>
    <Hero />
    <Search />
    <Suggestions />
    </>
  )
}

export default Wiki