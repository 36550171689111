import React, { useEffect } from "react";
import img1 from "../../assets/hero/hero-img-1.gif";
import img2 from "../../assets/hero/hero-img-2.svg";
import img3 from "../../assets/hero/hero-img-3.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from "react-redux";


const Hero = () => {
  // const [active, setactive] = useState(true);

  useEffect(() => { }, [])
  const { loading, error, carousel } = useSelector(state => state.carousel)
  return (

    <section className="min-h-[30vh]">
      <h1 className="sr-only">Maisha</h1>
      <div >
        <Carousel autoPlay showStatus={false} showThumbs={false} className="object-contain">
          <div className="carousel-item active relative float-left w-full mb-16 h-[70vh] ">
            <img
              src={img1}
              className="blocklg:w-8/12 m-auto object-contain h-full"
              alt="girl walking towards a rabbit"
            />
            <div className="block absolute -bottom-14 lg:-bottom-12 inset-x-0 text-center max-w-xl lg:max-w-2xl mx-auto">
              <p className="mx-3 font-caveat">
                “Not everyone who chased the zebra caught it, but he who caught,
                chased it”{" "}
                <span className="text-strong-red">- African Proverb</span>
              </p>
            </div>
          </div>

          {/* <!-- Single item --> */}
          <div className="carousel-item relative float-left w-full mb-16 h-[70vh] ">
            <img
              src={img2}
              className="block w-full lg:w-8/12 m-auto object-contain h-full"
              alt="maisha logo"
            />
          </div>
          {/* <!-- Single item --> */}
          <div className="carousel-item relative float-left w-full mb-16 h-[70vh] ">
            <img
              src={img3}
              className="block w-full lg:w-8/12 m-auto object-contain h-full"
              alt="say not to rape culture"
            />
            <div className="block absolute -bottom-14 -mb-2 inset-x-0 text-center max-w-xl lg:max-w-2xl mx-auto">
              <p className="mx-3">
                2 in 5 women in Ghana admit to their first sexual encounter
                being non cosentual. This is unacceptable Support us to bring
                public awareness to to this reprehensible act.{" "}
                <span className="text-strong-red font-bold">Find out more</span>
              </p>
            </div>
          </div>
        </Carousel>
      </div>

    </section>
  );
};

export default Hero;
