import { useEffect, useState } from "react";
import maishaLogo from "../../../assets/logo/maisha-logo.svg";
import brightAckwerh from "../../../assets/channels/intro_visual_art.png";
import channel1 from "../../../assets/channels/channel1.png";
import channel2 from "../../../assets/channels/channel2.png";
import channel3 from "../../../assets/channels/channel3.png";
import channel4 from "../../../assets/channels/channel4.png";
import channel5 from "../../../assets/channels/channel5.png";
import channel6 from "../../../assets/channels/channel6.png";
import channel7 from "../../../assets/channels/channel7.png";
import channel8 from "../../../assets/channels/channel8.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchChannels } from "../../../redux/reducers/channelSlice";

const FreeMediaTab = () => {
  const [channelImgs, setchannelImgs] = useState([channel1, channel2, channel3, channel4, channel5, channel6, channel7, channel8]);
  const state = useSelector(state => state.channelSlice)
  const dispatchFN = useDispatch()
  useEffect(() => {
    dispatchFN(fetchChannels())
  }, [])

  return (
    <div className="flex flex-wrap items-center justify-center lg:gap-x-6">
      {/* <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
        <div className="w-48 h-48 lg:w-56 lg:h-56 flex items-center justify-center rounded-full overflow-hidden border mb-4">
        <img
          src={maishaLogo}
          alt="channel icon"
          className="bg-light-gray w-28 lg:w-32"
        /></div>
        <Link to="channel-about" className="mt-2 hover:text-gray transition-all font-bold">Maisha Arts</Link>
      </article>
      <article className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
        <img
          src={brightAckwerh}
          alt="channel icon"
          className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
        />
        <Link to="channel-about" className="mt-2 hover:text-gray transition-all font-bold">Bright Ackwerh</Link>
      </article> */}
      {
        state.channels.map((channel, index) => (
          <article key={index} className="flex flex-col items-center justify-center w-48 lg:w-56 mb-7 mx-3 lg:mx-0">
            <img
              src={channel.channelThumbnailUrl}
              alt="channel icon"
              className="bg-light-gray w-48 h-48 lg:w-56 lg:h-56 rounded-full overflow-hidden border mb-4"
            />
            <Link to="channel-about" className="mt-2 hover:text-gray transition-all font-bold">{channel.channelName}</Link>
          </article>
        ))
      }
    </div>
  );
};

export default FreeMediaTab;
