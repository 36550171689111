import React, { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOneVideos } from "../../redux/reducers/mediaSlice";
import VideoDetails from "../../components/Media/VideoPage/VideoDetails";
import SuggestedVids from "../../components/Media/VideoPage/SuggestedVids";
import VideoComments from "../../components/Media/VideoPage/VideoComments";
import Censor from "../../components/Media/Censor";

const VideoPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const { id } = params;
  useEffect(() => {
    dispatch(getOneVideos(id))
  }, [params])



  // useEffect(() => {
  //   const curPage = localStorage.getItem("curPage")
  //     ? localStorage.getItem("curPage")
  //     : 1;
  //   // PAGE TOKEN IS BASED ON CURRENT PAGE
  //   dispatch(

  //   );
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   // SINGLE VIDEO DETAILS
  //   !loading && dispatch();

  //   // eslint-disable-next-line
  // }, [success, id]);

  // useEffect(() => {
  //   if (!loading) {
  //     dispatch();
  //     // VIDEO COMMENTS
  //     dispatch();
  //   }

  //   // eslint-disable-next-line
  // }, [success, curVideo.length > 0, id, videos]);

  // useEffect(() => {
  //   // TO ENSURE ITS AN ACTUAL VIDEO ID AND NOT AN INCOMPLETE PAGE LINK
  //   location.pathname.split("/").includes("page") && navigate("/media");

  //   // eslint-disable-next-line
  // }, [location.pathname]);

  // useEffect(() => {
  //   if (!loading) {
  //     // VIDEO COMMENTS
  //     dispatch(getVideoComments({ id }));
  //   }
  //   // eslint-disable-next-line
  // }, [success, curVideo.length > 0]);

  const { error, errMsg, videos, loading, success, selectedVideo } = useSelector((state) => state.media);
  console.log(selectedVideo)
  return (
    <section className="w-full px-5 lg:px-8 xl:px-5 mx-auto max-w-xl lg:max-w-7xl flex flex-row mt-10 items-center justify-center">
      {/* {censor && <Censor />} */}
      {!error ? (
        <>
          {loading || selectedVideo == null ? (
            <>
              {/* <p className="min-h-[30vh] text-center w-full font-semibold">
              Loading...
            </p> */}

              <div
                role="status"
                className="w-full flex flex-col lg:flex-row items-center lg:items-start justify-center gap-4 rounded-md animate-pulse"
              >
                <div className="w-full lg:w-8/12 mb-10">
                  <div className="bg-gray w-full h-[360px] rounded-md"></div>
                  <div className="mt-4 w-9/12 h-7 bg-gray rounded-full"></div>
                  <div className="mt-4 flex items-center max-w-lg">
                    <div className="w-7/12 h-6 bg-gray rounded-full"></div>
                    <div className="w-1/12 h-6 bg-gray rounded-full ml-3"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
                {/* SUGGESTED VIDEOS */}
                <div className="flex-1 mb-10">
                  {Array(3)
                    .fill()
                    .map((item, index) => (
                      <div
                        key={index}
                        role="status"
                        className="post md:mx-3 lg:mx-4 mb-8 flex flex-row justify-between animate-pulse"
                      >
                        <div className="rounded-lg mb-4 bg-gray min-h-[150px] w-6/12"></div>
                        <div className="flex-1 flex flex-col ml-2">
                          <div className="h-5 w-9/12 bg-gray rounded-full mb-4"></div>
                          <div className="h-5 w-5/12 bg-gray rounded-full mb-6"></div>
                          <div className="h-4 w-8/12 bg-gray rounded-full mb-3"></div>
                          <div className="h-3 w-6/12 bg-gray rounded-full"></div>
                        </div>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full flex flex-col lg:flex-row items-center lg:items-start justify-center gap-4 rounded-md">
                <div className="w-full lg:w-8/12 mb-10">
                  <VideoDetails selected={selectedVideo} />
                  <VideoComments id={selectedVideo._id} commentCount={selectedVideo.commentCount} />
                </div>
                <SuggestedVids />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <p className=" mt-20 text-center text-very-dark-blue">
            {errMsg}. Reload page
          </p>
        </>
      )}
    </section>
  );
};

export default VideoPage;
