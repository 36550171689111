
import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import axios from "axios";

import { baseUrl } from "../../config"

export const getAllVideos = createAsyncThunk(
  'media/getAllVideos',
  async (limit = 10) => {
    const response = await fetch(baseUrl + `/media/all?mediaType=all&limit=${limit}`)
    const data = (await response.json())["media"];
    return data

  })

export const getOneVideos = createAsyncThunk(
  'media/getOneVideos',
  async (id, thunkAPI) => {
    const response = await fetch(baseUrl + `/media/${id}?mediaType=video`)
    const data = (await response.json())["media"];
    return data
  })

export const getSuggestedVideos = createAsyncThunk(
  'media/suggested',
  async (limit = 10) => {
    const response = await fetch(baseUrl + `/media/suggested?limit=${limit}`)
    const data = (await response.json())["media"];
    return data
  })
function sortByFunction(condition, list) {
  switch (condition) {
    case 'date':
      return list.sort((a, b) => new Date(b.postDate) - new Date(a.postDate));
    case 'rating':
      return list.sort((a, b) => b.likes.length - a.likes.length);
    case 'viewCount':
      return list.sort((a, b) => b.likes.length - a.likes.length);
    case 'relevance':
      return list.sort((a, b) => b.likes.length - a.likes.length);
    case 'title':
      return list.sort((a, b) => a.titleOfContent.localeCompare(b.titleOfContent));

    default:
      return list

  }
}
const mediaSlice = createSlice({
  name: "media",
  initialState: {
    loading: false,
    error: false,
    videos: [],
    selectedVideo: null,
    errMsg: '',
    success: false,
    showReplyForm: false,
    curComment: null,
    pageCount: 0,
    currentPage: 0,
    sorts: ['', 'date', 'rating', 'viewCount', 'relevance', 'title', 'videoCount'],
    filters: ['', 'any', 'short', 'medium', 'long'],
    filterBy: '',
    sortBy: '&order=date',
    filterSort: '',
    censor: true,
    userDate: '',
    curYear: new Date().getFullYear(),
    censorDigit: '',
    suggested: null
  },
  reducers: {
    changeCurrentPage(state, action) {
      state.curComment = action.payload
    },
    changeSortFunction(state, action) {
      state.videos = sortByFunction(action.payload, state.videos)
    },
    changeSelectedVideo(state, action) {
      state.selectedVideo = action.payload
    }
  },
  extraReducers:
    builder => {
      builder.addCase(getAllVideos.fulfilled, (state, action) => {
        state.videos = [...action.payload];
        state.pageCount = Math.floor(action.payload.length / 25)
        state.loading = false;
        state.error = false;
        state.success = true;

      })
      builder.addCase(getAllVideos.pending, (state) => {
        state.loading = true
        state.error = false;
      })
      builder.addCase(getAllVideos.rejected, (state) => {
        state.loading = false
        state.error = true;
      })
      builder.addCase(getOneVideos.fulfilled, (state, action) => {
        console.log(action.payload)
        state.selectedVideo = action.payload;

        state.loading = false;
        state.error = false;
        state.success = true;

      })
      builder.addCase(getOneVideos.pending, (state) => {
        state.loading = true
        state.error = false;
      })
      builder.addCase(getOneVideos.rejected, (state) => {
        state.loading = false
        state.error = true;
      })
      builder.addCase(getSuggestedVideos.fulfilled, (state, action) => {
        state.suggested = action.payload;
      })
      builder.addCase(getSuggestedVideos.pending, (state) => {

      })
      builder.addCase(getSuggestedVideos.rejected, (state) => {

      })
    }

})

// export const { suggestedVideos, replyCommentForm, setPageCount, setCurPage, setDisplayNumbers, selectFilter, selectSort, removeCensorError, censorApproval } = mediaSlice.actions;
export const { changeCurrentPage, changeSortFunction, changeSelectedVideo } = mediaSlice.actions;
export default mediaSlice.reducer;
