import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ChannelsTabs from '../../components/Channels/ChannelsTabs/ChannelsTabs'
import { Backdrop } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';

const UserHomePage = () => {
    const { user } = useSelector(state => state.protection)

    const [showEditProfile, setShowEditProfile] = useState(false)
    const [fullName, setFullName] = useState(user ? user.fullname : "")
    const [email, setEmail] = useState(user ? user.email : "")
    const [phoneNumber, setphoneNumber] = useState(user ? user.phoneNumber ?? "" : "")
    const [country, setCountry] = useState(user ? user.country : "")

    const [imagePath, setImagePath] = useState("")
    function handleProfileImage(e) {
        e.preventDefault();
        setImagePath(URL.createObjectURL(e.target.files[0]))
    }
    useEffect(() => {
        if (user) {
            setFullName(user.fullname)
            setEmail(user.email)
            setphoneNumber(user.phoneNumber)
            setCountry(user.country)
        }
    }, [user])
    return (
        <>
            {!showEditProfile ? <div className='mx-10 my-10'>
                <div className='md:h-[250px] h-[200px] flex my-5'>
                    <div className='h-[200px] w-[200px] md:h-[250px] md:w-[250px] overflow-clip rounded-full mx-3'>
                        {user ? <img src={user.imageUrl} className='object-cover h-full w-full' alt='profile-img' /> : <div className='w-[60px] bg-dark-gray animate-pulse'>
                        </div>}
                    </div>
                    <div className='mx-5 py-5'>
                        {user ? <h2 className='font-bold md:text-3xl text-xl'>{user.fullname}</h2> : <div className='w-[60px] bg-dark-gray animate-pulse'></div>}
                        {user ? <h5 className='text-gray'>{user.email}</h5> : <div className='w-[60px] bg-dark-gray animate-pulse'></div>}
                        {user ? <h3 className='text-gray'>{user.country}</h3> : <div className='w-[60px] bg-dark-gray animate-pulse'></div>}
                        <button onClick={(e) => {
                            e.preventDefault();
                            setShowEditProfile(true);
                        }} className='bg-strong-red px-3 py-2 text-white font-bold rounded-lg text-sm my-10'>Edit Profile</button>
                    </div>
                </div>

                <hr />
                {user && <ChannelsTabs />}
            </div> :
                <AnimatePresence>
                    <Backdrop key='editProfile' className='z-10' open={showEditProfile}>
                        <motion.div initial={{
                            y: 50,
                            opacity: 0
                        }} animate={{
                            y: 0, opacity: 1
                        }} exit={{
                            y: 50,
                            opacity: 0
                        }} className='w-[80vw] max-w-[700px] h-[90vh] md:h-[60vh] bg-white rounded-md px-4 py-2 '>
                            <div className='flex justify-end '>
                                <svg onClick={(e) => {
                                    e.preventDefault();
                                    setShowEditProfile(false)
                                    setImagePath("")
                                }} className='cursor-pointer' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.75 5.25L5.25 18.75" stroke="#414142" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M18.75 18.75L5.25 5.25" stroke="#414142" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            {/* Form Section */}
                            <div>
                                <form >
                                    <div className='flex md:flex-row flex-col'>
                                        <div className='h-[150px] w-[150px] md:h-[225px] md:basis-4/12 border-2 border-gray rounded-md  flex flex-col items-center justify-center' >
                                            {imagePath === "" ? <>
                                                <input id="profile-img" onChange={handleProfileImage} type='file' hidden accept='.png, .jpg, .jpeg' />
                                                <svg className='mx-auto cursor-pointer' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.825 14.6248C13.7525 14.4422 13.734 14.2426 13.7716 14.0498C13.8093 13.857 13.9016 13.6791 14.0375 13.5373L19.2875 8.28727C19.4771 8.09948 19.7332 7.99414 20 7.99414C20.2669 7.99414 20.5229 8.09948 20.7125 8.28727L25.9625 13.5373C26.0984 13.6791 26.1907 13.857 26.2284 14.0498C26.266 14.2426 26.2475 14.4422 26.175 14.6248C26.1004 14.8091 25.9726 14.967 25.8078 15.0784C25.6431 15.1897 25.4489 15.2494 25.25 15.2498H21V22.9998C21 23.265 20.8947 23.5193 20.7071 23.7069C20.5196 23.8944 20.2652 23.9998 20 23.9998C19.7348 23.9998 19.4804 23.8944 19.2929 23.7069C19.1054 23.5193 19 23.265 19 22.9998V15.2498H14.75C14.5512 15.2494 14.3569 15.1897 14.1922 15.0784C14.0274 14.967 13.8996 14.8091 13.825 14.6248ZM31 21.9998C30.7348 21.9998 30.4804 22.1051 30.2929 22.2927C30.1054 22.4802 30 22.7345 30 22.9998V29.9998H10V22.9998C10 22.7345 9.89464 22.4802 9.70711 22.2927C9.51957 22.1051 9.26522 21.9998 9 21.9998C8.73478 21.9998 8.48043 22.1051 8.29289 22.2927C8.10536 22.4802 8 22.7345 8 22.9998V29.9998C8 30.5302 8.21071 31.0389 8.58579 31.414C8.96086 31.7891 9.46957 31.9998 10 31.9998H30C30.5304 31.9998 31.0392 31.7891 31.4142 31.414C31.7893 31.0389 32 30.5302 32 29.9998V22.9998C32 22.7345 31.8947 22.4802 31.7071 22.2927C31.5196 22.1051 31.2652 21.9998 31 21.9998Z" fill="#A4A4A4" />
                                                </svg>
                                                <label htmlFor="profile-img" className='text-center underline text-strong-red cursor-pointer'>Upload Image</label></> : <img src={imagePath} className='w-full object-cover h-full' />}

                                        </div>

                                        <div className='w-full  md:basis-8/12  md:mx-4 my-4 px-2 box-border'>
                                            <input className='w-full outline-none border-2 border-gray px-3 py-1 rounded-md my-2' placeholder='Full name' value={fullName} onChange={(e) => {
                                                setFullName(e.target.value)
                                            }} />
                                            <input className='w-full outline-none border-2 border-gray px-3 py-1 rounded-md my-2' placeholder='Email' readOnly value={email} />
                                            <input className='w-full outline-none border-2 border-gray px-3 py-1 rounded-md my-2' placeholder='Country' value={country} onChange={(e) => {
                                                setCountry(e.target.vaule)
                                            }} />
                                            <input className='w-full outline-none border-2 border-gray px-3 py-1 rounded-md my-2' placeholder='Phone' value={phoneNumber} onChange={(e) => {
                                                setphoneNumber(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                    <button onSubmit={(e) => {
                                        e.preventDefault();
                                    }
                                    } className='w-full px-auto my-3 bg-strong-red py-2 text-white rounded-md'>Update Profile</button>

                                </form>
                            </div>
                        </motion.div>

                    </Backdrop></AnimatePresence>}
        </>
    )
}

export default UserHomePage