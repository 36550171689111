import React from 'react'
import userImage from "../../assets/home/st-pete-school.png"

const TestimonialItem = () => {
    return <div className='flex justify-between flex-row items-start w-full my-4'>
        <div className='rounded-full overflow-hidden basis-1/12 '>
            <img src={userImage} className='object-cover w-[50px] h-[50px] rounded-full' alt="User" />
        </div>
        <div className='mx-5 flex flex-col basis-11/12'>
            <p className='font-bold'>John Doe <span className='font-thin mx-3'>5 hours ago</span></p>
            <div className='flex'>
                {Array(5).fill().map((e, index) => (
                    <svg key={index} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" fill="#414142" />
                    </svg>
                ))}
            </div>
            <div className=''>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim risus, venenatis, in feugiat maecenas semper non. Non nec, nam elementum ultrices enim massa at eros, blandit.
                Dignissim risus, venenatis, in feugiat maecenas sem
                <div className='flex'>
                    <img src={userImage} className='w-[35px] h-[35px] object-cover mx-2 rounded' />
                    <img src={userImage} className='w-[35px] h-[35px] object-cover mx-2 rounded' />
                </div>
            </div>
        </div>
    </div>
}

export default TestimonialItem