import React from "react";
import Hero from "../../components/Home/Hero";
import OnInstagram from "../../components/Home/OnInstagram";
import RecommendedForYou from "../../components/Home/RecommendedForYou";

const HomeArts = () => {
  return (
    <>
      <Hero />
      <RecommendedForYou />
      <OnInstagram />
    </>
  );
};

export default HomeArts;
