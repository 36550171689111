import React, { useRef, useState } from 'react'


const MasterClass = (props) => {

  const addNewField = () => {
    console.log(props.fields);
    props.setFields((e) => {
      return [...e, { id: e.length, name: "", file: "" }]
    })
  }
  return (
    <>
      {/* ABOUT COURSE */}
      <div className="mb-8">
        <label htmlFor="course" className="block mb-2">
          About course<span className="text-strong-red">*</span>
        </label>
        <input
          id="course"
          name="course"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="Course description"
        />
      </div>

      {/* PRICE */}
      <div className="mb-8">
        <label htmlFor="price" className="block mb-2">
          Course price<span className="text-strong-red">*</span>
        </label>
        <input
          id="price"
          name="price"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="e.g $10,000"
        />
      </div>

      {/* UPLOAD MEDIA */}
      <div className="mb-8">
        <div className="flex items-center justify-between flex-wrap mb-4 pb-4 border-b border-gray">
          <label
            className="block mb-2 text-sm font-medium text-gray-900"
          // htmlFor="media"
          >
            Upload media<span className="text-strong-red">*</span>
          </label>
          <span className="text-strong-red underline cursor-pointer"
            onClick={addNewField}
          >+Add new</span>
        </div>

        {props.fields.map((value, index) => {
          return <div className="flex items-center justify-between flex-col md:flex-row gap-x-4 flex-wrap">
            <div className="mb-4 flex-1 w-full">
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="class-name"
              >
                Name
              </label>
              <input
                className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                id="class-name"
                type="text"
                name='module_name'
                placeholder="e.g Section 1"
                onChange={(e) => props.setFields((curr) => {
                  curr[index].name = e.target.value;
                  return curr
                })}
              />
            </div>
            <div className="mb-0 md:mb-4 flex-1 w-full">
              <label
                className="block mb-2 text-sm font-medium text-gray-900 "
                htmlFor="duration"
              >
                File
              </label>
              <input
                className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
                id="duration"
                type="file"
                name='module_file'
                onChange={(e) => props.setFields((curr) => {
                  curr[index].file = e.target.files[0];
                  return curr
                })}
              />
            </div>
          </div>
        })}

      </div>

      {/* ABOUT */}
      {/* <div className="mb-8">
        <label htmlFor="about" className="block mb-2">
          About<span className="text-strong-red">*</span>
        </label>
        <input
          id="about"
          name="about"
          type="text"
          className="h-14 p-3 w-full border border-gray placeholder-gray focus:outline-none focus:border-strong-red rounded-md"
          placeholder="Class description"
        />
        <div className="mb-8 mt-4">
          <label className="sr-only" htmlFor="media">
            Upload media
          </label>
          <input
            className="block w-full file:font-bold file:border file:border-gray file:mr-4 file:px-6 file:py-4 file:bg-white file:rounded-lg file:cursor-pointer focus:outline-none"
            id="media"
            type="file"
            name='about_media'
          />
        </div>
      </div> */}
    </>
  )
}

export default MasterClass