import React, { useEffect } from "react";
import Article from "../../components/Wiki/WikiPost/Article";
import Aside from "../../components/Wiki/WikiPost/Aside";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fecthPageAsyncThunck } from "../../redux/reducers/wikiSlice";
const WikiPost = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fecthPageAsyncThunck(searchParams.get("id")))
  }, [])
  const wikiSelector = useSelector(state => state.wiki)
  return (
    <div className="w-full text-dark-gray px-5 lg:px-8 xl:px-5 mx-auto max-w-xl lg:max-w-7xl flex flex-col lg:flex-row mt-10 mb-8 items-start justify-evenly">
      {(wikiSelector.loading || wikiSelector.pageResult == null) ? <div>Loading</div> : <Article article={wikiSelector.pageResult} />}
      {(wikiSelector.loading || wikiSelector.pageResult == null) ? <div>Loadind</div> : <Aside aside={wikiSelector.pageResult} />}

    </div>
  );
};

export default WikiPost;
