import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
let forumComments = require('../../comments.json')


export const getForumComments = createAsyncThunk('forum/getForumComments', async () => {
  try {
    let comments = forumComments;
    return comments
  } catch (err) {
    return err.response.data
  }

})

const forumSlice = createSlice({
  name: "forum",
  initialState: {
    loading: true,
    error: false,
    errMsg: '',
    success: '',
    forumComments: [],
    showReplyForm: false,
    curComment: null,
  },
  reducers: {
    replyCommentForm: (state, { payload }) => {
      state.showReplyForm = payload.display
      state.curComment = payload.id
    },
  },
  extraReducers: {
    [getForumComments.pending]: (state) => {
      state.loading = true
      state.success = false
    },
    [getForumComments.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.forumComments = payload
      state.success = true
    },
    [getForumComments.rejected]: (state, action) => {
      state.loading = false
      state.success = false
      state.error = true
      state.errMsg = action.error.message
    },
  }
})

export const { replyCommentForm } = forumSlice.actions;
export default forumSlice.reducer;
