import React from "react";

const AboutCampaignTab = ({ aboutCampaign }) => {
  return (
    <>
      <p className="mb-4">
        {aboutCampaign}
      </p>

      {/* <h2 className="mt-8 mb-4 font-bold text-xl lg:text-2xl">
        Support other Campaigns
      </h2>
      <div className="flex-1 w-full lg:w-1/2 flex justify-start flex-col sm:flex-row sm:gap-x-4">
        <div className="post w-full md:mx-3 mb-8 relative flex flex-col justify-between">
          <figure className="flex flex-col overflow-hidden">
            <img
              src="https://i.ytimg.com/vi/uGBGX0WHGSI/hqdefault.jpg"
              alt="media thumbnail"
              className=" object-cover rounded-lg mb-4 bg-gray min-h-[198px] lg:min-h-[216px]"
            />
          </figure>
        </div>
        <div className="post w-full md:mx-3 mb-8 relative flex flex-col justify-between">
          <figure className="flex flex-col overflow-hidden">
            <img
              src="https://i.ytimg.com/vi/uGBGX0WHGSI/hqdefault.jpg"
              alt="media thumbnail"
              className=" object-cover rounded-lg mb-4 bg-gray min-h-[198px] lg:min-h-[216px]"
            />
          </figure>
        </div>
      </div> */}
    </>
  );
};

export default AboutCampaignTab;
